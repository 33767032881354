import React from 'react';
import { FacebookFilled, InstagramFilled, LinkedinFilled } from '@ant-design/icons';
import './index.scss';
import { titleExternalLinks } from './constants';


const ExternalLinks = () => {
    return (
        <section className="container-external-links">
            <h6>{titleExternalLinks}</h6>
            <div className="container-social-icons">
                <a href="https://www.facebook.com/MiDocapp">
                    <FacebookFilled className="social-icons" />
                </a>
                <a href="https://www.instagram.com/midoc_app/">
                    <InstagramFilled className="social-icons" />
                </a>
                <a href="https://www.linkedin.com/company/mi-doc/">
                    <LinkedinFilled className="social-icons" />
                </a>
            </div>
        </section>
    );
}

export default ExternalLinks;