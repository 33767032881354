export const formatQuerySnapshot = query => {
  return {
    size: query.size,
    data: query.docs.map(doc => ({ id: doc.id, ...doc.data() })),
  };
};

export const formatErrorResponse = data => {
  return {
    title: 'Error',
    message: data.message,
  };
};
