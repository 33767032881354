export const accentedCharacters =
  'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ';
export const removeAccents = (str: string): string => {
  const accentsOut =
    'AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg';
  const arrayWithoutAccents = str.split('');
  arrayWithoutAccents.forEach((letter, index) => {
    const i = accentedCharacters.indexOf(letter);
    if (i !== -1) {
      arrayWithoutAccents[index] = accentsOut[i];
    }
  });
  return arrayWithoutAccents.join('');
};
