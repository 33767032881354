import { accentedCharacters } from './string';
const isAlfaRexString = `^[a-z${accentedCharacters}A-Z]+(([',. -][a-z${accentedCharacters}A-Z ])?[a-z${accentedCharacters}A-Z]*)*$`;

export default {
  // isEmail: new RegExp(
  //   /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  // ),
  isEmail: new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/),
  isAlfa: new RegExp(isAlfaRexString),
  isAlfaNumeric: new RegExp(/^[a-zA-Z0-9_]*$/),
  isNumeric: new RegExp(/^[0-9]*$/),
  isAddress: new RegExp(/\d{1,5}\s\w.\s(\b\w*\b\s){1,2}\w*\./),
};
