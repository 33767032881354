export const title = 'Cuenta bancaria';

export const banks = [
  {
    title: 'Banco de Mexico',
    key: 'BANCO_DE_MEXICO',
    value: 'banco_de_mexico',
  },
  {
    title: 'Banco de Tijuana',
    key: 'BANCO_DE_TIJUANA',
    value: 'banco_de_tijuana',
  },
];

export const buttonSubmit = 'Actualizar datos';

export const formErrors = {
  bankNumber: {
    required: 'La CLABE es requerida',
    length: 'La CLABE debe contener 18 digitos',
    onlyNumbers: 'La CLABE debe contener solo numeros',
  },
  bankName: {
    required: 'El banco tiene que ser eligido',
  },
};
export const rexRules = {
  email:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  numbers: '^[0-9]*$',
};

export const warningMessage = `Bienvenido, solo un último paso!
 Llena tus datos bancarios para poder transferir tus ganancias.`;
