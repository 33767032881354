export const formTitle = 'Únete a MiDoc';
export const title = 'Las llamadas y mensajes fuera del consultorio también tienen un valor';
export const subTitle = 'MiDoc ayuda a comunicarte con tus pacientes de forma justa y segura';
export const button = 'Continuar';
// export const rexRules = {
//   email:
//     "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
// };

export const errorMessages = {
  name: {
    required: 'Nombre requerido',
    invalid: 'Nombre puede tener solo letras',
  },
  lastName: {
    required: 'Apellidos requerido',
    invalid: 'Apellidos pueden tener solo letras',
  },
  email: {
    invalid: 'Correo electrónico inválido',
    required: 'Correo electrónico requerido',
  },
};

export const formPlaceholders = {
  name: 'Nombre(s)',
  lastNames: {
    fatherLastName: 'Apellido Paterno',
    motherLastName: 'Apellido Materno',
  },
  email: 'Correo electrónico',
};
