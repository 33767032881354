export const title = 'Perfil';

export const editedFields = [
  {
    title: 'Nombre(s)',
    key: 'name',
    type: 'input',
    rules: [],
  },
  {
    title: 'Email',
    key: 'email',
    type: 'input',
  },
  {
    title: 'Apellido(s)',
    key: 'lastName',
    type: 'input',
  },
  {
    title: 'Telefono de oficina',
    key: 'officePhoneNumber',
    type: 'input',
  },
  {
    title: 'Fecha de nacimiento',
    key: 'birthDay',
    type: 'birthDay',
  },
  {
    title: 'Sexo',
    type: 'gender',
    key: 'gender',
  },
  {
    title: 'Telefono',
    type: 'input',
    key: 'phoneNumber',
  },
  {
    title: 'Especialidad',
    type: 'select',
    key: 'specialty',
  },
  {
    title: 'Sub-especialidad',
    type: 'input',
    key: 'subSpeciality',
  },
  {
    title: 'Univerisdad',
    type: 'select',
    key: 'university',
  },
];
