import { database } from '../config/firebase';
import { collectionNames } from './constants';
import { formatQuerySnapshot } from './utils';

export default {
  getBanks: async () => {
    try {
      const querySnapshot = await database
        .collection(collectionNames.banks.title)
        .orderBy('name')
        .get();
      const response = formatQuerySnapshot(querySnapshot);
      return response;
    } catch (e) {
      return e;
    }
  },
};
