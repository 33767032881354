import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  BankOutlined,
  IdcardOutlined,
  HistoryOutlined,
  ScheduleOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { logOutButton } from './constants';
import './style.scss';
import { DoctorDataContext } from '../../../contexts/DoctorData';
import QrCode from '../../../components/qr-code';

const SideMenu = props => {
  const [sideMenuLinks, setMenuLinks] = useState([
    { title: 'Perfil', path: '/dashboard/profile', icon: <HomeOutlined /> },
    {
      title: 'Cuenta bancaria',
      section: 'bankAccount',
      path: '/dashboard/bank-account',
      icon: <BankOutlined />,
    },
    {
      title: 'Datos fiscales',
      section: 'goverment',
      path: '/dashboard/goverment-data',
      icon: <IdcardOutlined />,
    },
    {
      title: 'Mis Horarios',
      section: 'schedule',
      path: '/dashboard/schedule',
      icon: <ScheduleOutlined />,
    },
    {
      title: 'Historial',
      section: 'history',
      path: '/dashboard/history',
      icon: <HistoryOutlined />,
    },
    {
      title: 'Preguntas Frecuentes',
      section: 'faq',
      path: '/dashboard/faq',
      icon: <QuestionCircleOutlined />,
    },
  ]);

  const { doctorData } = useContext(DoctorDataContext);

  useEffect(() => {
    if (doctorData) {
      // console.log("DOC DATA MENU", doctorData);
      if (!doctorData.bankAccount) {
        // console.log("BANK INCOPLETE");
        const bankAccountIndex = sideMenuLinks.findIndex(menu => menu.section === 'bankAccount');
        const newMenu = sideMenuLinks;
        newMenu[bankAccountIndex].incomplete = true;
        setMenuLinks(newMenu);
      } else {
        const bankAccountIndex = sideMenuLinks.findIndex(menu => menu.section === 'bankAccount');
        const newMenu = sideMenuLinks;
        newMenu[bankAccountIndex].incomplete = false;
        setMenuLinks(newMenu);
      }
    }
  }, [doctorData, sideMenuLinks]);



  return (
    <Drawer
      title={<span>Menu | {props.name}</span>}
      placement="left"
      closable={false}
      onClose={props.onClose}
      visible={props.isOpen}
      className="dashboard-sidemenu"
    >
      <Menu onClick={props.onClose} selectedKeys={[props.currentPath]}>
        {sideMenuLinks.map(item => (
          <Menu.Item key={item.path}>
            <Link to={item.path}>
              {item.icon}
              {item.incomplete ? (
                <>
                  {item.title} <ExclamationCircleFilled style={{ color: 'red' }} />
                </>
              ) : (
                  item.title
                )}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <QrCode doctorId={doctorData && doctorData.uid} />
      <Button
        onClick={() => {
          props.logout();
        }}
        className="button-logout"
        block
        type="danger"
      >
        {logOutButton.title}
      </Button>
    </Drawer>
  );
};

export default SideMenu;
