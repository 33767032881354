import React from 'react';
import { Col, Typography } from 'antd';
import './styles.scss';

const { Title, Text } = Typography;

export const FunctionalityCard = props => {
  return (
    <Col
      xs={24}
      sm={24}
      md={8}
      lg={8}
      xl={6}
      xll={4}
      offset={props.number === 1 && 4}
      className="functionality-card"
    >
      <div className="step">
        <div className="icon-circle">
          <Text className="icon-text">{props.number}</Text>
        </div>
        <Title level={4} className="stepTitle">
          {props.title}
        </Title>
      </div>
      <img src={props.image} alt="Download" className="images" />
      <p className="step-description">{props.description}</p>
    </Col>
  );
};
