import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.png';
import './style.scss';
import { ILogoProps } from './types';

const Logo: React.FC<ILogoProps> = ({ hasLink }) => (
  <div className="logo">
    {hasLink ? (
      <Link to="/">
        <img src={logo} alt="MiDoc Logo" />
      </Link>
    ) : (
      <img src={logo} alt="MiDoc Logo" />
    )}
  </div>
);

export default Logo;
