import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { teamInfo } from './TeamCards/constants';
import TeamCards from './TeamCards';
import { textConstants } from './constants';
import './style.scss';

const TeamSection = () => {
  const { Paragraph, Title } = Typography;
  return (
    <section className="about-us">
      <Row className="team-pictures">
        {Object.values(teamInfo).map(item => (
          <TeamCards key={item.name} img={item.img} name={item.name} career={item.career} />
        ))}
      </Row>
      <Row justify="space-around" align="center">
        <Col xs={24} sm={18} md={18} lg={18} xl={18} className="text-container">
          <Row justify="center">
            <Title level={2} className="title">
              {textConstants.title}
            </Title>
          </Row>
          <Paragraph className="text">
            {textConstants.textStart}
            <span className="text-highlight"> {textConstants.spanText}. </span>
            {textConstants.textEnd}
          </Paragraph>
          <hr />
          <br />
          <Row justify="space-around">
            <Card style={{ width: 300 }}>
              <div className=" quote quote-first">
                <Paragraph className="quote-text">{textConstants.patyQuote.text}</Paragraph>
                <Paragraph className="quoteName text-highlight">
                  {textConstants.patyQuote.name}
                </Paragraph>
              </div>
            </Card>
            <Card style={{ width: 300 }}>
              <div className=" quote quote-last">
                <Paragraph className="quote-text">{textConstants.carlosQuote.text}</Paragraph>
                <Paragraph className="quoteName text-highlight">
                  {textConstants.carlosQuote.name}
                </Paragraph>
              </div>
            </Card>

            <Card style={{ width: 300 }}>
              <div className=" quote quote-last">
                <Paragraph className="quote-text">{textConstants.manuelQuote.text}</Paragraph>
                <Paragraph className="quoteName text-highlight">
                  {textConstants.manuelQuote.name}
                </Paragraph>
              </div>
            </Card>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default TeamSection;
