import React, { useState, useEffect, useContext } from 'react';
import { Button, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import SideMenu from './SideMenu';
import { AuthContext } from '../../contexts/Auth';
import { AppContext } from '../../contexts/App';
import { DoctorDataContext } from '../../contexts/DoctorData';
import { Auth as AuthApi } from '../../api';
import DashboardRoutes from './routes';
import Header from '../../components/Header';
import FullSizeCover from '../../components/FullSizeCover';
import './style.scss';

const Dashboard = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const { appLoading } = useContext(AppContext);
  const { doctorData } = useContext(DoctorDataContext);

  useEffect(() => {
    if (currentUser) {
      setUserName(currentUser.displayName);
      if (!currentUser.emailVerified) {
        history.push({
          pathname: '/email-handler',
          search: '?type=waitingForEmailVerification',
        });
      }
      if (doctorData && !doctorData.bankAccount) {
        history.push('/dashboard/bank-account');
      }
    }
    if (currentUser === false) {
      history.push('/');
    }
  }, [currentUser, history, doctorData]);

  const onCloseSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };
  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };
  const logout = async () => {
    const response = await AuthApi.logOut();
    if (response === true) {
      history.push('/');
    }
  };
  return (
    <div className="dashboard ">
      {appLoading && <FullSizeCover />}
      <SideMenu
        onClose={onCloseSideMenu}
        currentPath={history.location.pathname}
        isOpen={sideMenuOpen}
        logout={logout}
        name={userName}
      />
      <Row>
        <Col span={2} md={2} xs={6} sm={4} className="menu-icon">
          <Button onClick={() => toggleSideMenu()}>
            <MenuOutlined className="icon" />
          </Button>
        </Col>
        <Col xs={24} sm={16} md={18}>
          <Header onlyLogo />
        </Col>
      </Row>

      <div className="content shadow-2">
        <DashboardRoutes />
      </div>
    </div>
  );
};
export default Dashboard;
