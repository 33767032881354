import React, { useState, useEffect } from 'react';

import { Typography, notification } from 'antd';

import Logo from '../../components/Logo';
import { SignUp as SignUpApi, Auth as AuthAPI } from '../../api';
import { signUpTitle } from './constants';
import './style.scss';
import ProfileForm from '../../components/ProfileForm';
import LastStep from '../../components/EmailComponents/LastStep';
import { GANewPage, GAEvent } from '../../utils/analytics';

const SignUp = () => {
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [lastStepCredentials, setLastStepCredentials] = useState(null);

  useEffect(() => {
    GANewPage();
  }, []);
  const handleSignup = async values => {
    setIsLoading(true);
    const createUserResponse = await SignUpApi.createUser(values);
    // console.log('createUserResponse', createUserResponse);
    if (createUserResponse.error) {
      notification.error({
        message: createUserResponse.data.message,
      });
    }
    if (createUserResponse.status) {
      setLastStepCredentials({
        email: createUserResponse.email,
        uid: createUserResponse.uid,
      });
      await AuthAPI.login({
        email: createUserResponse.email,
        password: values.password,
      });
      notification.success({
        message: 'Registro exitoso!',
      });
      setIsLastStep(true);
    }
    GAEvent({
      category: 'web_user',
      action: 'SignUp action',
      label: 'SignUp user',
    });
    setIsLoading(false);
  };

  return (
    <section className="signup-section">
      <div className="header">
        <Logo />
      </div>
      <div className="form-section shadow-2">
        {!isLastStep && <Title level={2}>{signUpTitle}</Title>}
        <section>
          {isLastStep ? (
            <LastStep
              email={(lastStepCredentials && lastStepCredentials.email) || ''}
              uid={(lastStepCredentials && lastStepCredentials.uid) || ''}
            />
          ) : (
            <ProfileForm isLoading={isLoading} sendForm={handleSignup} isRegisterForm />
          )}
        </section>
      </div>
    </section>
  );
};

export default SignUp;
