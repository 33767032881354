import 'firebase/functions';
import { firebaseAuth, functions } from '../config/firebase';

export default {
  login: async ({ email, password }) => {
    try {
      const response = await firebaseAuth.signInWithEmailAndPassword(email, password);
      return response;
    } catch (error) {
      return error;
    }
  },
  reLogin: async ({ email, password }) => {
    try {
      const response = await firebaseAuth.currentUser.reauthenticateWithCredential(email, password);
      return response;
    } catch (e) {
      return e;
    }
  },
  logOut: async () => {
    try {
      await firebaseAuth.signOut();
      return true;
    } catch (error) {
      // console.log("Logout Error", error);
      return error;
    }
  },
  updateTimestamps: async ({ uid }) => {
    try {
      const updateTimestampsRef = functions.httpsCallable('addTimestamps');

      const response = await updateTimestampsRef({ uid });
      // console.log('response', response);
    } catch (e) {
      // console.log('E', e);
      return e;
    }
    // return true;
  },
};
