export const title = 'Datos Fiscales';

export const types = [
  { title: 'Fisico', value: 'normal', key: 'FISICO' },
  {
    title: 'Moral',
    key: 'MORAL',
    value: 'moral',
  },
];

export const errorMessages = {
  rfc: {
    invalid: 'RFC debe contener solo numeros y letras',
    length: 'RFC inválido. Capture solo 12 o 13 caracteres en mayusculas',
  },
  name: {
    invalid: 'Nombre para facturar debe contener solo caracteres',
  },
};
