// import { analytics } from '../config/firebase';
import ReactGA from 'react-ga';
import '@config/analytics';

export const GANewPage = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  // if (analytics) analytics.setCurrentScreen(window.location.pathname + window.location.search);
};

export const GAEvent = ({ category, action, label }) => {
  ReactGA.event({ category, action, label });
  // if (analytics) analytics.logEvent(category, { action, label });
};
