import React from 'react';
import { Spin, Typography } from 'antd';

import Logo from '../Logo';
import './style.scss';

const FullSizeCover = () => (
  <div className="full-size-cover">
    <Logo />
    <Typography.Title level={2}>Obteniendo tus datos...</Typography.Title>
    <Spin />
  </div>
);

export default FullSizeCover;
