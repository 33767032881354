import React, { useEffect, createContext, useContext, useState } from 'react';

import { AuthContext } from '../Auth';
import { AppContext } from '../App';
import { User as UserApi } from '../../api';

export const DoctorDataContext = createContext();

export const DoctorDataProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { setAppLoading } = useContext(AppContext);
  const [doctorData, setDoctorData] = useState(null);
  const [loadingDoctorData, setLoadingDoctorData] = useState(false);
  useEffect(() => {
    const getDoctorData = async id => {
      setAppLoading(true);
      setLoadingDoctorData(true);
      const response = await UserApi.getUserData(id);
      setDoctorData({ ...response });
      setLoadingDoctorData(false);
      setAppLoading(false);
    };
    if (currentUser) {
      getDoctorData(currentUser.uid);
    }
  }, [currentUser, setAppLoading]);

  return (
    <DoctorDataContext.Provider value={{ doctorData, setDoctorData, loadingDoctorData }}>
      {children}
    </DoctorDataContext.Provider>
  );
};
