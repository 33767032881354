import ReactGA from 'react-ga';

import { enviroment as AppEnv } from '@config/app';

const analyticsID = 'UA-163590464-1';
const enviroment = AppEnv || 'development';
ReactGA.initialize(analyticsID, {
  debug: enviroment === 'development',
  titleCase: false,
});
// ReactGA.initialize("152550192")
ReactGA.pageview(window.location.pathname + window.location.search);
