import * as React from 'react';
import { Animated } from 'react-animated-css';
import { useHistory } from 'react-router-dom';

import { Button, Form, Input, Typography } from 'antd';

import rexRules from '../../../utils/regex';
import { button, errorMessages, formPlaceholders, formTitle } from './constants';
import './styles.scss';

interface FormInputs {
  name: string;
  firstLastName: string;
  secondLastName: string;
  email: string;
}

const { Title } = Typography;
const LandingForm: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [, forceUpdate] = React.useState();
  const handleSignup = (values: FormInputs) => {
    history.push('/signup', { values });
  };
  React.useEffect(() => {
    forceUpdate({});
  }, []);
  return (
    <Animated
      className="cta-right__form landing-form"
      animationIn="fadeInRight"
      animationOut="fadeOut"
      isVisible
    >
      <span>
        <i>¿Eres Doctor?</i>
      </span>
      <Title level={3} className="form-title">
        {formTitle}
      </Title>
      <Form layout="vertical" form={form} name="basic" className="form" onFinish={handleSignup}>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: errorMessages.name.required },
            {
              pattern: rexRules.isAlfa,
              message: errorMessages.name.invalid,
            },
          ]}
          className="form-input-container"
          hasFeedback
        >
          <Input placeholder={formPlaceholders.name} className="form-input" />
        </Form.Item>

        <Form.Item
          name="firstLastName"
          rules={[
            { required: true, message: errorMessages.lastName.required },
            {
              pattern: rexRules.isAlfa,
              message: errorMessages.lastName.invalid,
            },
          ]}
          className="form-input-container"
          hasFeedback
        >
          <Input placeholder={formPlaceholders.lastNames.fatherLastName} className="form-input" />
        </Form.Item>

        <Form.Item
          name="secondLastName"
          rules={[
            { required: true, message: errorMessages.lastName.required },
            {
              pattern: rexRules.isAlfa,
              message: errorMessages.lastName.invalid,
            },
          ]}
          className="form-input-container"
          hasFeedback
        >
          <Input placeholder={formPlaceholders.lastNames.motherLastName} className="form-input" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: errorMessages.email.required },
            {
              pattern: rexRules.isEmail,
              message: errorMessages.email.invalid,
            },
          ]}
          className="form-input-container"
          hasFeedback
        >
          <Input placeholder={formPlaceholders.email} className="form-input" />
        </Form.Item>

        <Form.Item shouldUpdate className="submit-button-container">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={
                !form.isFieldsTouched(true) ||
                (form.getFieldsError().filter(({ errors }) => errors.length).length > 0 && true)
              }
              className="submit-button"
            >
              {button}
            </Button>
          )}
        </Form.Item>
      </Form>
    </Animated>
  );
};

export default LandingForm;
