import React from 'react';
import { Row, Col, Typography } from 'antd';

import dashboardImage from '../../../assets/img/dashboardEx.gif';
import { maintext, title } from './constants';
import './style.scss';

const { Title } = Typography;
const DashboardExample = () => (
  <section className="landing-page-dashboard-example">
    <Row justify="space-around" align="middle" className="content">
      <Col
        className="image-section"
        // span={10}
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
      >
        <img width="100%" className="shadow-2" src={dashboardImage} alt="dashboard-example" />
      </Col>
      <Col
        className="description"
        // span={10}
        xs={18}
        sm={18}
        md={18}
        lg={8}
        xl={8}
      >
        <Title level={2}>{title}</Title>
        {maintext.map(desc => (
          <span key={desc.text} className={desc.bold && 'text-highlight'}>
            {desc.bold ? <b>{desc.text}</b> : desc.text}
          </span>
        ))}
        <br/>
        <i>*Puedes consultarlo en tu app o página web</i>
      </Col>
    </Row>
  </section>
);

export default DashboardExample;
