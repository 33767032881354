import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { database, firebaseAuth, storage } from '../config/firebase';
import { collectionNames } from './constants';

let currentUser = {};

firebase.auth().onAuthStateChanged(user => {
  currentUser = user;
});

export default {
  getUser: () => currentUser,
  getUserData: async id => {
    const userDataRef = await database
      .collection(collectionNames.doctors)
      .doc(id)
      .get();

    // firebase.
    if (userDataRef.exists === true) {
      return userDataRef.data();
    }
    return null;
    // const response
  },
  updateUserData: async ({ id, data }) => {
    try {
      await database
        .collection(collectionNames.doctors)
        .doc(id)
        .update({
          ...data,
        });
      return true;
    } catch (error) {
      return error;
    }
  },
  loadImage: async ({ id, image }) => {
    const imageRef = storage.ref().child(`${id}/${image.name}`);
    const response = await imageRef.put(image);
    if (response.state === 'success') {
      return response.metadata.fullPath;
    }
    return 'error';
  },
  getImageFromCollection: async (path = '', defaultImage = false) => {
    let response = false;
    if (defaultImage) {
      const imageRef = storage.refFromURL('gs://mi-doc.appspot.com/profile_default.jpeg');
      const image = await imageRef.getDownloadURL();
      response = image;
    } else {
      const imageRef = storage.ref(path);
      const image = await imageRef.getDownloadURL();
      response = image;
    }
    return response;
  },
  changeUserNameInAuthAccount: async ({ fullName }) => {
    try {
      if (!fullName) {
        throw new Error(false);
      }
      await firebaseAuth.currentUser.updateProfile({
        displayName: fullName,
      });
      return true;
    } catch (e) {
      return e;
    }
  },
};
