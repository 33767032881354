export const textConstants = {
  title: 'Comprometidos con ayudarte',
  spanText: 'interacciones médico-paciente tienen un valor',
  textStart: 'Creemos que las',
  textEnd:
    'Queremos cambiar los paradigmas de la cultura mexicana y latinoamericana, el consejo de un médico tiene valor y debe de ser recompensado.',
  patyQuote: {
    text:
      '"Creo en este proyecto porque vivo las llamadas y mensajes día a día, y mis' +
      ' colegas lo necesitan igual o más que yo"',
    name: '-Paty, Médico pediatra',
  },
  manuelQuote: {
    text:
      '"Estoy construyendo la tecnología de MiDoc porque es pionero en el sector' +
      ' Salud y los médicos lo necesitan ya; estamos comprometidos porque es una' +
      ' solución fácil e inteligente"',
    name: '-José Manuel, Ingeniero de Software',
  },
  carlosQuote: {
    text:
      '"Me encantó la app porque puedo contactar a mi doctor sin pena y porque sé ' +
      ' de antemano el costo de la llamada o mensaje, y así agradecer el tiempo que' +
      ' me dedica fuera del consultorio"',
    name: '-Carlos, paciente',
  },
};
