export const tableColumns = [
  {
    title: 'Nombre de paciente',
    dataIndex: 'patientFullName',
  },
  {
    title: 'Dia de llamada',
    dataIndex: 'timestamps.ringing',
  },
];

export const title = 'Historial';

export const toolTipText =
  'Este numero se genera del numero que has puesto en el horario menos el 20% que cobre MiDoc ';
