import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { Col, Row } from 'antd';
import useMedia from 'use-media';

import Billing from '../../components/LandingPage/Billing';
import CallToAction from '../../components/LandingPage/CallToAction/';
import DashboardExample from '../../components/LandingPage/DashboardExample';
import Description from '../../components/LandingPage/Description';
import FAQ from '../../components/LandingPage/FAQ';
import { Functionality } from '../../components/LandingPage/Functionality';
import TeamSection from '../../components/LandingPage/TeamSection';
import '../../scss/app.scss';
import { GANewPage } from '../../utils/analytics';
import './style.scss';

toast.configure();

const Home = () => {
  const isWide = useMedia({ minWidth: '600px' });
  useEffect(() => {
    GANewPage();
  }, []);
  return (
    <div className="App">
      <CallToAction />
      <Row className="section" id="description">
        <Description />
      </Row>
      <Row className="section" id="how-it-works">
        <Functionality />
      </Row>
      <Row className="section" id="billing">
        <Billing />
      </Row>
      <Row className="section" id="dashboard-example">
        <DashboardExample />
      </Row>
      <Row className="section" id="about-us">
        <TeamSection />
      </Row>
      <Row className="section" id="faq">
        <Col offset={isWide && 2} span={isWide ? 16 : 24}>
          <FAQ />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
