import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = { opened: false };
  }

  render() {
    const { title, content } = this.props;
    const { opened } = this.state;

    return (
      <div
        className={`accordion-item ${opened && "accordion-item--opened"}`}
        role="button"
        tabIndex="0"
        onKeyDown={() => {
          this.setState({ opened: !opened });
        }}
        onClick={() => {
          this.setState({ opened: !opened });
        }}
      >
        <div className="accordion-item__line">
          <h6 className="accordion-item__title">{title}</h6>
          <span className="accordion-item__icon" />
        </div>
        <div className="accordion-item__inner">
          <div className="accordion-item__content">
            <p className="accordion-item__paragraph">{content}</p>
          </div>
        </div>
      </div>
    );
  }
}

AccordionItem.defaultProps = {
  title: undefined,
  content: undefined
};

AccordionItem.propTypes = {
  content: PropTypes.element,
  title: PropTypes.string
};

export default AccordionItem;
