import React, { useEffect, useContext } from 'react';
import { Form, Typography, Input, Button, Radio, notification } from 'antd';

import { User as UserApi } from '../../../api';
import { DoctorDataContext } from '../../../contexts/DoctorData';
import { title, types, errorMessages } from './constants';
import { AuthContext } from '../../../contexts/Auth';
import { GANewPage } from '../../../utils/analytics';
import rexRules from '../../../utils/regex';

const GovermentData = () => {
  const { useForm, Item } = Form;
  const { Title } = Typography;
  const [form] = useForm();
  const { currentUser } = useContext(AuthContext);
  const { doctorData, setDoctorData } = useContext(DoctorDataContext);

  useEffect(() => {
    GANewPage();
  }, []);
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);
  useEffect(() => {
    if (doctorData && doctorData.goverment) {
      form.setFieldsValue({
        rfc: doctorData.goverment.rfc,
        billName: doctorData.goverment.billName,
        type: doctorData.goverment.type,
      });
    }
  }, [doctorData, form]);

  const handleSubmit = async values => {
    const dataToSend = {
      goverment: {
        ...values,
      },
    };
    if (currentUser) {
      const response = await UserApi.updateUserData({
        id: currentUser.uid,
        data: dataToSend,
      });
      await setDoctorData(prevState => ({ ...prevState, ...dataToSend }));
      if (response === true) {
        notification.success({
          message: 'Tus datos fueron actualizados',
        });
      }
    }
  };
  const handleChange = ({ name, value }) => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  return (
    <div>
      <Title style={{ marginBottom: 5 }}>{title}</Title>
      <i><b>*Estos campos son necesarios solo si requieres factura</b></i>
      <Form layout="vertical" onFinish={handleSubmit} form={form} style={{ marginTop: 30 }}>
        <Item
          label="RFC"
          hasFeedback
          rules={[
            {
              pattern: rexRules.alfaNumeric,
              message: errorMessages.rfc.invalid,
            },
            {
              min: 12,
              message: errorMessages.rfc.length,
            },
            {
              max: 13,
              message: errorMessages.rfc.length,
            },
          ]}
          name="rfc"
        >
          <Input
            size="large"
            value={form.getFieldValue('rfc')}
            placeholder="RFC"
            maxLength={13}
            onChange={value => handleChange({ name: 'rfc', value: value.target.value })}
          />
        </Item>
        <Item
          label="Nombre para facturar"
          rules={[
            {
              pattern: rexRules.isAlfa,
              message: errorMessages.name.invalid,
            },
          ]}
          hasFeedback
          name="billName"
        >
          <Input
            size="large"
            value={form.getFieldValue('billName')}
            placeholder="Nombre para facturar"
            onChange={value => handleChange({ name: 'billName', value: value.target.value })}
          />
        </Item>
        <Item label="Tipo" name="type">
          <Radio.Group
            onChange={value => handleChange({ name: 'type', value: value.target.value })}
            defaultValue={form.getFieldValue('type')}
            buttonStyle="solid"
          >
            {types.map(type => (
              <Radio.Button key={type.key} value={type.key}>
                {type.title}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">
            Actualizar los datos
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default GovermentData;
