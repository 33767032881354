import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ExternalLinks from '@components/ExternalLinks';
// import { MobileSplash } from '@components/MobileSplash';
import { AppContext } from '@contexts/App';

import Footer from '../components/Footer';
import Header from '../components/Header';
import { DoctorDataProvider } from '../contexts/DoctorData';
import Dashboard from '../views/Dashboard';
import EmailHandler from '../views/EmailHandler';
import Home from '../views/Home';
import Privacy from '../views/Privacy/Privacy';
import Terms from '../views/Privacy/Terms';
import Signup from '../views/Signup';

const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: () => (
      <>
        <Header />
        <Home />
        <ExternalLinks />
        <Footer />
      </>
    ),
  },
  {
    path: '/email-handler/',
    key: 'VERIFY_EMAIL',
    exact: true,
    component: () => <EmailHandler />,
  },
  {
    path: '/signup',
    key: 'SIGN_UP',
    exact: true,
    component: () => (
      <>
        <Signup />
        <Footer />
      </>
    ),
  },
  {
    path: '/dashboard',
    key: 'DASHBOARD',
    // exact: true,
    component: () => (
      <DoctorDataProvider>
        <Dashboard />
      </DoctorDataProvider>
    ),
  },
  {
    path: '/terms',
    key: 'TERMS',
    component: () => (
      <>
        <Header />
        <Terms />
        <Footer />
      </>
    ),
  },
  {
    path: '/privacy',
    key: 'TERMS',
    component: () => (
      <>
        <Header />
        <Privacy />
        <Footer />
      </>
    ),
  },
];

const RouteWithSubRoutes: (route: any) => JSX.Element = (route: any) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      // eslint-disable-next-line react/jsx-props-no-spreading
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
};
export const RenderRoutes: React.FC<any> = ({ routes }) => {
  const { isMobileApp } = useContext(AppContext);
  // return (
  //   <>
  //     {isMobileApp ? (
  //       <MobileSplash />
  //     ) : (
  //       <Router>
  //         <Switch>
  //           {routes.map((route: any) => (
  //             // eslint-disable-next-line react/jsx-props-no-spreading
  //             <RouteWithSubRoutes key={route.key} {...route} />
  //           ))}
  //           <Route path="*" component={() => <h2>NOT FOUND</h2>} />
  //         </Switch>
  //       </Router>
  //     )}
  //   </>
  // );
  return (
    <>
      <Router>
        <Switch>
          {routes.map((route: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RouteWithSubRoutes key={route.key} {...route} />
          ))}
          <Route path="*" component={() => <h2>NOT FOUND</h2>} />
        </Switch>
      </Router>
    </>
  );
};
export default ROUTES;
