import React, { createContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { getItem } from 'services/LocalStorage';

import { LocalStorageKeys } from '../../interfaces/LocalStorage.interface';
import { IAppContext } from './types';

export const AppContext = createContext<IAppContext>({
  appLoading: false,
  isMobileApp: false,
  setAppLoading: () => null,
  setIsMobileApp: () => null,
});

export const AppProvider: React.FC = ({ children }) => {
  const [appLoading, setAppLoading] = useState(true);
  const [isMobileApp, setIsMobileApp] = useState(isMobile);
  useEffect(() => {
    const isMobileScreenVisited = getItem(LocalStorageKeys.isMobileScreenVisted);
    if (isMobileScreenVisited) {
      setIsMobileApp(false);
    }
  }, []);
  return (
    <AppContext.Provider value={{ appLoading, setAppLoading, isMobileApp, setIsMobileApp }}>
      {children}
    </AppContext.Provider>
  );
};
