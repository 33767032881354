import React from "react";
import PropTypes from "prop-types";

import AccordionItem from "./accordionItem";
import "./style.scss";

const AccordionComponent = ({ title, children }) => (
  <div className="accordion-list">
    <AccordionItem content={children} title={title} />
  </div>
);

AccordionComponent.defaultProps = {
  title: undefined,
  children: undefined
};

AccordionComponent.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string
};

export default AccordionComponent;
