export const days = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
];
export const months = [
  { value: 'January', label: 'Enero' },
  { value: 'February', label: 'Febrero' },
  { value: 'March', label: 'Marzo' },
  { value: 'April', label: 'Abril' },
  { value: 'May', label: 'Mayo' },
  { value: 'June', label: 'Junio' },
  { value: 'July', label: 'Julio' },
  { value: 'August', label: 'Agosto' },
  { value: 'September', label: 'Septiembre' },
  { value: 'October', label: 'Octubre' },
  { value: 'November', label: 'Noviembre' },
  { value: 'December', label: 'Diciembre' },
];
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: 'https://mi-doc.firebaseapp.com//finishSignUp?cartId=1234',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios',
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12',
  },
  dynamicLinkDomain: 'example.page.link',
};

export const errorMessages = {
  name: {
    required: 'Nombre requerido',
    invalid: 'Nombre es invalido',
    max: 'Nombre no puede contener mas de 50 caracteres',
    min: 'Nombre no puede contener menos de 3 caracteres',
  },
  lastName: {
    required: 'Apellidos requerido',
    invalid: 'Apellidos es invalido',
    max: 'Apellidos no puede contener mas de 50 caracteres',
    min: 'Apellidos no puede contener menos de 3 caracteres',
  },
  email: {
    invalid: 'Correo electrónico inválido',
    required: 'Correo electrónico requerido',
  },
  doctorId: {
    required: 'Cédula profesional requerida',
    isNumber: 'Cédula profesional requiere solo numeros',
    min: 'Cédula profesional no puede ser menos de 3 cifras',
    max: 'Cédula profesional no puede ser mas de 50 cifras',
  },
  specialty: {
    required: 'Especialidad requerida',
  },
  university: {
    required: 'Universidad requerida',
  },
  phoneNumber: {
    required: 'Teléfono requerido',
    isTenNumbers: 'Teléfono requiere 10 digitos',
    isNumber: 'Teléfono requiere solo numeros',
  },
  officePhoneNumber: {
    required: 'Teléfono de oficina requerido',
    isTenNumbers: 'Teléfono de oficina requiere 10 digitos',
    isNumber: 'Teléfono de oficina requiere solo numeros',
  },
  officeAddress: {
    invalid: 'Dirección no es valida',
    max: 'Dirección no puede ser mas de 100 simbolos',
  },
  gender: {
    required: 'Género requerido',
  },
  password: {
    required: 'Contraseña requerida',
  },
  confirmPassword: {
    required: 'Confirmación de contraseña requerida',
    asPassword: 'Confirmación de contraseña no es igual',
  },
  birthday: {
    day: {
      required: 'Dia requerido',
    },
    month: {
      required: 'Mes requerido',
    },
    year: {
      required: 'Año requerido',
    },
  },
};
export const rexRules = {
  email:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  numbers: '^[0-9]*$',
};

export const formPlaceholders = {
  name: 'Nombre(s)',
  lastName: 'Apellidos',
  email: 'Correo electrónico',
  birthday: 'Fecha de nacimiento',
  specialty: 'Especialidad',
  subSpecialty: 'Sub-especialidad',
  university: 'Universidad donde estudiaste Medicina General',
  specialtyUniversity: 'Universidad de la especialidad',
  license: 'Cédula profesional',
  licenseSubTitle: 'Esta es la cédula con la que tu paciente te buscará',
  phoneNumber: 'Telefono celular',
  officePhoneNumber: 'Telefono de consultorio',
  gender: 'Sexo',
  password: 'Contraseña',
  officeAddress: 'Dirección de consultorio',
  confirmPassword: 'Confirmar contraseña',
};

export const genders = [
  { name: 'Hombre', value: 'male' },
  { name: 'Mujer', value: 'female' },
];

export const signUpTitle = 'Registrate';

export const notificationErrorTitle = 'Error en registro';
export const notificationSuccessTitle = 'Te has registrado';
export const notificationDesc = {
  'User Already Exist': 'Tu correo ya esta registrado.',
  WaitForEmail: 'Revisa tu correo para confirmar el registro.',
};

export const notificationSpecialty = '*Colocar Medicina General si no aplica';
