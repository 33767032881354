import React from 'react';
import { Typography } from 'antd';

import './style.scss';

const PrivacyComponent = () => {
  const { Title, Paragraph } = Typography;
  return (
    <section className="privacy-section">
      <Title className="title" level={1}>
        AVISO DE PRIVACIDAD <i>DEL DOCTOR</i>
      </Title>
      <Paragraph className="paragraph">
        Mi Doc Technologies S.A.P.I. de C.V. (en adelante referido como “MiDoc” y/o el “Responsable
        del Tratamiento de Datos Personales) con domicilio fiscal en Puebla 403, Col. Roma Norte,
        Delegación Cuauhtémoc, Ciudad de México, C.P. 06700, expide el presente Aviso de Privacidad
        para que el titular de los datos personales (en adelante referido como “el Titular” o “el
        Usuario”) tenga un mayor conocimiento sobre el uso y trato que MiDoc les dará a dichos datos
        personales recabados por medio de nuestra página web.
      </Paragraph>
      <Paragraph className="paragraph">
        El Departamento de Atención a Clientes es para efectos del presente aviso de privacidad el
        departamento de datos personales que dará trámite a las solicitudes de los Titulares, para
        el ejercicio de los derechos a que se refiere el presente aviso de privacidad, la Ley
        Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento (la
        “LFPDPPP”) estableciendo como medios de contacto el correo electrónico a{' '}
        <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>
      </Paragraph>
      <Title className="title" level={2}>
        Aceptación del Aviso de Privacidad
      </Title>
      <Paragraph className="paragraph">
        El Usuario manifiesta que al uso de la página, la proporción de sus Datos Personales, o la
        realización directa de origen contractual o extracontractual con MiDoc, ya ha leído el
        presente aviso y consciente lo estipulado en el mismo. El Usuario consiente el presente
        Aviso de Privacidad, de conformidad con la LFPDPPP.
      </Paragraph>
      <Title className="title" level={2}>
        Datos Personales que Serán Solicitados y Tratados
      </Title>
      <Paragraph className="paragraph">
        MiDoc solicita los siguientes datos personales a través de la Página Web:
        <ul>
          <li>Nombre</li>
          <li>Apellidos</li>
          <li>Correo electrónico</li>
          <li>Fecha de nacimiento</li>
          <li>Especialidad </li>
          <li>Última cédula profesional obtenida</li>
          <li>Universidad donde estudió medicina</li>
          <li>Número celular</li>
          <li>Teléfono consultorio médico (si aplica) </li>
          <li>Dirección consultorio médico (si aplica)</li>
          <li>Sexo</li>
          <li>Datos fiscales para elaboración de facturas</li>
          <li>Identificadores de cuentas bancarias</li>
        </ul>
      </Paragraph>
      <Title className="title" level={2}>
        Finalidad del Tratamiento de los Datos Personales
      </Title>
      <Paragraph className="paragraph">
        Los datos personales especificados en la sección anterior serán utilizados para las
        siguientes finalidades:
      </Paragraph>
      <Paragraph className="paragraph">
        Finalidades Primarias (finalidades que dieron origen y son necesarias para la existencia,
        mantenimiento y cumplimiento de la relación jurídica entre el responsable y titular):
        <ul>
          <li>Identificación y certeza del Usuario de la Página Web.</li>
          <li>
            Funcionamiento, gestión, facturación, administración, prestación de nuestros servicios.
          </li>
          <li>
            Entrega de notificaciones, requerimientos, cartas o boletines informativos o atención a
            sus solicitudes relacionadas con los servicios que prestamos.
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        Finalidades Secundarias (Finalidades que no dieron origen y no son necesarias para la
        existencia, mantenimiento y cumplimiento de la relación jurídica entre el responsable y el
        titular):
        <ul>
          <li>Análisis del comportamiento y la demografía de los Usuarios.</li>
          <li>
            Enviar información o mensajes sobre nuevos productos y/o servicios, así como cualquier
            otra información.
          </li>
          <li>Mostrar publicidad o promociones de interés para nuestros Usuarios.</li>
          <li>
            La realización de seguimiento de tráfico, con intención de identificar grupos y perfiles
            de usuarios, así como para fines de orientación publicitaria.
          </li>
        </ul>
      </Paragraph>
      <Title className="title" level={2}>
        Mecanismo para manifestar la negativa al tratamiento de los datos para las finalidades
        secundarias
      </Title>
      <Paragraph className="paragraph">
        En caso de que el Usuario desee manifestar su negativa para el tratamiento de los datos
        personales que se señalan en la sección Finalidades Secundarias el titular podrá enviar un
        correo al departamento de datos personales:{' '}
        <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>
      </Paragraph>
      <Title className="title" level={2}>
        Transferencia de información a terceros
      </Title>
      <Paragraph className="paragraph">
        MiDoc podrá transferir los datos personales que recabe sin el consentimiento del Titular
        cuando la transferencia: i) esté prevista en una Ley o Tratado en los que México sea parte;
        ii) sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia
        sanitaria, tratamiento médico o la gestión de servicios sanitarios; iii) sea efectuada a sus
        sociedades controladoras, subsidiarias o afiliadas bajo su control común o a una sociedad
        matriz o a cualquier sociedad del mismo grupo de Lunna que opere bajo los mismos procesos y
        políticas internas; iv) sea necesaria por virtud de un contrato celebrado o por celebrar en
        interés del Titular, por MiDoc y un tercero; v) sea necesaria o legalmente exigida para la
        salvaguarda de un interés público, o para la procuración o administración de justicia; vi)
        sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso
        judicial; y/o vii) sea precisa para el mantenimiento o cumplimiento de una relación jurídica
        entre MiDoc y el titular.
      </Paragraph>
      <Paragraph className="paragraph">
        Adicionalmente, MiDoc podrá transferir sus datos a sus asociados estratégicos, comerciales o
        técnicos con la finalidad de ofrecerle al Titular mejores condiciones de Promociones y/o
        contenidos del Usuario.
      </Paragraph>
      <Paragraph className="paragraph">
        Entiendo que al momento en que se pone a mi disposición el presente Aviso de Privacidad
        estoy aceptando la transferencia de mis datos para las finalidades que no forman parte de
        las excepciones del consentimiento arriba descritas, en caso de requerir manifestar la
        negativa para la transferencia aquí mencionada favor de enviar correo electrónico a
        midoc.contacto@gmail.com o acudir al departamento de datos personales.
      </Paragraph>
      <Title className="title" level={2}>
        ¿Tu información está segura?
      </Title>
      <Paragraph className="paragraph">
        Nos tomamos muy en serio la seguridad de tus datos. Al momento de registrarse, se
        solicitarán datos bancarios para los cuales ofrecemos seguridad y confidencialidad. Estos
        datos son meramente para hacer el depósito de las transacciones que se generen a beneficio
        de usted.
      </Paragraph>
      <Paragraph className="paragraph">
        Nunca se le pedirá ningún tipo de NIP o clave para ingresar a sus datos bancarios.
      </Paragraph>
      <Title className="title" level={2}>
        Cookies y otras tecnologías
      </Title>
      <Paragraph className="paragraph">
        Tu navegación en nuestro sitio web activa cookies. Las cookies son pequeñas piezas de
        información que son enviadas por el sitio web a su navegador las cuales nos permiten recabar
        ciertos datos personales de manera automática y simultánea al tiempo que usted hace contacto
        con las mismas.
      </Paragraph>
      <Paragraph className="paragraph">
        Se almacenan en el disco duro de tu equipo y se usan para determinar tus preferencias y lo
        que haces dentro de nuestra página. La finalidad del tratamiento de los datos recabados a
        través de dichas tecnologías es reconocerte al ingresar a nuestra página, detectar el ancho
        de banda y saber qué información puede ser de tu interés.
      </Paragraph>
      <Paragraph className="paragraph">
        Recabamos los siguientes datos a través de cookies y web beacons: dirección IP, localización
        GPS, tipo de dispositivo utilizado para acceder a la Página Web, tipo de navegador, sistema
        operativo, momento de acceso a la Página Web, tiempo de navegación en la Página Web, sitios
        web visitadas, información consultada, medio de identificación para el acceso a la Cuenta
        del Usuario, preferencias y personalización de la Cuenta del Usuario.
      </Paragraph>
      <Paragraph className="paragraph">
        La instalación, permanencia y existencia de las Cookies en tu computadora depende de tu
        exclusiva voluntad y puede ser desactivada cuando así lo desees, como se describe a
        continuación.
      </Paragraph>
      <Title className="title" level={2}>
        Desactivar Cookies
      </Title>
      <Paragraph className="paragraph">
        Normalmente los navegadores permiten las cookies automáticamente, pero también ofrece la
        posibilidad de controlar la mayoría de ellas, incluida la opción de aceptarlas o no y cómo
        eliminarlas. En el apartado ”Herramientas“ (o similar) de tu navegador encontrarás
        información sobre cómo controlar las cookies. En la mayoría de casos puedes configurar tu
        navegador para que te avise antes de recibir una cookie y te dé la opción de aceptarla o no.
        También puedes configurar el navegador para que desactive todas las cookies. Sin embargo,
        esta configuración afectará tanto a las cookies permanentes como a las temporales y, en
        consecuencia, es posible que no puedas utilizar todas las funciones de nuestra página web.
        Aquí puedes encontrar información sobre cómo desactivar el almacenamiento de cookies en los
        navegadores más populares:
        <ul>
          <li>
            <a href="https://support.mozilla.org/es/kb/Borrar%20cookies">Mozilla Firefox</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
              Internet Explorer
            </a>
          </li>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?hl=es">Google Chrome</a>
          </li>
          <li>
            <a href="https://privacy.microsoft.com/es-ES/windows-10-microsoft-edge-and-privacy">
              Microsoft Edge
            </a>
          </li>
          <li>
            <a href="https://www.opera.com/es/o/ie-simple">Opera</a>
          </li>
          <li>
            <a href="https://support.apple.com/es-mx/guide/safari/sfri11471/mac">Safari</a>
          </li>
        </ul>
      </Paragraph>
      <Title className="title" level={2}>
        Protección
      </Title>
      <Paragraph className="paragraph">
        Para garantizar que los datos personales sean tratados conforme a lo dispuesto en la
        LFPDPPP, MiDoc cumple con los siguientes principios:
        <ol>
          <li>
            Se le comunicarán las finalidades del tratamiento de sus datos personales recabando solo
            los datos necesarios para el cumplimiento de estas finalidades y no se les dará uso
            distinto al establecido en el presente aviso de privacidad.
          </li>
          <li>
            Se realizará el mantenimiento adecuado para que sus datos personales sean correctos y
            actualizados, en caso de que sea necesario conservar sus datos será conforme a lo
            dispuesto por la Ley aplicable.
          </li>
          <li>
            Se han implementado medidas de seguridad para garantizar la protección de sus datos
            personales.
          </li>
        </ol>
      </Paragraph>
      <Title className="title" level={2}>
        Remisión de Datos
      </Title>
      <Paragraph className="paragraph">
        MiDoc podrá hacer remisiones de algunos datos personales que recabe de acuerdo con el
        presente Aviso de Privacidad a Empresas de Servicios de Paquetería (como dicho término se
        encuentra definido en los Términos y Condiciones) con la finalidad de que los productos
        adquiridos le sean entregados.
      </Paragraph>
      <Paragraph className="paragraph">
        De igual manera, MiDoc podrá transferir sus datos a sociedades controladoras, subsidiarias o
        afiliadas o a una sociedad matriz o a cualquier sociedad del mismo grupo de MiDoc que opere
        bajo los mismos procesos y políticas internas.
      </Paragraph>
      <Paragraph className="paragraph">
        A su vez, sus datos serán transferidos cuando la transferencia sea necesaria o legalmente
        exigida para la salvaguarda de un interés público, o para la procuración o administración de
        justicia; cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de
        un derecho en un proceso judicial, y cuando la transferencia sea precisa para el
        mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.
      </Paragraph>
      <Title className="title" level={2}>
        Confidencialidad
      </Title>
      <Paragraph className="paragraph">
        Los datos de los Titulares serán recabados por MiDoc en las formas establecidas en este
        Aviso de Privacidad. MiDoc hará todo lo que esté a su alcance para proteger la privacidad de
        la información. Puede suceder que en virtud de órdenes judiciales, o de regulaciones
        legales, MiDoc se vea compelida a revelar información a las autoridades o terceras partes
        bajo ciertas circunstancias, o bien en casos que terceras partes puedan interceptar o
        acceder a cierta información o transmisiones de datos en cuyo caso MiDoc no responderá por
        la información que sea revelada. En estos casos, MiDoc notificará al Titular sobre esta
        situación.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc tomará todas las medidas posibles para mantener la confidencialidad y la seguridad
        descritas anteriormente, pero no responderá por los daños o perjuicios que pudieran
        derivarse de la violación de esas medidas por parte de terceros que utilicen las redes
        públicas o el Internet, alterando los sistemas de seguridad para obtener acceso a la
        información de los Titulares.
      </Paragraph>
      <Paragraph className="paragraph">
        Las vulneraciones de seguridad ocurridas en cualquier fase del tratamiento que afecten de
        forma significativa los derechos patrimoniales o morales de los Titulares, serán informadas
        de forma inmediata por MiDoc al Titular, a fin de que este último pueda tomar las medidas
        correspondientes a la defensa de sus derechos.
      </Paragraph>
      <Paragraph className="paragraph">
        No asumimos ninguna obligación de mantener confidencial respecto a cualquier otra
        información que el Titular proporcione a través de boletines y pláticas en línea (chats),
        y/o cualesquier otra fuente de información pública.
      </Paragraph>
      <Title className="title" level={2} />
      <Paragraph className="paragraph">
        En caso de que no desee recibir correos electrónicos informativos, publicitarios y/o
        promocionales usted deberá enviar un correo electrónico a la dirección
        <a href="mailto:contacto@midocapp.com"> contacto@midocapp.com </a>
      </Paragraph>
      <Paragraph className="paragraph">
        De igual manera, Para que el Usuario ejerza su derecho de limitar el uso o divulgación de
        los Datos Personales, pero con intención de continuar usando de los servicios que presta
        MiDoc, el usuario debe de manifestarlo mediante un escrito al siguiente correo electrónico
        <a href="mailto:contacto@midocapp.com"> contacto@midocapp.com </a>
      </Paragraph>
      <Paragraph className="paragraph">
        Adicionalmente, el Titular podrá inscribirse en el Registro Público de Consumidores previsto
        en la Ley Federal de Protección al Consumidor, y en el Registro Público de Usuarios conforme
        a la Ley de Protección y Defensa al Usuario de Servicios Financieros.
      </Paragraph>
      <Title className="title" level={2} />
      <Paragraph className="paragraph">
        El Titular tiene derecho, de propia persona o a través de un Representante, de solicitar a
        la Empresa, en cualquier momento el acceso, rectificación, cancelación u oposición, respecto
        de los datos personales que estén almacenados en la base de datos de MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        La Solicitud debe ser por escrito con los siguientes requisitos:
        <ul>
          <li>
            El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su
            solicitud.
          </li>
          <li>
            Los documentos que acrediten la identidad (copia de credencial para votar por ejemplo)
            o, en su caso, la representación legal del titular. Las identificaciones deben de ser
            Oficiales.
          </li>
          <li>
            La descripción clara y precisa de los datos personales respecto de los que se busca
            ejercer alguno de los derechos de acceso, rectificación, cancelación u oposición a los
            mismos.
          </li>
          <li>
            En su caso, las modificaciones a realizarse y aportar la documentación que sustente su
            petición.
          </li>
          <li>Cualquier otro elemento que facilite la localización de los datos personales.</li>
          <li>Que la carta esté dirigida a la Empresa.</li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        La Solicitud será enviada al departamento de datos personales a través del correo
        electrónico
        <a href="mailto:contacto@midocapp.com"> contacto@midocapp.com </a>. MiDoc comunicará al
        Titular, a través del correo electrónico del que provenga la petición, en un plazo de 20
        (veinte) días hábiles contados desde la fecha en que se recibió la solicitud de acceso,
        rectificación, cancelación u oposición, la determinación adoptada. El momento en que se
        recibe la solicitud es aquel en que haya entrado en nuestro servidor.
      </Paragraph>
      <Paragraph className="paragraph">
        Una vez que haya sido enviada la contestación en el plazo señalado, MiDoc tendrá 15 (quince)
        días hábiles para ejecutar el Acceso, Rectificación, Cancelación u Oposición de los datos
        personales conforme a la solicitud o derechos similares.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc solicita al Usuario que actualice sus datos cada vez que éstos sufran alguna
        modificación, ya que esto permitirá brindarle un servicio eficiente y personalizado.
      </Paragraph>
      <Paragraph className="paragraph">
        Cuando los datos hayan dejado de ser necesarios para el cumplimiento de las finalidades
        previstas por este aviso de privacidad y las disposiciones legales aplicables, deberán ser
        cancelados, bloqueados y suprimidos por ministerio de Ley.
      </Paragraph>
      <Title className="title" level={2}>
        Pasos para revocar su consentimiento en cualquier momento
      </Title>
      <Paragraph className="paragraph">
        Usted podrá en cualquier momento revocar el consentimiento que ha otorgado para el
        tratamiento de sus datos personales de acuerdo con el presente Aviso de Privacidad. Para
        ello, es necesario que siga el siguiente procedimiento:
        <ol>
          <li>
            Presentar una solicitud por escrito debidamente firmada, dirigida a MiDoc, al domicilio
            que aparece en el primer párrafo del presente Aviso de Privacidad o mediante correo
            electrónico a la dirección
            <a href="mailto:contacto@midocapp.com"> contacto@midocapp.com </a>, a efecto de iniciar
            el procedimiento.
          </li>
          <li>
            Señalar con precisión si desea revocar su consentimiento para todas las autorizaciones
            que el presente Aviso de Privacidad prevé o solo para alguna o algunas de ellas.
          </li>
          <li>Proporcionar los datos que permitan su identificación como usuario.</li>
          <li>
            MiDoc dará respuesta a su solicitud de revocación en un plazo de 20 días hábiles
            contados a partir del día en que se reciba su solicitud.
          </li>
        </ol>
      </Paragraph>
      <Title className="title" level={2}>
        Disposiciones finales
      </Title>
      <Paragraph className="paragraph">
        Si el Titular utiliza los servicios de la Página Web de MiDoc, significa que ha leído,
        entendido y acordado los términos antes expuestos.
      </Paragraph>
      <Paragraph className="paragraph">
        Si usted acepta haber leído el presente Aviso de Privacidad y no manifiesta su oposición
        para que sus datos personales sean tratados o transferidos, se entenderá que ha otorgado su
        consentimiento para ello.
      </Paragraph>
      <Paragraph className="paragraph">
        Este Aviso de Privacidad estará regido y será interpretado de conformidad con la LFPDPPP, su
        Reglamento y los Lineamientos del Aviso de Privacidad.
      </Paragraph>
      <Title className="title" level={2}>
        Cambios al aviso de privacidad
      </Title>
      <Paragraph className="paragraph">
        MiDoc se guarda el derecho de hacer en cualquier momento modificaciones al presente Aviso de
        Privacidad, los cuales notificaremos al usuario a través de nuestro sitio{' '}
        <a href="https://midocapp.com">midocapp.com</a> en la sección de aviso de privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        Estas modificaciones estarán siempre a la disposición del Usuario en la Página Web{' '}
        <a href="https://midocapp.com">midocapp.com</a> en la sección de aviso de privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        Se le notifica que el Aviso de Privacidad ha sido modificado por última vez el día 15 de
        noviembre del 2019.
      </Paragraph>
      <hr />
      <Title className="title" level={1} id="paciente">
        AVISO DE PRIVACIDAD <i>Del paciente</i>
      </Title>
      <Title className="title" level={2}>
        Responsable del Tratamiento
      </Title>
      <Paragraph className="paragraph">
        Mi Doc Technologies S.A.P.I. DE C.V. (en adelante referido como “MiDoc”) con domicilio en
        Puebla 403, Col. Roma Norte, Ciudad de México, expide el presente Aviso de Privacidad para
        que el titular de los datos personales (en adelante referido como “el Titular” o “el
        Usuario”) tenga un mayor conocimiento sobre el uso y trato que MiDoc les dará a dichos datos
        personales recabados por medio de nuestra aplicación móvil (en adelante referido como
        “Aplicación”).
      </Paragraph>
      <Title className="title" level={2}>
        Finalidades del tratamiento
      </Title>
      <Paragraph className="paragraph">
        Los datos personales que recabamos a través de la aplicación móvil (la “Aplicación”) los
        utilizaremos para las siguientes finalidades que son necesarios para poder prestarle
        nuestros servicios, ya sea para uso propio o de otros Titulares de los servicios de la
        Aplicación:
        <ol>
          <li>
            Para que el Titular pueda utilizar la Aplicación y los demás servicios que presta MiDoc
            a través de la misma.
          </li>
          <li>
            Dar cumplimiento a obligaciones contraídas con el Titular, derivadas de los productos o
            servicios suministrados por MiDoc. Informar y enviarle alertas sobre cambios en nuestros
            términos y condiciones y el presente aviso de privacidad.
          </li>
          <li>
            Informar y enviarle alertas sobre cambios en nuestros términos y condiciones y el
            presente aviso de privacidad.
          </li>
          <li>
            Realizar estudios internos sobre los datos demográficos, intereses y comportamiento de
            los Titulares; lo anterior se realiza a través de sistemas automatizados y dicho sistema
            no arroja datos personales del Titular.
          </li>
          <li>
            Almacenar la información que se proporciona por el Titular dentro de los servidores.
          </li>
          <li>Verificar y confirmar la identidad o cualquier dato del Titular.</li>
          <li>
            Realizar y dar seguimiento a preguntas, investigaciones y/o revisiones de las
            actividades, operaciones, quejas y/o reclamaciones del Titular relacionados con los
            productos o servicios proporcionados por MiDoc o por terceros.
          </li>
          <li>Dar cumplimiento a cualquier disposición legal aplicable.</li>
        </ol>
      </Paragraph>
      <Title className="title" level={2}>
        Datos personales que serán recabados y tratados
      </Title>
      <Paragraph className="paragraph">
        1. <b>Registro:</b> Al momento en que el Titular se registra en la Aplicación proporcionara:
        su correo electrónico, nombre, apellido paterno, apellido materno, número de teléfono móvil
        y fecha de nacimiento para crear una cuenta en MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        Así mismo el Titular de la cuenta proporcionará la siguiente información dentro de la
        Aplicación:
        <ul>
          <li>Sexo</li>
          <li>Estatura (opcional)</li>
          <li>Peso (opcional)</li>
          <li>
            Información de pagos: tarjeta de crédito/débito o cuenta Paypal (únicamente para fines
            de pago de parte del Titular y no almacenados por MiDoc)
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc tendrá acceso a los datos personales del Titular anteriormente mencionados y que el
        Titular proporciona en la Aplicación. MiDoc utilizará dichos datos para verificar y
        confirmar que la información es verídica y para las finalidades mencionadas en el presente
        aviso de privacidad. MiDoc no utilizará los datos antes mencionados para fines
        propagandísticos o para ofrecerle servicios o productos. En el supuesto en que el Titular de
        los datos personales desee recibir cualquier producto de terceros y lo contrate por medio de
        la Aplicación, el Titular acepta que MiDoc puede compartir los datos personales del Titular
        con el tercero proveedor de los productos.
      </Paragraph>
      <Paragraph className="paragraph">
        2. <b>Almacenamiento de datos:</b> Toda la información que otorgue el Titular de los datos a
        un tercero a través de la Aplicación mediante el servicio de almacenamiento de datos será
        responsabilidad del Titular de los datos y del tercero al que se le proporcione. El tercero
        que reciba la información del Titular, al aceptar el presente aviso de privacidad se
        compromete a tratar la información recibida a través de la Aplicación de acuerdo con sus
        políticas de privacidad y la legislación aplicable. El Titular y el tercero al aceptar este
        aviso de privacidad, reconocen que a través de la Aplicación pudieran recibir datos
        considerados como sensibles, y por tanto, será su responsabilidad realizar cualquier acción
        necesaria para el correcto tratamiento de dichos datos.
      </Paragraph>
      <Paragraph className="paragraph">
        La información que se proporciona por el Titular de los datos será almacenada por receptores
        de extremo a extremo. MiDoc sólo utilizará la información que el Titular y los terceros
        compartan, a través de la Aplicación, para realizar estudios internos de conformidad con las
        finalidades enlistadas en el numeral II establecidas en el presente aviso de privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc no utilizará dicha información para fines propagandísticos o para ofrecerle servicios
        o productos.
      </Paragraph>
      <Paragraph className="paragraph">
        Las comunicaciones entre el Titular y terceros pasan por un proceso de disociación a efecto
        de que MiDoc o cualquier tercero no pueda asociar al Titular o permitir la identificación
        del mismo. Los datos e información obtenidos por MiDoc a través de la Aplicación es
        utilizada y obtenida de manera anónima y se usará únicamente para fines estadísticos y para
        el mejoramiento de nuestros productos.
      </Paragraph>
      <Paragraph className="paragraph">
        3. <b>Servicios de atención al cliente:</b> El Titular, como parte de los servicios podrá
        proporcionar a MiDoc información relacionada con el uso de la Aplicación y los servicios
        prestados por medio de la misma. Dichos datos serán tratados por MiDoc para ponernos en
        contacto con el Titular con el fin de prestar atención directa al cliente respecto a la
        Aplicación.
      </Paragraph>
      <Paragraph className="paragraph">
        4. <b>Información de pago:</b> MiDoc utiliza proveedores externos para poder procesar los
        pagos que se realizan a través de la Aplicación. MiDoc no tiene acceso a dichos datos de
        pago. El Titular, al aceptar el presente aviso de privacidad deberá cerciorarse de revisar y
        aceptar las políticas de privacidad y el tratamiento que le dan los proveedores de pago a
        sus datos.
      </Paragraph>
      <Paragraph className="paragraph">
        El presente aviso privacidad aplica a la información que recopilamos en y a través de la
        Aplicación o que el Titular de los datos proporcione directamente cuando visita o utiliza la
        Aplicación, servicios en línea, servicios de mensajería, redes sociales y cuando obtenemos
        información de otras fuentes que están permitidas por la legislación aplicable. La
        Aplicación contiene enlaces a sitios de terceros que no son propiedad ni están controlados
        por MiDoc.
      </Paragraph>
      <Title className="title" level={2}>
        Transferencia de datos
      </Title>
      <Paragraph className="paragraph">
        Le informamos que sus datos personales son compartidos dentro y fuera del país con los
        usuarios registrados en la página web{' '}
        <a href="https://www.midocapp.com">www.midocapp.com</a>
        con los que el Titular se contacta directamente con la finalidad de recibir consejo médico.
      </Paragraph>
      <Paragraph className="paragraph">
        El Titular, al aceptar y hacer uso de la Aplicación, acepta que sus datos personales sean
        compartidos con otros usuarios registrados en la página web{' '}
        <a href="https://www.midocapp.com">www.midocapp.com</a>
      </Paragraph>
      <Paragraph className="paragraph">
        Asimismo, en el supuesto en que el Titular de los datos realicé una solicitud de productos a
        terceros por medio de la Aplicación, el Titular al aceptar estos términos y condiciones
        reconoce y acepta que MiDoc podrá compartir su teléfono móvil, nombre, apellidos y correo
        electrónico con dichos terceros para que puedan proveerle los productos.
      </Paragraph>
      <Paragraph className="paragraph">
        Los datos del Titular podrán ser transferidos por MiDoc sin consentimiento del titular en
        los supuestos establecidos en la legislación aplicable.
      </Paragraph>
      <Paragraph className="paragraph">
        En cualquier otro caso, MiDoc requerirá su autorización para realizar la transferencia de
        datos que resulte necesaria para la prestación de los servicios solicitados.
      </Paragraph>
      <Title className="title" level={2}>
        Derechos de Acceso, Rectificación, Cancelación y Oposición
      </Title>
      <Paragraph className="paragraph">
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos
        y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la
        corrección de su información personal en caso en que este desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestro registros o bases de datos cuando
        considere que la misma no está siendo utilizada conforme a los principios, deberes y
        obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos
        personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
      </Paragraph>
      <Paragraph className="paragraph">
        Para el ejercicio de los derechos ARCO usted deberá presentar la solicitud respectiva vía
        correo electrónico a contacto@midocapp.com. Dicha solicitud deberá contener: (i) su teléfono
        móvil, nombre completo y correo electrónico para comunicarle la respuesta; (ii) copia del
        documento que acredite la identidad o, en su caso, la representación legal (iii) la
        descripción de los datos personales de los que se busca ejercer algún derecho ARCO; (iv)
        cualquier otro elemento que facilite la localización de los datos personales; (v) el derecho
        ARCO que pretende ejercer; y (vi) un breve relato de los motivos por lo que pretende ejercer
        el derecho ARCO.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc le comunicará dentro de un plazo de 20 (veinte) días hábiles siguientes a la
        presentación de su solicitud, la respuesta a la misma. La cancelación de sus datos estará
        sujeta a lo previsto en el artículo 26 de la Ley Federal de Protección de Datos Personales
        en Posesión de los Particulares.
      </Paragraph>
      <Title className="title" level={2}>
        Revocación del consentimiento
      </Title>
      <Paragraph className="paragraph">
        Usted puede revocar el consentimiento para el tratamiento de sus datos personales, a efecto
        de lo cual, usted deberá enviar la solicitud respectiva vía correo electrónico a la
        dirección <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>, acompañada de la
        información y documentación mencionada en el rubro “Derechos de Acceso, Rectificación,
        Cancelación y Oposición” del presente aviso de privacidad. Sin embargo, es importante tener
        en cuenta que para ciertos fines, la revocación de su consentimiento podría implicar que
        MiDoc no se encuentre en posibilidades de seguir prestando el servicio que solicitó, o la
        conclusión de su relación con MiDoc. Asimismo, deberá considerar que no en todos los casos
        podremos atender su solicitud o detener el uso de forma inmediata, ya que es posible que por
        alguna obligación legal requiramos seguir tratando sus datos personales por un plazo
        adicional.
      </Paragraph>
      <Paragraph className="paragraph">
        Para revocar su consentimiento deberá presentar su solicitud a través del sitio o de la
        Aplicación. Para conocer el procedimiento y requisitos para la revocación del
        consentimiento, usted podrá llamar comunicarse a través del correo electrónico{' '}
        <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>.
      </Paragraph>
      <Title className="title" level={2}>
        Limitación al uso y divulgación de datos personales
      </Title>
      <Paragraph className="paragraph">
        Adicionalmente, con objeto de que usted pueda limitar el uso y divulgación de su información
        personal, le ofrecemos los siguientes medios:
      </Paragraph>
      <Paragraph className="paragraph">
        Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la
        Procuraduría Federal del Consumidor (“PROFECO”), con la finalidad de que sus datos
        personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o
        servicios. Para mayor información sobre este registro, usted puede consultar el portal de
        Internet de la PROFECO, o bien ponerse en contacto directo con esta.
      </Paragraph>
      <Title className="title" level={2}>
        Candados de seguridad
      </Title>
      <Paragraph className="paragraph">
        Con la finalidad de brindar una mayor protección y confidencialidad de las comunicaciones
        entre los usuarios registrados dentro de la Aplicación, MiDoc implementará todas las medidas
        de seguridad posibles.
      </Paragraph>
      <Paragraph className="paragraph">
        No obstante, MiDoc no será responsable por las vulneraciones a la seguridad de la Aplicación
        que pudieran ser ocasionadas por terceros.
      </Paragraph>
      <Title className="title" level={2}>
        El uso de cookies, web beacons o cualquier otra tecnología similar o análoga
      </Title>
      <Paragraph className="paragraph">
        <b>1. Sitios para recopilar información.</b>
        <br />
        En MiDoc utilizamos diversas plataformas y sitios para recopilar información que usan
        &quot;cookies&quot;, que son archivos de texto que se colocan en su computadora, para ayudar
        al sitio a analizar cómo los Titulares los usan.
      </Paragraph>
      <Paragraph className="paragraph">
        <b>2. Información recopilada a través de cookies y tecnologías similares.</b>
        <br />
        MiDoc y los terceros proveedores de servicios utilizan “cookies”, “web beacons” y otras
        tecnologías para recibir y almacenar ciertos tipos de información cada vez que los Titulares
        interactúan con la Aplicación a través de su dispositivo móvil. Una “cookie” es un pequeño
        archivo que contiene una cadena de caracteres que se envía a su computadora cuando visita un
        sitio web. Cuando vuelve a visitar la Aplicación o los sitios de los proveedores de
        servicios, la “cookie” les permite a los sitios de los proveedores de servicios reconocer su
        navegador. Las “cookies” pueden almacenar identificadores únicos, preferencias del Titular y
        otra información. Puede reiniciar su navegador para rechazar todas las “cookies” o para
        indicar cuándo se está enviando una “cookie”. Sin embargo, es posible que algunas funciones
        o servicios de la Aplicación o los sitios de los proveedores de servicios no funcionen
        correctamente sin “cookies”. Utilizamos “cookies” para mejorar la calidad de nuestro
        servicio, incluso para almacenar las preferencias de los Titulares, hacer un seguimiento de
        las tendencias de los Titulares.
      </Paragraph>
      <Paragraph className="paragraph">
        Por su parte, “web beacons” son imágenes insertadas en una página de internet o correo
        electrónico, que puede ser utilizado para monitorear el comportamiento de un visitante, como
        almacenar información sobre la dirección IP del Titular, duración del tiempo de interacción
        en dicha página y el tipo de navegador utilizado, entre otros.
      </Paragraph>
      <Paragraph className="paragraph">
        Le informamos que utilizamos cookies y web beacons para obtener información personal de
        usted, como la siguiente:
        <ol>
          <li>Su tipo de navegador y sistema operativo.</li>
          <li>Las páginas de Internet que visita.</li>
          <li>Los vínculos que sigue.</li>
          <li>La dirección IP.</li>
          <li>El sitio que visitó antes de entrar al nuestro.</li>
        </ol>
      </Paragraph>
      <Paragraph className="paragraph">
        Estas cookies y otras tecnologías pueden ser deshabilitadas. Existen diversos sitios en la
        Aplicación que pueden conectarse con otros sitios web que no funcionan bajo las políticas de
        privacidad de la Aplicación. Cuando usted se conecta a otros sitios web, las políticas de
        privacidad de la Aplicación, así como el presente aviso ya no tienen validez. Invitamos a
        los Titulares a revisar las políticas de privacidad, así como el aviso de privacidad
        correspondiente de cada sitio antes de revelar cualquier información de identificación
        personal. Todos los datos que se recopilen deberán ser actualizados para los fines que
        fueron recabados y en caso de que los datos dejen de ser necesarios para las finalidades
        previstas por MiDoc deberán ser cancelados.
      </Paragraph>
      <Title className="title" level={2}>
        Modificaciones al Aviso de Privacidad
      </Title>
      <Paragraph className="paragraph">
        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
        derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
        productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios a
        nuestro modelo de negocio, o por otras causas.
      </Paragraph>
      <Paragraph className="paragraph">
        Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente
        aviso de privacidad, a través del envío de un mensaje de texto a su teléfono móvil cuando
        este nos sea proporcionado en su registro.
      </Paragraph>
      <Paragraph className="paragraph">
        <b>XI. Consentimiento.</b>
        <br />
        Al ingresar y usar la Aplicación, el Titular otorga su consentimiento para el tratamiento de
        sus datos de conformidad con el presente aviso de privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        Cualquier duda o comentario respecto al presente aviso de privacidad, así como el ejercicio
        de cualesquiera otros derechos que por la ley le corresponden, deberán dirigirse al
        encargado de avisos de privacidad al correo electrónico
        <a href="mailto:contacto@midocapp.com"> contacto@midocapp.com</a>.
      </Paragraph>
      <Paragraph className="paragraph">
        Sin perjuicio de lo anterior, en caso de que usted considere que el tratamiento de sus datos
        personales constituye alguna violación a las disposiciones de la ley, podrá acudir al
        Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
        Personales (INAI).
      </Paragraph>
      <Paragraph className="paragraph">Última actualización: 10 de Abril 2020</Paragraph>
    </section>
  );
};

export default PrivacyComponent;
