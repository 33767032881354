import React from 'react'
import { Button, Menu, Anchor } from 'antd'

import { links } from './constants'
import './styles.scss'

const { Link } = Anchor

const Links = ({ isAside, close }) => (
  <>
    {isAside ? (
      <Menu>
        <Anchor onClick={() => close(false)} className="header-links-side-menu" affix={false}>
          {links.map(link => (
            <span key={link.title}>
              {link.type === 'button' ? (
                <Button
                  onClick={() => {
                    window.open('mailto:contacto@midocapp.com', 'mail')
                  }}
                  shape="round"
                  // type="primary"
                >
                  {link.title}
                </Button>
              ) : (
                <Link href={link.path} title={link.title} />
              )}
            </span>
          ))}
        </Anchor>
      </Menu>
    ) : (
      <>
        {links.map(link => (
          <span key={link.title}>
            {link.type === 'button' ? (
              <Button
                onClick={() => {
                  window.open('mailto:contacto@midocapp.com', 'mail')
                }}
                shape="round"
                // type="primary"
              >
                {link.title}
              </Button>
            ) : (
              <a href={link.path}>{link.title}</a>
            )}
          </span>
        ))}
      </>
    )}
  </>
)

export default Links
