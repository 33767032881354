import React, { useState, useEffect, useContext } from 'react';
import { Table, Select, notification, Typography } from 'antd';
import { schedule as ScheduleConstants, values, title } from './constants';
import { User as UserApi } from '../../../api';
import { AuthContext } from '../../../contexts/Auth';
import { DoctorDataContext } from '../../../contexts/DoctorData';
import { GANewPage } from '../../../utils/analytics';
import './style.scss';

const Schedule = () => {
  const [schedule, setSchedule] = useState([]);
  const { Option } = Select;
  const { Title } = Typography;
  const { currentUser } = useContext(AuthContext);
  const { doctorData } = useContext(DoctorDataContext);
  useEffect(() => {
    GANewPage();
  }, []);
  useEffect(() => {
    if (doctorData) {
      // console.log(doctorData.schedule);
      setSchedule(doctorData.schedule ? doctorData.schedule : ScheduleConstants);
    }
  }, [doctorData]);

  const columns = [
    {
      title: 'Horario',
      dataIndex: 'hours',
      key: 'hours',
      fixed: 'left',
      render: hour => (
        <span className="last-day">
          {hour.start} - {hour.end}{' '}
          {hour.isLast && (
            <div className="next-day-label">
              <small>Día siguiente</small>
            </div>
          )}
        </span>
      ),
    },
    {
      title: 'Lunes',
      dataIndex: 'monday',
      key: 'monday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'monday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Martes',
      dataIndex: 'tuesday',
      key: 'tuesday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'tuesday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Miercoles',
      dataIndex: 'wednesday',
      key: 'wednesday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'wednesday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Jueves',
      dataIndex: 'thursday',
      key: 'thursday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'thursday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Viernes',
      dataIndex: 'friday',
      key: 'friday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'friday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Sabado',
      dataIndex: 'saturday',
      key: 'saturday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'saturday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Domingo',
      dataIndex: 'sunday',
      key: 'sunday',
      render: (text, record) => (
        <>
          <b>$ </b>
          <Select
            defaultValue={text}
            className="option"
            onChange={value => editHour({ day: 'sunday', key: record.key, value })}
          >
            {values.map(value => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
  ];

  const editHour = async ({ key, value, day }) => {
    const newSchedule = [...schedule];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newSchedule.length; i++) {
      if (schedule[i].key === key) {
        newSchedule[i][day] = value;
        setSchedule(newSchedule);
        break;
      }
    }
    if (currentUser) {
      const response = await UserApi.updateUserData({
        id: currentUser.uid,
        data: { schedule },
      });
      if (response === true) {
        notification.success({
          message: 'Tus horarios fueron actualizados',
        });
      }
    }
  };

  return (
    <div className="dashboard-schedule">
      <Title>{title}</Title>
      <Table
        scroll={{ x: 800 }}
        size="small"
        dataSource={schedule}
        pagination={false}
        columns={columns}
      />
    </div>
  );
};

export default Schedule;
