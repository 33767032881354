import { database } from '../config/firebase';
import { collectionNames } from './constants';
import { formatQuerySnapshot } from './utils';

export default {
  getDoctorHistory: async doctorId => {
    const historyRef = await database
      .collection(collectionNames.calls.title)
      .where(collectionNames.calls.fields.doctorId, '==', doctorId)
      .orderBy('timestamps.created', 'desc')
      .get();
    if (historyRef.empty === true) {
      return null;
    }

    return formatQuerySnapshot(historyRef);
    // console.log("asdasdads");
    // return true;
  },
};
