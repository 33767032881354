import React, { useContext, useEffect, useState } from 'react';
import { Form, Typography, Select, Input, Button, notification, Spin } from 'antd';

import { title, buttonSubmit, formErrors, rexRules, warningMessage } from './constants';
import { DoctorDataContext } from '../../../contexts/DoctorData';
import { User as UserApi, Banks as BanksAPI } from '../../../api';
import { AuthContext } from '../../../contexts/Auth';
import { AppContext } from '../../../contexts/App';
import { GANewPage, GAEvent } from '../../../utils/analytics';

const BankAccount = () => {
  const { useForm, Item } = Form;
  const { Title } = Typography;
  const { Option } = Select;
  const [form] = useForm();
  const { doctorData, setDoctorData } = useContext(DoctorDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { setAppLoading } = useContext(AppContext);

  const [banks, setBanks] = useState({
    size: 0,
    data: [],
  });
  useEffect(() => {
    GANewPage();
  }, []);
  useEffect(() => {
    // setBanks(banksList);
    const getSetupData = async () => {
      setAppLoading(true);
      try {
        const response = await BanksAPI.getBanks();
        setBanks(response);
      } catch (e) {
        notification.error({
          message: 'Server Error',
        });
      }
      setAppLoading(false);
    };
    getSetupData();
    return () => {
      form.resetFields();
    };
  }, [form, setAppLoading]);

  useEffect(() => {
    // console.log("DOCTOR", doctorData);
    if (
      doctorData &&
      doctorData.bankAccount &&
      doctorData.bankAccount.bank.key &&
      doctorData.bankAccount.number
    ) {
      // console.log(
      //   "doctorData.bankAccount.bank.key",
      //   doctorData.bankAccount.bank.key
      // );
      form.setFieldsValue({
        number: doctorData.bankAccount.number,
        bank: doctorData.bankAccount.bank.key,
      });
    }
  }, [doctorData, form]);

  const handleChange = ({ name, value }) => {
    form.setFieldsValue({
      [name]: value,
    });
  };
  const handleSubmit = async values => {
    setIsLoading(true);
    const dataToSend = {
      bankAccount: {
        number: values.number,
        bankNumber: values.number.substr(6, 11),
        bank: {
          key: values.bank,
          name: banks.data.find(bank => bank.key === values.bank).name,
        },
      },
    };
    if (currentUser) {
      const response = await UserApi.updateUserData({
        id: currentUser.uid,
        data: dataToSend,
      });
      await setDoctorData(prevState => ({
        ...prevState,
        bankAccount: { ...dataToSend.bankAccount },
      }));
      if (response === true) {
        notification.success({
          message: 'Tus datos fueron actualizados',
        });
      }
    }
    GAEvent({
      category: 'web_user',
      action: 'Update bank account',
      label: 'Doctor Bank Account',
    });
    setIsLoading(false);
  };
  return (
    <div>
      <Title level={3}>{title}</Title>

      {doctorData && !doctorData.bankAccount && (
        <i>
          <b>{warningMessage}</b>
        </i>
      )}

      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Item
          label="Banco"
          rules={[
            {
              required: true,
              message: formErrors.bankName.required,
            },
          ]}
          name="bank"
        >
          <Select
            // size="large"
            defaultValue={form.getFieldValue('bank')}
            showSearch
            placeholder="Banco"
            onChange={value => handleChange({ name: 'bank', value })}
          >
            {banks.data.map(bank => (
              <Option value={bank.key} key={bank.key}>
                {bank.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={[
            { required: true, message: formErrors.bankNumber.required },
            {
              pattern: rexRules.numbers,
              message: formErrors.bankNumber.onlyNumbers,
            },
            { len: 18, message: formErrors.bankNumber.length },
          ]}
          label="Numero de cuenta(CLABE)"
          name="number"
        >
          <Input
            size="large"
            maxLength="18"
            value={form.getFieldValue('number')}
            placeholder="CLABE"
          />
        </Item>
        <Item>
          {isLoading ? (
            <Spin />
          ) : (
              <Button type="primary" htmlType="submit">
                {buttonSubmit}
              </Button>
            )}
        </Item>
      </Form>
    </div>
  );
};
export default BankAccount;
