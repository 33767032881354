interface SubText {
  title: string;
}

export interface IQuestionText {
  key: string;
  title: string;
  text: string;
  numbers?: SubText[];
  dots?: SubText[];
}

export const FAQtext: IQuestionText[] = [
  {
    key: 'QUESTION_1',
    title: '¿Cómo puedo comenzar a implementarlo con mis pacientes?',
    text: `
      <p>
        Comenta al paciente que si le surge alguna emergencia o duda médica puede contactarse por medio de la app contigo. Si te escriben por whatsapp, dentro de la app hay una opción para que mandes el link para que te escriban o llamen por la app.
      </p>
      <p>
        MiDoc ayuda a que centralices las llamadas y mensajes de todos tus pacientes, y decides si cobrar o no cada una de las interacciones.
      </p>
      <p>
        La idea es que en tu recetario o consulta dejes de dar tu número personal.  Puedes colocar en tu consultorio o receta el código QR único que viene en tu app que dirige al paciente directo a descargar la app y a tu perfil.
      </p>
    `,
  },
  {
    key: 'QUESTION_2',
    title: '¿Me puedes explicar más cómo funciona?',
    text: `
      <h4>Paciente:</h4>
      <p>
        Baja app y se registra. Después en un buscador localiza por nombre o cédula profesional  a su propio médico si gusta contactarlo.
      </p>
      <p>
        Una vez encontrado el médico, el paciente puede llamar o mandar mensaje; éstos generan un cobro al paciente, el cual se le deposita al médico.
      </p>
      <br/>

      <h4>Médico:</h4>
      <p>
        <ul>
          <li>Descarga la app o te registras en la página web. Una vez registrado entra a la sección “Mis horarios” para que puedas colocar tus tarifas; después entra a “Cuenta bancaria” para dar de alta a donde depositarte. También puedes editar tu perfil y agregarle una foto tuya, tu dirección de consultorio (si aplica), entre otros datos.</li>
          <li>Si te llaman, recibirás una llamada como cualquier otra de un número genérico, el cobró se hará hasta que se finalice la llamada ( no se cobra si no contestas o se corta antes de 20 segundos).</li>
          <li>Si te mandan mensaje, en tu pantalla principal lo verás y funciona como whatsapp. Cuando hayan finalizado el intercambio de mensajes puedes finalizar la interacción en el botón en el lado superior derecho que dice “Finalizar consulta” donde te dará la opción de cobrar lo que estableciste o no cobrar si así lo decides.</li>
        </ul>
      </p>
      <p>
        Listo ya estás listo para comunicarte con tus pacientes por medio de MiDoc.
      </p>
    `,
  },
  {
    key: 'QUESTION_3',
    title: '¿Cuando el paciente llama, no se ve el número real del médico?',
    text: `
      <p>
        No. Al hacer el paciente la llamada se crea un número genérico, sin revelar el verdadero número del médico, así se evita el contacto directo y gratuito del paciente hacia su doctor. Esto funciona con un programa que genera estos números aleatorios, a los cuales no les puedes llamar una vez terminada la llamada.
      </p>
    `,
  },
  {
    key: 'QUESTION_4',
    title: '¿Tiene costo la aplicación?',
    text: `
      <p>
        No, es gratuita para médico y paciente.
      </p>
    `,
  },
  {
    key: 'QUESTION_5',
    title: '¿Se puede cambiar el costo de la llamada o mensaje?',
    text: `
      <p>
        Sí, se puede modificar en la sección “Mis horarios” según las necesidades y preferencias de cada médico.
      </p>
    `,
  },
  {
    key: 'QUESTION_6',
    title: '¿Cómo comparto la app a mis pacientes?',
    text: `
      <p>
        Puedes mandarles un link de descarga  de la app e instrucciones: desde tu Menú hay un botón que dice “Comparte la app”, y se lo puedes mandar por whatsapp a pacientes.
      </p>
    `,
  },
  {
    key: 'QUESTION_7',
    title: '¿Dónde puedo revisar el historial de llamadas, mis datos o hacer cambios?',
    text: `
      <p>
        Al registrarte en esta página creas tu usuario como médico para la App. Podrás ingresar por aquí a tu <b>Panel de control</b> donde podrás encontrar cosas como: historial de llamadas, registro de cuenta bancaria a depositar, datos fiscales, panel para poder cambiar costo de llamadas, entre otras cosas. En tu app tu pantalla principal es el historial de mensajes y llamadas, y del lado izquierdo tienes tu menú con todo lo demás.
      </p>
    `,
  },
  {
    key: 'QUESTION_8',
    title: '¿Tengo que dar recibo/factura por cada llamada?',
    text: `
      <p>
        Sólo si el paciente la pide. Y si la pide, no te preocupes, nosotros le mandamos una factura que se genera automáticamente con los datos que te pedimos en tu portal. Recuerda que es un ingreso extra que no se tenía antes, por lo que queremos que todo sea en regla según las leyes fiscales. Al final del día sigues recibiendo tu ganancia.
      </p>
    `,
  },
  {
    key: 'QUESTION_9',
    title: '¿Cómo gana MiDoc en esto?',
    text: `
      <p>
        Mi Doc cobra 20% de servicio en cada llamada. Ejemplo: si tus mensajes tienen un costo de $50:  $40 son para el médico y $10 para MiDoc. Este servicio que se cobra incluye:
        <ul>
          <li>Uso de software de MiDoc</li>
          <li>Recolección de transferencia de pagos</li>
          <li>Comisión de tarjeta de crédito</li>
        </ul>
      </p>
    `,
  },
  {
    key: 'QUESTION_10',
    title: '¿Cómo me depositan y cuándo?',
    text: `
      <p>
        Se te depositan tus ganancias del mes los primeros 5 días del mes siguiente, o sea aproximadamente cada 30 días. Se hace una transferencia de nuestra cuenta a la cuenta que diste de alta en tu  Panel de Control. Si tienes alguna duda contáctanos a <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>
      </p>
    `,
  },
  {
    key: 'QUESTION_11',
    title: '¿Por qué hicieron esto?',
    text: `
      <p>
        Detectamos con familiares médicos que existía un problema de contacto excesivo de pacientes. Esto es una herramienta para ayudar a los médicos, para que por medio de ella los pacientes se comuniquen con sus propios doctores.
      </p>
      <p>
        La función de esta Aplicación móvil es:
        <ol>
          <li>Evitar que el Médico dé su número personal</li>
          <li>Generar una ganancia para el Médico si el paciente decide llamarlo o mandarle mensaje</li>
        </ol>
      </p>
    `,
  },
  {
    key: 'QUESTION_12',
    title: '¿Si no me gusta la App o su funcionamiento, me puedo salir?',
    text: `
      <p>
        ¡Claro! Recuerda que es una herramienta que creamos para ti, tú puedes optar por usarla o no usarla. Te invitamos a usarla porque resuelve el problema de recibir tantas llamadas, mensajes y no recibir nada a cambio. Sin embargo, queremos saber por qué no te gustó o qué es lo que podemos mejorar, escríbenos a <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a> y veremos qué podemos hacer para mejorar y adecuarnos a tus necesidades.
      </p>
    `,
  },
  {
    key: 'QUESTION_13',
    title: '¿Dudas o comentarios?',
    text: `
      <p>
        <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>
      </p>
    `,
  },
];

export const title = 'Preguntas frecuentes';
