import React, { useEffect, useState } from 'react';

import { Typography } from 'antd';
import { useMedia } from 'use-media';

import appStoreIcon from '../../../assets/img/appstore-icon.png';
import googlePlayIcon from '../../../assets/img/google-icon.png';
import mobileImg from '../../../assets/img/mobileApp.png';
import mobileImg1 from '../../../assets/img/mobileApp4.png';
import mobileImg2 from '../../../assets/img/mobileApp5.png';
import mobileImg3 from '../../../assets/img/mobileApp6.png';
import { subTitle, title } from './constants';
import LandingForm from './form';
import './styles.scss';

interface MobileAppLinksBottomProps {
  showMobilePhone: boolean;
}

const { Title } = Typography;

const MobileAppLinksBottom: React.FC<MobileAppLinksBottomProps> = ({ showMobilePhone }) => {
  const mobileImgStack = [mobileImg, mobileImg1, mobileImg2, mobileImg3];
  const [mobileImgSrc, setMobileImgSrc] = useState<{ src: string; tick: number }>({
    src: mobileImgStack[0],
    tick: 0,
  });
  useEffect(() => {
    const changeMobileAppImage = setInterval(
      () =>
        setMobileImgSrc(prevState => ({
          tick: prevState.tick + 1 === 4 ? 0 : prevState.tick + 1,
          src: mobileImgStack[prevState.tick + 1 === 4 ? 0 : prevState.tick + 1],
        })),
      3000
    );
    return () => clearInterval(changeMobileAppImage);
  }, []);

  return (
    <div className="cta-left__mobileapps" style={{ display: 'flex' }}>
      {!showMobilePhone && (
        <img src={mobileImgSrc.src} className="cta-left__mobileapps__img" alt="mobileapp-screen" />
      )}
      <div className="links">
        <h4>Descarga la app</h4>
        <a
          href="https://play.google.com/store/apps/details?id=com.midoc.midoc"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img style={{ width: '7vw' }} src={googlePlayIcon} alt="" />
        </a>
        <a
          href="https://apps.apple.com/us/app/midoc/id1494637379?ls=1"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img style={{ width: '7vw' }} src={appStoreIcon} alt="" />
        </a>
      </div>
    </div>
  );
};
const MobileAppLinksSection = (
  <div className="cta-left__mobileapps-section">
    <h3>Descarga la app</h3>
    <div className="icons">
      <a
        href="https://play.google.com/store/apps/details?id=com.midoc.midoc"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img style={{ width: '20vw' }} src={googlePlayIcon} alt="" />
      </a>
      <a
        href="https://apps.apple.com/us/app/midoc/id1494637379?ls=1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img style={{ width: '20vw' }} src={appStoreIcon} alt="" />
      </a>
    </div>
  </div>
);
const CallToAction: React.FC = () => {
  const showLinksOnBottom = useMedia({ minWidth: '660px' });
  const showMobilePhone = useMedia({ maxHeight: '530px' });
  // const showMobilePhone = true
  return (
    <section id="call-to-action" className="landing-cta shadow-2">
      <div className="landing-cta__left-side cta-left">
        <div className="cta-left__titles">
          <Title level={1} className="main-title">
            {title}
          </Title>
          <Title level={3} className="sub-title">
            {subTitle}
          </Title>
        </div>
        {showLinksOnBottom && <MobileAppLinksBottom showMobilePhone={showMobilePhone} />}
      </div>
      <div className="landing-cta__right-side cta-right">
        <LandingForm />
      </div>
      {!showLinksOnBottom && MobileAppLinksSection}
    </section>
  );
};
export default CallToAction;
