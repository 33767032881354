import React from 'react';

import { Col, Row, Typography } from 'antd';

import moneyTransfer from '../../../assets/img/money-transfer.svg';
import { enfasis } from './constants';
import './style.scss';

const BillingComponent: React.FC = () => (
  <section className="billing-container">
    <Row justify="space-around" className="row" align="middle">
      <Col className="money-transfer" xs={18} sm={18} md={18} lg={8} xl={8}>
        <img src={moneyTransfer} alt="money transfer" className="image-money-transfer" />
      </Col>
      <Col
        // span={10}
        // offset={4}
        xs={18}
        sm={18}
        md={18}
        lg={8}
        xl={8}
        className="text-container"
      >
        <Typography.Title level={2}>{enfasis.title}</Typography.Title>
        <p className="text">
          Si tu paciente decide contactarte se le hace un cobro según lo decidas. Al final de cada
          interacción podrás escoger si cobrar o no la tarifa que estableciste o no hacer ningún
          cobro por esa interacción.{' '}
          <span className="text-highlight">
            Para el médico es gratuito el uso de la plataforma, en ningún momento se te hace algún
            cobro.
          </span>
        </p>
      </Col>
    </Row>
  </section>
);

export default BillingComponent;
