import React from 'react';

import '@config/analytics';
import 'antd/dist/antd.css';

import { AppProvider } from './contexts/App';
import { AuthProvider } from './contexts/Auth';
import Routes, { RenderRoutes } from './routes';
// import { database } from 'firebase';
// import app from '@config/firebase';
// import NetworkDetector from "./components/NetworkDetector";

const App: React.FC = () => {
  return (
    <>
      <AppProvider>
        <AuthProvider>
          <RenderRoutes routes={Routes} />
        </AuthProvider>
      </AppProvider>

      {/* <NetworkDetector /> */}
    </>
  );
};

export default App;
