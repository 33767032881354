import 'firebase/analytics';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY || 'AIzaSyAe8l_8N8Ezst_5DtJq0GvdVzrLHL1CSEo',
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN || 'mi-doc.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE || 'https://mi-doc.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'mi-doc',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'mi-doc.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID || '495892463146',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:653002134360:web:3c2e688e8d1d2bab8f0b2d',
};
const fireAnalytics = null;
export const app = firebase.initializeApp(firebaseConfig);

export const database = firebase.firestore();
export const firebaseAuth = app.auth();
export const storage = firebase.storage();
export const analytics = fireAnalytics;
export const functions = firebase.functions();
export default app;
