import { firebaseAuth, database, functions } from '../config/firebase';

import { collectionNames, status } from './constants';
import { formatQuerySnapshot, formatErrorResponse } from './utils';

export default {
  loadSpecialties: async () => {
    try {
      const querySnapshot = await database
        .collection(collectionNames.medicalSpecialties)
        .where(status.active, '==', true)
        .get();
      const response = formatQuerySnapshot(querySnapshot);
      return response;
    } catch (error) {
      return error;
    }
  },
  loadUniversties: async () => {
    try {
      const querySnapshot = await database.collection(collectionNames.uni).get();
      const response = formatQuerySnapshot(querySnapshot);
      return response;
    } catch (error) {
      return error;
    }
  },
  sendEmailVerification: async ({ email, uid, emailToken }) => {
    try {
      const verifyUserRef = functions.httpsCallable('sendEmailVerification');
      const response = await verifyUserRef({ uid, email, emailToken });
      // console.log("ResponseSEND EMAIL", response);
      if (response.data !== true) {
        throw new Error('Error');
      }
      return true;
    } catch (e) {
      return e;
    }
  },
  verifyUser: async ({ uid, token }) => {
    try {
      const verifyUserRef = functions.httpsCallable('verifyUser');
      const response = await verifyUserRef({ uid, token });
      // console.log("RESPOSE", response);
      if (response.data !== true) {
        throw new Error('invalid token');
      }
      return true;
    } catch (e) {
      return { status: false, message: e.message };
    }
  },

  createUser: async data => {
    try {
      const signUpUserRef = functions.httpsCallable('signUpUser');
      const createUserResponse = await signUpUserRef({
        ...data,
        type: 'doctor',
      });
      if (createUserResponse.data.errorInfo) {
        throw formatErrorResponse(createUserResponse.data.errorInfo);
      }
      return createUserResponse.data;
      // if (createUserResponse.data.errorInfo) {

      // }
      // if (
      //   (createUserResponse &&
      //     createUserResponse.data &&
      //     createUserResponse.data.type &&
      //     createUserResponse.data.type === "error") ||
      //   createUserResponse.data.errorInfo
      // ) {
      //   return createUserResponse.data;
      // }
      // // await firebase
      // //   .auth()
      // //   .signInWithEmailAndPassword(data.email, data.password);
      // return createUserResponse;
    } catch (error) {
      return {
        error: true,
        data: error,
      };
    }
  },
  confirmEmail: async data => {
    try {
      await firebaseAuth.applyActionCode(data.oobCode);
      return true;
    } catch (e) {
      return e;
    }
  },
};
