export const lastStepText =
  'Hola Doctor! Solo te falta un paso: entra al correo que te mandamos para verificar tu cuenta y listo!';
export const sendEmailAgainText = 'Volver enviar el correo';

export const sendEmailErrorText = 'Error al enviar el correo';

export const sendEmailSuccessText =
  'Ya te registraste y el correo de verificacion fue enviado, verifique tu bandeje de entrada';

export const checkSpamMessage =
  'Si el correo no te llega, verifica la sección de spam o correo no deseado de tu correo.';
