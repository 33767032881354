import signUp from './signup';
import auth from './auth';
import user from './user';
import history from './history';
import banks from './banks';

export const SignUp = signUp;
export const Banks = banks;
export const Auth = auth;
export const User = user;
export const History = history;
