import React, { useContext, useState } from 'react';
import { Typography, Button, Affix, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { useMedia } from 'use-media';
import { MenuOutlined } from '@ant-design/icons';

import logo from '../../assets/img/logo.png';
import LoginComponent from './LoginForm';
import Links from './Links';
import { AuthContext } from '../../contexts/Auth';
import { AppContext } from '../../contexts/App';
import Logo from '../Logo';
import { goToDashboardText } from './constans';
import './style.scss';

const RenderLoginSection = () => {
  const { currentUser } = useContext(AuthContext);
  const { appLoading } = useContext(AppContext);

  if (appLoading) {
    return <span>Cargando...</span>;
  }
  if (!currentUser) {
    return <LoginComponent forceRender />;
  }
  return (
    <Link to="/dashboard">
      <Button size="large" shape="round" type="primary">
        {goToDashboardText}
      </Button>
    </Link>
  );
};

const AsideMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Title } = Typography;
  return (
    <>
      <Button onClick={() => setIsModalOpen(!isModalOpen)} size="large" icon={<MenuOutlined />}>
        Menu
      </Button>
      <Drawer
        forceRender
        closable={false}
        onClose={() => setIsModalOpen(!isModalOpen)}
        visible={isModalOpen}
      >
        <Title level={3}>Menu</Title>
        <Links isAside close={setIsModalOpen} />
        <RenderLoginSection />
      </Drawer>
    </>
  );
};

const Header = props => {
  // const { Title } = Typography;
  const isWide = useMedia({ minWidth: '1250px' });
  return props.onlyLogo ? (
    <div className="header-onlyLogo">
      <Logo inLanding />
    </div>
  ) : (
    <Affix offsetTop={0}>
      <div className="header shadow-2">
        <div className="navbar-logo ">
          <img width="100px" src={logo} alt="Midoc Logo" />
          {/* <Title level={2}>MiDoc</Title> */}
        </div>
        {isWide ? (
          <>
            <Links /> <RenderLoginSection />
          </>
        ) : (
          <AsideMenu />
        )}
      </div>
    </Affix>
  );
};
export default Header;
