import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useMedia from 'use-media';
import {
  Col,
  Row,
  Input,
  InputNumber,
  Select,
  Form,
  Button,
  Radio,
  Divider,
  Spin,
  notification,
  Upload,
} from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import { removeAccents } from '@utils/string';
import { SignUp as SignUpApi, User as UserApi } from '../../api';
import rexRules from '../../utils/regex';
import {
  formPlaceholders,
  months,
  genders,
  // rexRules,
  errorMessages,
} from './constants';
import { AuthContext } from '../../contexts/Auth';
import { AppContext } from '../../contexts/App';
import GoogleAddress from '../GoogleAutocomplete';
import './styles.scss';

const ProfileForm = props => {
  const history = useHistory();
  const [medialSelectIsOpen, setMedicalSelectIsOpen] = useState(false);
  const isWide = useMedia({ minWidth: '1500px' });

  const smallYearInput = useMedia({ minWidth: '1330px' });
  const smallMonthInput = useMedia({ minWidth: '1160px' });
  const { Item } = Form;
  const [form] = Form.useForm();
  const initalValues = {
    name: '',
    email: '',
    lastName: '',
    license: '',
    fullName: '',
    specialty: '',
    university: '',
    subSpecialty: '',
    'birth-day': '1',
    'birth-month': 'January',
    'birth-year': 2000,
    phoneNumber: '',
    officeAddress: '',
    officePhoneNumber: '',
    gender: 'male',
    imageUrl: '',
    password: '',
    confirmPassword: '',
  };
  const { Option } = Select;

  const { Group, Password } = Input;
  const { currentUser } = useContext(AuthContext);
  const { setAppLoading } = useContext(AppContext);
  const [profileImage, setProfileImage] = useState({
    ref: null,
    src: null,
    loading: false,
    default: true,
  });
  const [medicalSpecialties, setMedicalSpecialties] = useState({
    size: 0,
    data: [],
  });
  const [universities, setUniversities] = useState({
    size: 0,
    data: [],
  });
  const [isSpecialtyCustom, setIsSpecialtyCustom] = useState(false);
  const [activeAddress, setActiveAddress] = useState('');

  useEffect(() => {
    const getSetupData = async (uid = '') => {
      if (!props.isRegisterForm) {
        setAppLoading(true);
        setProfileImage(prevState => ({
          ...prevState,
          loading: true,
        }));
      }
      setMedicalSpecialties(await SignUpApi.loadSpecialties());
      setUniversities(await SignUpApi.loadUniversties());
      if (!props.isRegisterForm && uid !== '') {
        const response = await UserApi.getUserData(uid);
        // Check if imageRef is setted
        if (response.imageUrl) {
          setProfileImage(prevState => ({
            ...prevState,
            ref: response.imageUrl,
          }));
        }
        if (response && response.specialty && response.specialty.type === 'custom') {
          setIsSpecialtyCustom(true);
        }
        form.setFieldsValue({
          name: response.name || '',
          email: response.email || '',
          lastName: response.lastName || '',
          license: response.license || '',
          fullName: `${response.name} ${response.lastName}`,
          specialty: (response.specialty && response.specialty.key) || '',
          university: (response.university && response.university.key) || '',
          subSpecialty: response.subSpecialty || '',
          'birth-day': (response.birthDay && response.birthDay.day) || '01',
          'birth-month': (response.birthDay && response.birthDay.month) || 'January',
          'birth-year': (response.birthDay && response.birthDay.year) || 2020,
          phoneNumber: response.phoneNumber.substr(3) || 0,
          officeAddress: (response.office && response.office.address) || '',
          officePhoneNumber: (response.office && response.office.phone) || '',
          gender: response.gender || 'male',
          // imageUrl: response.imageUrl
          //   ? await UserApi.getImageFromCollection(response.imageUrl, false)
          //   : await UserApi.getImageFromCollection("", true)
        });

        const imageURL = response.imageUrl
          ? await UserApi.getImageFromCollection(response.imageUrl, false)
          : await UserApi.getImageFromCollection('', true);
        setProfileImage(prevState => ({
          ...prevState,
          src: imageURL,
          loading: false,
          default: response.imageUrl && true,
        }));
        setActiveAddress((response.office && response.office.address) || '');
      }
      setAppLoading(false);
    };
    // console.log("CURRENtUSER", currentUser);
    getSetupData(currentUser ? currentUser.uid : '');
  }, [currentUser, setAppLoading, props.isRegisterForm, form]);
  useEffect(() => {
    if (props.isRegisterForm) {
      if (
        history &&
        history.location &&
        history.location.state &&
        history.location.state.values.name
      ) {
        form.setFieldsValue({
          name: history.location.state.values.name,
          lastName: `${history.location.state.values.firstLastName} ${history.location.state.values.secondLastName}`,
          email: history.location.state.values.email,
        });
      }
    }
    // console.log(form.getFieldsValue());
    return () => {
      form.resetFields();
    };
  }, [form, history, props.isRegisterForm]);

  useEffect(() => {
    const setAddress = () => {
      form.setFieldsValue({ officeAddress: activeAddress });
    };
    setAddress();
  }, [activeAddress, form]);
  useEffect(() => {
    // window.scrollTo(0, 0);
    if (props.isRegisterForm && !props.isLoading) {
      form.scrollToField('email');
    }
  }, [form, props]);

  const handleForm = ({ name, value }) => {
    form.setFieldsValue({
      [name]: value,
    });
  };
  const handleSubmit = values => {
    const dataToSubmit = {
      name: values.name,
      searchName:
        removeAccents(`${values.name} ${values.lastName}`.replace(/\s/g, '').toLowerCase()) || '',
      type: 'doctor',
      email: values.email,
      lastName: values.lastName,
      fullName: `${values.name} ${values.lastName}`,
      license: values.license,
      specialty: {
        key: values.specialty,
        name: isSpecialtyCustom
          ? values.specialty
          : medicalSpecialties.data.find(
            medicalSpecialty => medicalSpecialty.id === values.specialty
          ).name,
        type: isSpecialtyCustom ? 'custom' : 'inDB',
      },
      subSpecialty: values.subSpecialty,
      university: {
        key: values.university,
        name: universities.data.find(university => university.id === values.university).name,
      },
      specialtyUniversity: {
        key: values.specialtyUniversity,
        name: universities.data.find(university => university.id === values.university).name,
      },
      phoneNumber: `+52${values.phoneNumber}`,
      officeAddress: values.officeAddress,
      office: {
        address: values.officeAddress,
        phone: values.officePhoneNumber,
      },
      officePhoneNumber: values.officePhoneNumber,
      gender: values.gender,
      birthDay: {
        day: values['birth-day'],
        month: values['birth-month'],
        year: values['birth-year'],
      },
      password: props.isRegisterForm && values.password,
    };
    // if (profileImage.ref) {
    //   dataToSubmit.imageUrl = profileImage.ref;
    // }
    form.scrollToField('gender', {});
    // console.log('DATa', dataToSubmit);
    props.sendForm(dataToSubmit);
  };
  const beforeUploadProfileImage = file => {
    // console.log('File', file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'Solo puedes cargar imagenes de tipo JPG/PNG!',
      });
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   notification.error({ message: "Image must smaller than 2MB!" });
    // }
    return isJpgOrPng;
  };
  const loadProfileImage = async file => {
    setProfileImage(prevState => ({
      ...prevState,
      loading: true,
    }));
    if (currentUser.uid) {
      const response = await UserApi.loadImage({
        id: currentUser.uid,
        image: file,
      });
      if (response === 'error') {
        notification.error({
          message: 'No pudimos cargar la imagen',
        });
      } else {
        // handleForm({
        //   name: "imageUrl",
        //   value: await UserApi.getImageFromCollection(response, false)
        // });
        props.sendForm({ imageUrl: response });
      }
    }
    setProfileImage(prevState => ({
      ...prevState,
      loading: false,
    }));
  };
  const uploadButton = (
    <div>
      {profileImage.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Cargando</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      form={form}
      className="profile-form"
      initialValues={initalValues}
      // onFinish={props.handleSubmit}
      onFinish={handleSubmit}
      onFinishFailed={({ errorFields }) => {
        if (errorFields) {
          form.scrollToField(errorFields[0].name[0]);
        }
      }}
    >
      <Row>
        <Col lg={{ span: 8, offset: 2 }} xs={{ span: 24 }} sm={{ span: 24 }}>
          {!props.isRegisterForm && (
            <>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={event => loadProfileImage(event)}
                beforeUpload={file => beforeUploadProfileImage(file)}
                // onChange={event => loadProfileImage(event)}
                customRequest={() => null}
              >
                {profileImage.src ? (
                  <>
                    <img src={profileImage.src} alt="avatar" style={{ width: '100%' }} />
                    <Button type="file">
                      {profileImage.default ? 'Actualizar' : 'Cargar'} imagen
                    </Button>
                  </>
                ) : (
                    uploadButton
                  )}
              </Upload>
            </>
          )}
          <Item
            label={formPlaceholders.name}
            name="name"
            rules={[
              { required: true, message: errorMessages.name.required },
              {
                pattern: rexRules.isAlfa,
                message: errorMessages.name.invalid,
              },
              {
                min: 3,
                message: errorMessages.name.min,
              },
              {
                max: 50,
                message: errorMessages.name.max,
              },
            ]}
            hasFeedback
          >
            <Input
              onChange={event => handleForm({ name: 'name', value: event.target.value })}
              // value={form.getFieldValue("name")}
              placeholder={formPlaceholders.name}
            />
          </Item>
          <Item
            label={formPlaceholders.lastName}
            name="lastName"
            rules={[
              { required: true, message: errorMessages.lastName.required },
              {
                pattern: rexRules.isAlfa,
                message: errorMessages.lastName.invalid,
              },
              {
                min: 3,
                message: errorMessages.lastName.min,
              },
              {
                max: 50,
                message: errorMessages.lastName.max,
              },
            ]}
            hasFeedback
          >
            <Input
              onChange={event =>
                handleForm({
                  name: 'lastName',
                  value: event.target.value,
                })
              }
              // value={form.getFieldValue("lastName")}
              placeholder={formPlaceholders.lastName}
            />
          </Item>
          <Item
            label={formPlaceholders.email}
            name="email"
            rules={[
              { required: true, message: errorMessages.email.required },
              {
                pattern: rexRules.isEmail,
                message: errorMessages.email.invalid,
              },
            ]}
            hasFeedback
          >
            <Input
              onChange={event => handleForm({ name: 'email', value: event.target.value })}
              // value={form.getFieldValue("email")}
              placeholder={formPlaceholders.email}
            />
          </Item>
          <Item
            label={formPlaceholders.license}
            name="license"
            rules={[
              { required: true, message: errorMessages.doctorId.required },
              {
                pattern: rexRules.isNumeric,
                message: errorMessages.doctorId.isNumber,
              },
              {
                min: 3,
                message: errorMessages.doctorId.min,
              },
              {
                max: 50,
                message: errorMessages.doctorId.max,
              },
            ]}
            hasFeedback
            extra={formPlaceholders.licenseSubTitle}
          >
            <Input
              autoFocus={props.isRegisterForm}
              // onChange={(event) =>
              //   handleForm({
              //     name: "license",
              //     value: event.target.value,
              //   })
              // }
              // value={form.getFieldValue("license")}
              placeholder={formPlaceholders.license}
            />
          </Item>
          <Item
            label={formPlaceholders.phoneNumber}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: errorMessages.phoneNumber.required,
              },
              {
                pattern: rexRules.isNumeric,
                message: errorMessages.phoneNumber.isNumber,
              },
              {
                len: 10,
                message: errorMessages.phoneNumber.isTenNumbers,
              },
            ]}
            hasFeedback
          >
            <Input
              addonBefore="+52"
              maxLength={10}
              onChange={event =>
                handleForm({
                  name: 'phoneNumber',
                  value: event.target.value,
                })
              }
              // value={form.getFieldValue("phoneNumber")}
              placeholder={formPlaceholders.phoneNumber}
            />
          </Item>
          <Item
            label={
              <>
                <span>{formPlaceholders.officePhoneNumber}</span>
                <sup>
                  <i>Opcional</i>
                </sup>
              </>
            }
            name="officePhoneNumber"
            rules={[
              {
                len: 10,
                message: errorMessages.officePhoneNumber.isTenNumbers,
              },
              {
                pattern: rexRules.numbers,
                message: errorMessages.phoneNumber.isNumber,
              },
            ]}
            hasFeedback
          >
            <Input
              maxLength={10}
              onChange={event =>
                handleForm({
                  name: 'officePhoneNumber',
                  value: event.target.value,
                })
              }
              // value={form.getFieldValue("officePhoneNumber")}
              placeholder={formPlaceholders.officePhoneNumber}
            />
          </Item>
          <Item
            label={
              <>
                <span>{formPlaceholders.officeAddress}</span>
                <sup>
                  <i>Opcional</i>
                </sup>
              </>
            }
            name="officeAddress"
            rules={[
              // {
              //   pattern: rexRules.isAddress,
              //   messagge: errorMessages.officeAddress.invalid
              // },
              {
                max: 100,
                message: errorMessages.officeAddress.max,
              },
            ]}
            hasFeedback
          >
            <GoogleAddress formAddress={activeAddress} handleAddress={setActiveAddress} />
          </Item>
        </Col>
        <Col lg={{ span: 8, offset: 4 }} xs={{ span: 24 }} sm={{ span: 24 }}>
          <Item
            rules={[
              {
                required: true,
                message: errorMessages.specialty.required,
              },
            ]}
            name="specialty"
            label={formPlaceholders.specialty}
            hasFeedback
          >
            {medicalSpecialties.data && (
              <Select
                showSearch
                // value={form.getFieldValue("specialty")}
                loading={medicalSpecialties.size <= 0}
                onSelect={value => {
                  // console.log('Value2', value);
                  handleForm({ name: 'specialty', value });
                }}
                onClick={() => {
                  // console.log('Click');
                  setMedicalSelectIsOpen(!medialSelectIsOpen);
                }}
                optionFilterProp="children"
                open={medialSelectIsOpen}
                filterOption={(input, option) => {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                dropdownRender={content => (
                  <>
                    {content}
                    <Divider onClick={e => e.stopPropagation()} style={{ margin: '4px 0' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: 'auto' }}
                        placeholder="Otra"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        // value={}
                        onChange={event =>
                          handleForm({
                            name: 'specialty',
                            value: event.target.value,
                          })
                        }
                      />
                      <Button
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setMedicalSpecialties(prevState => ({
                            ...prevState,
                            data: [
                              ...prevState.data,
                              {
                                name: form.getFieldValue('specialty'),
                                type: 'custom',
                                id: form.getFieldValue('specialty'),
                              },
                            ],
                          }));
                          setMedicalSelectIsOpen(!medialSelectIsOpen);
                        }}
                      >
                        <PlusOutlined /> Agregar
                      </Button>
                    </div>
                  </>
                )}
              >
                {medicalSpecialties.data.map(specialty => (
                  <Option key={specialty.id} value={specialty.id}>
                    {specialty.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item
            label={
              <>
                <span>{formPlaceholders.subSpecialty}</span>
                <sup>
                  <i>Opcional</i>
                </sup>
              </>
            }
            name="subSpecialty"
            hasFeedback
          >
            <Input
              onChange={event =>
                handleForm({
                  name: 'subSpecialty',
                  value: event.target.value,
                })
              }
              // value={form.getFieldValue("subSpecialty")}
              placeholder={formPlaceholders.subSpecialty}
            />
          </Item>
          <Group compact label={formPlaceholders.birthday} className="inputGroup">
            <label htmlFor="birthDay">{formPlaceholders.birthday}</label>
            <Item
              name="birth-day"
              rules={[
                {
                  required: true,
                  message: errorMessages.birthday.day.required,
                },
              ]}
            // hasFeedback
            >
              <Select
                showSearch
                // defaultValue="01"
                onChange={value => handleForm({ name: 'day', value })}
              >
                {Array.from({ length: 31 }, (value, key) => key + 1).map((day, index) => (
                  <Option key={day} value={`${day}`}>
                    {index < 9 ? `0${day}` : `${day}`}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="birth-month"
              rules={[
                {
                  required: true,
                  message: errorMessages.birthday.month.required,
                },
              ]}
            >
              <Select
                showSearch
                style={{ minWidth: isWide ? '150px' : '118px' }}
                // defaultValue={months[0].label}
                onChange={value => handleForm({ name: 'month', value })}
              >
                {months.map(month => (
                  <Option key={month.label} value={month.value}>
                    {!smallMonthInput && month.label.length > 5
                      ? `${month.label.substr(0, 4)}...`
                      : month.label}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="birth-year"
              rules={[
                {
                  required: true,
                  message: errorMessages.birthday.year.required,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                maxLength={4}
                style={{ width: !smallYearInput && '70px' }}
                onChange={value => handleForm({ name: 'year', value })}
              />
            </Item>
          </Group>
          <Item
            rules={[
              {
                required: true,
                message: errorMessages.university.required,
              },
            ]}
            label={formPlaceholders.university}
            name="university"
            hasFeedback
          >
            {universities.data && (
              <Select
                showSearch
                loading={universities.size <= 0}
                // value={form.getFieldValue("university")}
                onChange={value => handleForm({ name: 'university', value })}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {universities.data.map(university => (
                  <Option key={university.id} value={university.id}>
                    {university.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item
            rules={[
              {
                required: true,
                message: errorMessages.university.required,
              },
            ]}
            label={formPlaceholders.specialtyUniversity}
            name="specialtyUniversity"
            hasFeedback
          >
            {universities.data && (
              <Select
                showSearch
                loading={universities.size <= 0}
                // value={form.getFieldValue("university")}
                onChange={value => handleForm({ name: 'university', value })}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {universities.data.map(university => (
                  <Option key={university.id} value={university.id}>
                    {university.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item name="gender" label={formPlaceholders.gender}>
            <Radio.Group
              onChange={value => handleForm({ name: 'gender', value: value.target.value })}
              // defaultValue={genders[0].value}
              buttonStyle="solid"
            >
              {genders.map(gender => (
                <Radio.Button key={gender.name} value={gender.value}>
                  {gender.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Item>
          {props.isRegisterForm && (
            <>
              <Item
                rules={[
                  {
                    required: true,
                    message: errorMessages.password.required,
                  },
                ]}
                label={formPlaceholders.password}
                name="password"
                hasFeedback
              >
                <Password
                  onChange={event =>
                    handleForm({
                      name: 'password',
                      value: event.target.value,
                    })
                  }
                  placeholder={formPlaceholders.password}
                />
              </Item>
              <Item
                label={formPlaceholders.confirmPassword}
                name="confirmPassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: errorMessages.confirmPassword.required,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(errorMessages.confirmPassword.asPassword));
                    },
                  }),
                ]}
              >
                <Password
                  onChange={event =>
                    handleForm({
                      name: 'confirmPassword',
                      value: event.target.value,
                    })
                  }
                  placeholder={formPlaceholders.confirmPassword}
                />
              </Item>
            </>
          )}
        </Col>
      </Row>

      <Item>
        {props.isLoading ? (
          <Spin />
        ) : (
            <Button size="large" type="primary" htmlType="submit">
              {props.isRegisterForm ? 'Registrarse' : 'Actualizar datos'}
            </Button>
          )}
      </Item>
    </Form>
  );
};

export default ProfileForm;
