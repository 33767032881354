import DownloadApp from '../../../assets/img/download-app.svg';
import UserSearch from '../../../assets/img/user-search.svg';
import MakingCall from '../../../assets/img/making-call.svg';

export const textFunctionality = {
  first: {
    number: '1',
    title: ' Tu paciente descarga la app',
    description: 'Tu paciente descarga la app gratuitamente y se registra.',
    image: DownloadApp,
  },
  second: {
    number: '2',
    title: ' Tu paciente te busca',
    description:
      ' Busca por nombre del médico o cédula profesional para ponerse en contacto contigo en caso de una duda/urgencia.',
    image: UserSearch,
  },
  third: {
    number: '3',
    title: '  Recibe la llamada o mensaje de tu paciente',
    description:
      'Al mandarte mensaje o llamarte tú numero nunca aparece , y al terminar la interacción  se genera un cobro (si tú lo decides) el cual te será transferido.',
    image: MakingCall,
  },
};

export const titleFunctionality = 'Aparece en la app y genera ingresos:';

export const subtitleFunctionality = 'Una vez que estés registrado';
