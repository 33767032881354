import React from 'react';
import { Col, Row, Typography } from 'antd';
import Doctors from '../../../assets/img/doctors.svg';
import './styles.scss';
import { textFunctionality } from './constants';

const { Title, Paragraph } = Typography;

const Description = () => {
  return (
    <section className="landing-description">
      <Row justify="space-around" align="middle">
        <Col xs={24} sm={24} md={18} lg={8} xl={8}>
          <img src={Doctors} alt="appointment" className="functionality-image" />
        </Col>
        <Col xs={18} sm={18} md={18} lg={8} xl={8} className="functionality-text-columns">
          <Title level={2} className="functionality-title">
            {textFunctionality.title}
          </Title>
          <Paragraph className="text">
            MiDoc es la herramienta de comunicación entre pacientes y sus propios doctores. Genera
            ingresos por las llamadas y mensajes que recibas.
            <span className="text-highlight">
              No mostramos tu numero personal en ningún momento
            </span>
            y tu decides si cobrar o no
          </Paragraph>
        </Col>
      </Row>
    </section>
  );
};

export default Description;
