export const schedule = [
  {
    key: 'first-section',
    hours: {
      start: '8:00',
      end: '14:00',
    },
    monday: 45,
    tuesday: 45,
    wednesday: 45,
    thursday: 45,
    friday: 45,
    saturday: 45,
    sunday: 45,
  },
  {
    key: 'second-section',
    hours: {
      start: '14:00',
      end: '20:00',
    },
    monday: 45,
    tuesday: 45,
    wednesday: 45,
    thursday: 45,
    friday: 45,
    saturday: 45,
    sunday: 45,
  },
  {
    key: 'third-section',
    hours: {
      start: '20:00',
      end: '8:00',
      isLast: true,
    },
    monday: 45,
    tuesday: 45,
    wednesday: 45,
    thursday: 45,
    friday: 45,
    saturday: 45,
    sunday: 45,
  },
];
export const columns = [
  {
    title: 'Horario',
    dataIndex: 'hours',
    key: 'hours',
  },
  {
    title: 'Lunes',
    dataIndex: 'monday',
    key: 'monday',
  },
  {
    title: 'Martes',
    dataIndex: 'tuesday',
    key: 'tuesday',
  },
  {
    title: 'Miercoles',
    dataIndex: 'wednesday',
    key: 'wednesday',
  },
  {
    title: 'Jueves',
    dataIndex: 'thursday',
    key: 'thursday',
  },
  {
    title: 'Viernes',
    dataIndex: 'firday',
    key: 'friday',
  },
  {
    title: 'Sabado',
    dataIndex: 'saturday',
    key: 'saturday',
  },
  {
    title: 'Domingo',
    dataIndex: 'sunday',
    key: 'sunday',
  },
];

export const values = [
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105,
  110,
  115,
  120,
  125,
  130,
  135,
  140,
  145,
  150,
  200,
  250,
  300,
  350,
];

export const title = 'Horarios';
