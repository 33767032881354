import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import FAQ from '../../components/FAQ';
import Profile from './Profile';
import History from './History';
import BankAccount from './BankAccount';
import GovermentData from './GovermentData';
import Schedule from './Schedule';

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/history`} />
      </Route>
      <Route path={`${path}/profile`}>
        <Profile />
      </Route>
      <Route path={`${path}/bank-account`}>
        <BankAccount />
      </Route>
      <Route path={`${path}/goverment-data`}>
        <GovermentData />
      </Route>
      <Route path={`${path}/schedule`}>
        <Schedule />
      </Route>
      <Route path={`${path}/history`}>
        <History />
      </Route>
      <Route path={`${path}/faq`}>
        <FAQ />
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;
