import React from 'react';

import { Col, Collapse, Row, Typography } from 'antd';

import { FAQtext, IQuestionText, title } from './constants';
import './styles.scss';

const { Panel } = Collapse;
const { Title } = Typography;

const FAQ = () => {
  return (
    <section className="landing-faq">
      <Row>
        <Title level={2}>{title}</Title>
        <Col xs={24} lg={24}>
          <Collapse defaultActiveKey={['1']} bordered={false}>
            {FAQtext.map(item => (
              <Panel header={item.title} key={item.key} className="site-collapse">
                <p dangerouslySetInnerHTML={{ __html: item.text }} />
                {item.numbers && (
                  <ol>
                    {item.numbers.map(subPart => (
                      <li key={subPart.title}>{subPart.title}</li>
                    ))}
                  </ol>
                )}
                {item.dots && (
                  <ul>
                    {item.dots.map(dot => (
                      <li key={dot.title}>{dot.title}</li>
                    ))}
                  </ul>
                )}
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </section>
  );
};

export default FAQ;
