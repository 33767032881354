import React from 'react';
import { Col, Typography } from 'antd';
import './style.scss';

const { Title } = Typography;

const TeamCards = props => (
  <Col xs={4} sm={4} offset={1} md={4} lg={3} xl={3} xxl={3} className="team-cards">
    <img src={props.img} alt="Team Work" className="team-image" />
    <Title level={4} className="name">
      {props.name}
    </Title>
    <p className="career">{props.career}</p>
  </Col>
);

export default TeamCards;
