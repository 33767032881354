import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Input } from 'antd';

import './style.scss';

const GoogleAddress = ({ formAddress, handleAddress }) => {
  const handleSelect = addressHandle => {
    geocodeByAddress(addressHandle).then(results => {
      handleAddress(results[0].formatted_address);
    });
    // .catch(error => ());
  };
  return (
    <PlacesAutocomplete
      value={formAddress}
      onChange={value => {
        handleAddress(value);
      }}
      onSelect={addressHandle => handleSelect(addressHandle)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="google-search-place">
          <Input
            // eslint-disable-next-line
            {...getInputProps({
              placeholder: 'Busque la dirección ...',
              className: 'location-search-input',
            })}
          />
          {/* {console.log(suggestions, suggestions.length)} */}
          {suggestions && suggestions.length > 0 && (
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleAddress;
