export const title = 'Ten control de tus precios y datos personales';

export const maintext = [
  {
    text: 'En el panel de control podrás ver los',
  },
  {
    text:
      ' precios por llamada (por día y franja horaria), de tus mensajes y dar de alta tus datos bancarios para depositarte ',
    bold: true,
  },
  // {
  //   text: 'dar de alta tus ',
  // },
  // {
  //   text: 'datos bancarios ',
  //   bold: true,
  // },
  // {
  //   text: 'para depositarte tus ganancias, datos fiscales, ver los ',
  // },
  // {
  //   text: ' datos de perfil ',
  // },
  // {
  //   text: 'y el ',
  // },
  // {
  //   text: 'historial de llamadas ',
  //   bold: true,
  // },
  { text: 'tus ganancias. También datos fiscales y de perfil.' },
];
