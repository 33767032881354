import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Button, notification, Spin } from 'antd';

import { SignUp as SignUpAPI, Auth as AuthApi } from '../../api';
import { AuthContext } from '../../contexts/Auth';
import Logo from '../../components/Logo';
import { GAEvent } from '../../utils/analytics';
import LastStep from '../../components/EmailComponents/LastStep';
import './style.scss';

const EmailHandler = () => {
  // const { Title } = Typography;
  const [type, setType] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { currentUser, setVerificationEmailStatus } = useContext(AuthContext);

  useEffect(() => {
    // Confirm Email Function
    const confirmEmail = async ({ uid, token }) => {
      const response = await SignUpAPI.verifyUser({ uid, token });
      // console.log("resposne", response);
      if (response === true) {
        GAEvent({
          category: 'web_user',
          action: 'SignUp Complete',
          label: 'SignUp Complete',
        });
        if (currentUser) {
          notification.success({
            message: 'Correo confirmado, puedes ingresar a la plataforma',
          });
          setVerificationEmailStatus(true);
          history.push('/dashboard');
        }
        if (currentUser === false) {
          notification.success({
            message: 'Correo confirmado, puedes loguearte para ingresar a la plataforma',
          });
          history.push('/');
        }
      } else {
        notification.error({
          message: 'Token invalido',
        });
        history.push('/');
      }
    };
    // Effect
    if (currentUser !== null) {
      if (location) {
        const params = queryString.parse(location.search);
        setType(params.type);
        if (params.type === 'verify' && params.token) {
          // console.log("asdasd", params);
          confirmEmail({ uid: params.code, token: params.token });
        }
      }
    }
  }, [currentUser, location, history]);

  const logOut = async () => {
    const response = await AuthApi.logOut();
    // console.log("response");
    if (response) {
      history.push('/');
    }
  };
  return (
    <div className="emailhandler-page">
      <Button className="logout-button" onClick={() => logOut()}>
        LogOut
      </Button>
      <Logo />
      <h1>Procesando tu cuenta</h1>
      {type !== 'waitingForEmailVerification' && <Spin />}
      {type === 'waitingForEmailVerification' && <LastStep noSendEmail />}
    </div>
  );
};

export default EmailHandler;
