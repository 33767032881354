import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'antd';

import Logo from '../Logo';
import './style.scss';

const Footer = () => (
  <footer>
    <Row className="content">
      <Logo />
    </Row>
    <Row className="bottom">
      <span>MiDoc © 2020 </span>|
      <b>
        <Link to="/terms"> Terminos y condiciones</Link>|
        <Link to="/privacy">Aviso de privacidad</Link>
      </b>
    </Row>
  </footer>
);

export default Footer;