import React, { useEffect, useContext, useState } from 'react';
import { Table, Typography, Tooltip } from 'antd';
import useMedia from 'use-media';
import moment from 'moment';
import numeral from 'numeral';
import 'moment/locale/es';

import { History as historyApi } from '../../../api';
import { AuthContext } from '../../../contexts/Auth';
import { AppContext } from '../../../contexts/App';
import { title, toolTipText } from './constants';
import { GANewPage } from '../../../utils/analytics';

const History = () => {
  const { Title } = Typography;
  const { currentUser } = useContext(AuthContext);
  const { setAppLoading } = useContext(AppContext);
  const showScroll = useMedia({ minWidth: '900px' });
  const tableColumns = [
    {
      title: 'Nombre de paciente',
      dataIndex: 'patientFullName',
      key: 'patientFullName',
    },
    {
      title: 'Día de llamada',
      dataIndex: 'timestamps',
      key: 'timestamps',
      render: timestamps => (
        <>
          {timestamps && timestamps.created && timestamps.created.seconds && (
            <span>
              {moment
                .unix(timestamps.created.seconds)
                .locale('es')
                .format('D/M/YYYY, h:mm a')}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Duración',
      dataIndex: 'duration',
      key: 'duration',
      render: duration => (
        <span>{duration !== null ? `${duration} min` : 'no respondido (0 min)'}</span>
      ),
    },
    {
      title: 'Ingreso generado',
      dataIndex: 'chargeAmount',
      key: 'chargeAmount',
      render: chargeAmount => (
        <>
          {chargeAmount === 0 ? (
            <span>
              {numeral((chargeAmount / 100) * 80)
                .divide(100)
                .format('$0,0.00')}
            </span>
          ) : (
            <Tooltip placement="right" title={toolTipText}>
              <span>
                {numeral((chargeAmount / 100) * 80)
                  .divide(100)
                  .format('$0,0.00')}
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const [historyData, setHistoryData] = useState({
    size: 0,
    data: [],
  });
  useEffect(() => {
    GANewPage();
  }, []);
  useEffect(() => {
    if (currentUser) {
      const getHistoryData = async doctorId => {
        setAppLoading(true);
        let response = await historyApi.getDoctorHistory(doctorId);
        // console.log('resposne', response);
        if (response && response.size) {
          response = {
            size: response.size,
            data: response.data.map(item => ({
              ...item,
              chargeAmount: item.status === 'completed' ? item.chargeAmount : 0,
              key: item.id,
            })),
          };

          setHistoryData(response);
        }
        setAppLoading(false);
      };
      getHistoryData(currentUser.uid);
    }
  }, [currentUser, setAppLoading]);

  return (
    <div>
      <Title>{title}</Title>
      {historyData.size > 0 ? (
        <Table
          scroll={!showScroll && { x: 900 }}
          columns={tableColumns}
          dataSource={historyData.data}
          pagination={false}
        />
      ) : (
        <Title level={2}>No tienes historial</Title>
      )}
    </div>
  );
};

export default History;
