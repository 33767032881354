export const errorMessages = {
  email: {
    required: 'Debes ingresar el correo',
  },
  password: {
    required: 'Debes ingresar la contraseña',
  },
};
export const placeholders = {
  email: 'Correo electronico',
  password: 'Contraseña',
};

export const modalTitle = 'Login';
