import React, { useEffect, useContext, useState } from 'react';
import { Typography, Spin, Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../contexts/Auth';
import { SignUp as SignUpAPI } from '../../../api';
import {
  lastStepText,
  sendEmailAgainText,
  sendEmailErrorText,
  checkSpamMessage,
} from './constants';
import './style.scss';

const LastStep = props => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [isSendAgianButtonActive, setIsSendAgianButtonActive] = useState(false);
  const { Title } = Typography;

  const sendEmail = async ({ uid, email }, fromLink = false) => {
    try {
      if (currentUser || (uid && email)) {
        const response = await SignUpAPI.sendEmailVerification({
          email: email || currentUser.email,
          uid: uid || currentUser.uid,
        });
        if (response !== true) {
          throw new Error('Error sending email');
        }
        if (fromLink) {
          notification.success({
            message: 'Correo enviado',
          });
        }
      }
    } catch (e) {
      notification.error({
        message: sendEmailErrorText,
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.email && props.uid) {
      sendEmail({ uid: props.uid, email: props.email });
    }
  }, []);

  // If user is verified -> push to dashboard
  useEffect(() => {
    if (currentUser) {
      // console.log("Current user12", currentUser);
      if (currentUser.emailVerified) {
        history.push('/dashboard');
      } else {
        setTimeout(() => setIsSendAgianButtonActive(true), 60000);
      }
    }
  }, [currentUser, history]);
  return (
    <div className="email-last-step">
      <Title level={4}>{lastStepText}</Title>
      <small>
        <b>{checkSpamMessage}</b>
      </small>
      <Spin size="large" />
      {isSendAgianButtonActive && (
        <Button onClick={() => sendEmail({ uid: false, email: false }, true)} type="link">
          {sendEmailAgainText}
        </Button>
      )}
    </div>
  );
};

export default LastStep;
