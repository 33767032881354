import React from 'react';
import { Row, Typography } from 'antd';
import { titleFunctionality, textFunctionality, subtitleFunctionality } from './constants';
import './styles.scss';
import { FunctionalityCard } from './FunctionalityCard';

const { Title } = Typography;

export const Functionality = () => {
  return (
    <section className="functionality-container">
      <Title level={2} className="title">
        {titleFunctionality}
      </Title>
      <Title level={4} className="title">
        {subtitleFunctionality}
      </Title>
      <Row justify="space-around" align="center">
        {Object.values(textFunctionality).map(item => {
          return (
            <FunctionalityCard
              key={item.number}
              number={item.number}
              description={item.description}
              title={item.title}
              image={item.image}
            />
          );
        })}
      </Row>
    </section>
  );
};
