import React, { useState, useEffect } from 'react';
import { Button, Input, Form, notification, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'use-media';

import { errorMessages, placeholders, modalTitle } from './constants';
import { Auth } from '../../../api';
import './style.scss';

const LoginComponent = () => {
  const [form] = Form.useForm();
  const isWide = useMedia({ minWidth: '1500px' });
  const [loading, setLoading] = useState(false);
  const [, forceUpdate] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async values => {
    setLoading(true);
    const response = await Auth.login({ ...values });
    setLoading(false);
    if (response.code) {
      notification.error({
        message: response.message,
      });
    } else {
      history.push('/dashboard');
    }
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const LoginForm = (
    <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
      <Form.Item name="email" rules={[{ required: true, message: errorMessages.email.required }]}>
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder={placeholders.email}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: errorMessages.password.required }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={placeholders.password}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit">
            Entrar
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  const ShortLoginForm = (
    <div>
      <Button onClick={() => toggleModal()} type="primary" size="large">
        Log in
      </Button>
      <Modal
        forceRender
        title={modalTitle}
        visible={isModalOpen}
        onCancel={toggleModal}
        footer={null}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: errorMessages.email.required }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={placeholders.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: errorMessages.password.required }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={placeholders.password}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );

  return <div className="navbar-login">{isWide ? LoginForm : ShortLoginForm}</div>;
};

export default LoginComponent;
