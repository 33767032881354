export const collectionNames = {
  medicalSpecialties: 'medical_specialties',
  uni: 'universities',
  banks: {
    title: 'banks',
  },
  doctors: 'doctors',
  calls: {
    title: 'calls',
    fields: {
      doctorId: 'doctorId',
    },
  },
};

export const status = {
  active: 'active',
};
