import React, { useEffect, useState, useContext } from 'react';

import { analytics, app } from '../../config/firebase';
import { AppContext } from '../App';
import { Auth as AuthAPI } from '../../api';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const { setAppLoading } = useContext(AppContext);

  useEffect(() => {
    setAppLoading(true);
    app.auth().onAuthStateChanged(user => {
      if (user) {
        updateTimestamp(user);
        setCurrentUser(user);
        // console.log("current", user);
        if (analytics) analytics.setUserId(user.uid);
      } else {
        setCurrentUser(false);
      }
      setAppLoading(false);
    });
  }, [setAppLoading]);

  const updateTimestamp = async user => {
    const { uid } = user;

    const updateTimestampResult = await AuthAPI.updateTimestamps({ uid });
    // console.log('Id', updateTimestampResult);
    // console.log(
    //   "USERR",
    //   user.getIdTokenResult().then(claims => {
    //     console.log("Claims", claims);
    //   })
    // );
  };

  const setVerificationEmailStatus = status => {
    setCurrentUser(prevState => ({ ...prevState, emailVerified: status }));
  };
  const setFullName = ({ name }) => {
    setCurrentUser(prevState => ({ ...prevState, displayName: name }));
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setVerificationEmailStatus,
        setFullName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
