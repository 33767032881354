import React, { useContext, useEffect, useState } from 'react';
import { Typography, notification } from 'antd';

import ProfileForm from '../../../components/ProfileForm';
import { title } from './constants';
import { AuthContext } from '../../../contexts/Auth';
import { User as UserApi } from '../../../api';
import { GANewPage } from '../../../utils/analytics';
import './style.scss';

const Profile = () => {
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, setFullName } = useContext(AuthContext);
  useEffect(() => {
    GANewPage();
  }, []);

  const handleSubmit = async values => {
    if (currentUser) {
      setIsLoading(true);
      const response = await UserApi.updateUserData({
        id: currentUser.uid,
        data: values,
      });
      const { fullName } = values;
      if (fullName) {
        await UserApi.changeUserNameInAuthAccount({
          fullName,
        });
      }
      if (response === true) {
        setFullName({ name: fullName });
        notification.success({
          message: 'Tus datos fueron actualizados',
        });
      } else {
        notification.error({
          message: 'Error con actualizacion',
        });
      }
      // console.log("response", response);
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-profile">
      <Title>{title}</Title>
      <section>
        <ProfileForm
          forceRender
          isLoading={isLoading}
          sendForm={handleSubmit}
          isRegisterForm={false}
        />
      </section>
    </div>
  );
};
export default Profile;
