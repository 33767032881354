import React from 'react';
import { Typography } from 'antd';

import './style.scss';

const TermsComponent = () => {
  const { Title, Paragraph } = Typography;
  return (
    <section className="terms-section">
      <Title className="title" level={1}>
        TÉRMINOS Y CONDICIONES <i>Del doctor</i>
      </Title>
      <Title className="title" level={2}>
        Términos de uso
      </Title>
      <Paragraph className="paragraph">
        Los presentes Términos y Condiciones rigen la relación entre usted (en adelante referido
        como “Usuario”) y Mi Doc Technologies S.A.P.I. de C.V. (en adelante referido como “MiDoc”)
        con relación a los servicios y productos disponibles al Usuario a través del sitio web
        <a href="https://www.midocapp.com"> www.midocapp.com </a>
        (en adelante referido como “Página Web”) y la app móvil (en adelante referido como
        “Aplicación Móvil”) ; a su vez, los presentes términos y condiciones regulan el uso de la
        Página Web, del que MiDoc, con razón social MiDoc Technologies S.A.P.I. de C.V, es
        propietario, la cual es una Sociedad Anónima Promotora de Inversión, con domicilio fiscal en
        Puebla 403, Col. Roma Norte, Delegación Cuauhtémoc, Ciudad de México, C.P. 06700.
      </Paragraph>
      <Title className="title" level={2}>
        Aceptación de los términos y condiciones
      </Title>
      <Paragraph className="paragraph">
        Al ingresar y utilizar esta Página Web el Usuario está aceptando los Términos y Condiciones
        de uso contenidos y declara expresamente su aceptación utilizando para tal efecto medios
        electrónicos.
      </Paragraph>
      <Paragraph className="paragraph">
        En caso de no aceptar en forma absoluta y completa los Términos y Condiciones de este
        contrato, el Usuario deberá abstenerse de utilizar, observar y acceder a la Página Web.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc no guardará una copia individualizada del presente convenio celebrado entre el Usuario
        y MiDoc, por lo que se le recomienda al Usuario que guarde una copia de los presentes
        Términos y Condiciones para su propio expediente.
      </Paragraph>
      <Paragraph className="paragraph">
        En caso de que el Usuario viole lo expresado en estos Términos y Condiciones de Uso, MiDoc
        podrá cancelar su uso, así como excluir al Usuario de futuras operaciones, y/o tomar la
        acción legal que juzgue conveniente para sus intereses.
      </Paragraph>
      <Title className="title" level={2}>
        Objeto
      </Title>
      <Paragraph className="paragraph">
        En la Página Web el Usuario tendrá la posibilidad de registrar, actualizar datos, así como
        ver historial de llamadas recibidas, datos fiscales , datos bancarios y panel de control de
        horarios y días.
      </Paragraph>
      <Title className="title" level={2}>
        Uso de la página web
      </Title>
      <Paragraph className="paragraph">
        MiDoc reconoce ser la única propietaria legal de los derechos de propiedad intelectual, ya
        sean registrados o no registrados, incluyendo: nombres, logos, imágenes, videos, marcas de
        servicio, nombre de dominio, proyectos, software, textos y datos incluidos en la Página Web.
        El contenido mostrado por escrito en esta Página Web pertenece al autor y no puede
        reproducirse total o parcialmente sin el consentimiento expreso por escrito del mismo.
      </Paragraph>
      <Paragraph className="paragraph">
        Ciertos servicios y las características relacionadas que pueden estar disponibles en la
        Página Web pueden requerir el registro o suscripción. El Usuario reconoce que, al
        proporcionar la información de carácter personal, otorga a MiDoc la autorización señalada en
        el artículo 109 de la Ley Federal del Derecho de Autor. Si el Usuario decide registrarse o
        suscribirse a cualquiera de estos servicios o funciones relacionadas, el mismo se compromete
        a proporcionar información precisa y actualizada acerca de sí mismo, y a actualizar
        rápidamente esa información si hay algún cambio.
      </Paragraph>
      <Paragraph className="paragraph">
        Durante el proceso de registro, aceptas recibir correos electrónicos promocionales de forma
        esporádica de la Página Web, no obstante, posteriormente, puedes optar por no recibir tales
        correos promocionales haciendo clic en el enlace, en la parte inferior de cualquier correo
        electrónico promocional.
      </Paragraph>
      <Paragraph className="paragraph">
        Al registrarse en la página el Usuario acepta que sus datos se visualicen en las
        aplicaciones iOS y Android de MiDoc, para que pueda ser encontrado y contactado por medio de
        las aplicaciones móviles.
      </Paragraph>
      <Title className="title" level={2}>
        Cuenta del usuario
      </Title>
      <Paragraph className="paragraph">
        El Usuario podrá contar con una cuenta personal ingresando los datos solicitados en la
        Página Web. La Cuenta del Usuario es personal, única e intransferible. A través de la Cuenta
        del Usuario el Usuario podrá acceder a su historial de compras y pedidos realizados,
        verificar el estado de sus pedidos, acceder a su carrito de compra, tener acceso a
        promociones y descuentos que de tiempo en tiempo informe MiDoc, podrá personalizar el
        contenido que se le presente de la Página Web de acuerdo con sus preferencias, así como
        utilizar cualquier tipo de funcionalidades que MiDoc pueda aplicar en el futuro a la Cuenta
        del Usuario.
      </Paragraph>
      <Paragraph className="paragraph">
        Cada Usuario del sitio es el único responsable de mantener las contraseñas y otros
        identificadores de cuentas seguras. El titular de la cuenta es totalmente responsable de
        todas las actividades que ocurran bajo su contraseña o cuenta. Por otra parte, el Usuario
        debe notificar la Empresa de cualquier uso no autorizado de su contraseña o cuenta. De
        ninguna manera, MiDoc será responsable, directo o indirectamente, por cualquier pérdida o
        daño de cualquier tipo incurridos como resultado de la falta de cumplimiento con esta
        sección por parte del Usuario.
      </Paragraph>
      <Title className="title" level={2}>
        Protección de datos
      </Title>
      <Paragraph className="paragraph">
        Los datos recabados por MiDoc del Usuario durante el uso de la Página Web serán tratados por
        MiDoc conforme al Aviso de Privacidad que se incorpora por referencia al documento presente.
      </Paragraph>
      <Paragraph className="paragraph">
        La Página Web cuenta con el cumplimiento a la ley de protección de datos personales en
        posesión de terceros.
      </Paragraph>
      <Title className="title" level={2}>
        Servicio
      </Title>
      <Paragraph className="paragraph">
        MiDoc actúa como un prestador de servicio para el Usuario, para que éste pueda ser
        contactado por medio de aplicaciones móviles, desarrolladas también por MiDoc, y el Usuario
        pueda cobrar por ese contacto. MiDoc al prestar este servicio hace un cobro del 20% al
        Usuario por cada interacción telefónica o mensaje.
      </Paragraph>
      <Paragraph className="paragraph">
        Este servicio que se cobra incluye:
        <ul>
          <li>Uso de software de MiDoc</li>
          <li>Recolección y transferencia de pagos</li>
          <li>Comisión de tarjeta de crédito</li>
        </ul>
      </Paragraph>
      <Title className="title" level={2}>
        Tarifas
      </Title>
      <Paragraph className="paragraph">
        Las tarifas pueden variar según el Usuario las decida, colocando un mínimo de $45 pesos MXN
        y un máximo de $150 pesos MXN. MiDoc coloca una tarifa pre-establecida de $45 pesos MXN la
        cual puede ser modificada por el Usuario en cualquier momento.
      </Paragraph>
      <Title className="title" level={2}>
        MiDoc no proporciona servicios ni consejos de atención médica
      </Title>
      <Paragraph className="paragraph">
        La Página Web, Aplicación Móvil y los Servicios de MiDoc son sólo una tecnología de atención
        virtual de los servicios que prestan los Uusarios a los Pacientes. Los Usuarios deben tener
        un acuerdo por separado y una relaciónusuario-paciente con el Paciente, y MiDoc no es parte
        de dicho acuerdo o relación usuario-paciente. Los Usuarios son los únicos responsables de
        mantener confidencial toda la información proporcionada por los Usuarios.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc no tiene control o inherencia en los servicios ofrecidos por parte de los Usuarios,
        por ello no será responsable respecto de la existencia, calidad o legitimidad de los
        servicios ofrecidos por los Usuario, así como de la capacidad para contratar de los
        Pacientes o de la veracidad de la información ingresada por los Usuario o los Pacientes. Los
        Usuarios y los Pacientes conocen y aceptan ser los únicos responsables por los servicios de
        asistencia médica ofrecidos dentro de la Pagina Web y la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        En este sentido, los Usuarios que brindan servicios a los Pacientes a través de MiDoc
        debieran ser profesionales independientes que son los únicos responsables de los servicios
        que cada uno brinda. Los Usuarios no son empleados de MiDoc y MiDoc no practica la medicina
        o alguna otra profesión que requiera licencia.
      </Paragraph>
      <Paragraph className="paragraph">
        Asimismo, MiDoc no interfiere con la práctica de la medicina o cualquier otra profesión que
        requiera licencia. Cada uno de los Usuarios es responsable de sus servicios y del
        cumplimiento de los requisitos aplicables a su profesión y licencia.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc cuenta con mínimos estándares para verificar que los Usuarios tienen licencia y
        cumplen con los requisitos aplicables para ejercer su profesión, ya que esto depende de las
        declaraciones y la información que el Usuario ingresa en la Aplicación Móvil o el Sitio, y
        por tanto, MiDoc no garantiza que su licencia se encuentre vigente o que los Usuario cumplen
        con los requisitos para ejercer su profesión.
      </Paragraph>
      <Paragraph className="paragraph">
        El Sitio y la Aplicación Móvil están diseñados únicamente para compartir información entre
        los Pacientes y los Usuarios. MiDoc no presta servicios para el diagnóstico, manejo o
        tratamiento de enfermedades, condiciones médicas o cualquier otro padecimiento. La Página
        Web y la Aplicación Móvil no están diseñados para ser un sistema de alerta de emergencia.
      </Paragraph>
      <Paragraph className="paragraph">
        La información sobre los Pacientes puede incluir perfiles, revisiones, comentarios, u otro
        tipo de información, que están disponibles a través de MiDoc o de los propios Pacientes.
        MiDoc no recomienda ni respalda a algún Usuario particular y no es responsable de los
        servicios, consejos, actos u omisiones de los Usuarios. Los Pacientes son los únicos
        responsables de tomar decisiones respecto de que Usuario consultan o cualquier decisión que
        tomen respecto de su salud.
      </Paragraph>
      <Paragraph className="paragraph">
        Es responsabilidad exclusiva de los Usuarios garantizar que cuentan con las licencias
        necesarias para prestar los servicios que ofrecen a través de la Pagina Web o la Aplicación
        Móvil y que cuentan con los elementos técnicos y los recursos necesarios para prestar dichos
        servicios.
      </Paragraph>
      <Paragraph className="paragraph">
        Cualquier disputa entre los Usuarios y los Pacientes será tratada directamente por los
        Usuarios y los Pacientes. MiDoc no será responsable de resolver y no se involucrará en tales
        disputas. El Usuario acepta liberar y sacar en paz y a salvo a MiDoc, sus directores
        ejecutivos, empleados, afiliados, agentes, principales y dependientes de todas y
        cualesquiera reclamaciones o acciones legales que surjan entre los Pacientes y los Usuarios
        y / o cualquier otro tercero.
      </Paragraph>
      <Title className="title" level={2}>
        Políticas de facturación
      </Title>
      <Paragraph className="paragraph">
        El Usuario deberá enviar una solicitud de facturación al centro de atención al cliente de
        MiDoc 48 horas hábiles desde cuándo se realizó la llamada. No procederán solicitudes de
        factura que no entran en este plazo.
      </Paragraph>
      <Paragraph className="paragraph">
        Al solicitar la factura será necesario que el Usuario tenga a la mano y proporcione los
        siguientes requisitos fiscales a MiDoc:
        <ul>
          <li>RFC con Homoclave.</li>
          <li>Nombre completo o razón social.</li>
          <li>
            Domicilio Fiscal completo incluyendo calle, colonia, delegación o municipio, estado y
            Código Postal.
          </li>
          <li>
            Forma en la que realizó el Pago (efectivo, transferencias electrónicas de fondos,
            cheques nominativos o tarjetas de débito, de crédito, de servicio o las denominadas
            monederos electrónicos que autorice el Servicio de Administración Tributaria).
          </li>
          <li>Correo electrónico al que llegará la factura.</li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        Una vez emitida la factura correspondiente, MiDoc no podrá re-facturar o emitir facturas
        posteriores.
      </Paragraph>
      <Title className="title" level={2}>
        Responsabilidad
      </Title>
      <Paragraph className="paragraph">
        El acceso del Usuario a la Página Web no implica para MiDoc, la obligación de informar,
        controlar o, actuación alguna referente a la ausencia o presencia de virus, gusanos o
        cualquier otro elemento informático dañino. Los Usuarios no podrán hacer responsables a
        MiDoc, ni exigir indemnización bajo ningún concepto, por los perjuicios resultantes de
        dificultades técnicas o fallas en los sistemas o en internet.
      </Paragraph>
      <Paragraph className="paragraph">
        Hay casos en los cuales una orden de compra puede no ser procesada por circunstancias ajenas
        a nosotros y las cuales no pueden ser previstas, circunstancias en las cuales interviene la
        fuerza mayor o el caso fortuito, en ese sentido, la Página Web, informará al Usuario de
        inmediato el motivo por el cual no fue posible procesar una orden, restituyendo cualquier
        cantidad cobrada al suscriptor, dejando claro que en este proceso se puede pedir información
        adicional para completar el proceso de reembolso.
      </Paragraph>
      <Title className="title sub-title" level={3}>
        Propiedad de contenido y uso
      </Title>
      <Paragraph className="paragraph">
        La plataforma digital incluye contenido que es propiedad de la compañía y de sus
        licenciantes. Esto está protegido por derechos de autor y es exclusivo para el uso de la
        compañía.
      </Paragraph>
      <Paragraph className="paragraph">
        El contenido es proporcionado solamente para el uso personal y no comercial. Uso no
        autorizado del contenido violara los derechos de autor.
      </Paragraph>
      <Title className="title sub-title" level={3}>
        Responsabilidades
      </Title>
      <Paragraph className="paragraph">
        La compañía no es responsable por:
        <ul>
          <li>Daños causados por el uso del programa</li>
          <li>Retrasos, pérdidas o errores en la comunicación y mensajes.</li>
          <li>Errores de transmisión.</li>
          <li>Robo, corrupción, destrucción de información.</li>
        </ul>
      </Paragraph>
      <Title className="title sub-title" level={3}>
        Distribución “Spam”
      </Title>
      <Paragraph className="paragraph">
        La “Referencia” debe distribuir la información de una manera apropiada y de acuerdo a las
        plataformas en donde se distribuya.
      </Paragraph>
      <Paragraph className="paragraph">
        La compañía no tiene la obligación de monitorear el contenido de los mensajes del usuario.
        Es responsabilidad del usuario actuar de acuerdo a la ley anti-spam.
      </Paragraph>
      <Title className="title sub-title" level={3}>
        Sugerencias
      </Title>
      <Paragraph className="paragraph">
        La empresa no acepta sugerencias de creatividad, sin embargo, al hacernos llegar alguna idea
        esta podrá ser usada por la empresa y no tendrá que compensar al usuario en ninguna manera.
      </Paragraph>
      <Title className="title" level={2}>
        Modificaciones a la página web:
        <a href="https://www.midocapp.com"> www.midocapp.com</a>
      </Title>
      <Paragraph className="paragraph">
        MiDoc tendrá la libertad de realizar correcciones, adiciones, mejoras o modificaciones al
        contenido, presentación, información, servicios, áreas, bases de datos y demás elementos de
        la Página Web en cualquier momento y cuando este lo considere conveniente, sin necesidad de
        avisar al Usuario, sin que ello dé lugar ni derecho alguno a ninguna reclamación o
        indemnización a favor del Usuario.
      </Paragraph>
      <Title className="title" level={2}>
        Propiedad Intelectual
      </Title>
      <Paragraph className="paragraph">
        Los textos, diseños, imágenes, bases de datos, logos, estructura, marcas y demás elementos
        del Plataforma están protegidos por las leyes y los tratados internacionales sobre propiedad
        intelectual e industrial. Cualquier reproducción, transmisión, adaptación, traducción,
        modificación, comunicación al público, o cualquier otra explotación de todo o parte del
        contenido de este Plataforma, efectuada de cualquier forma o por cualquier medio,
        electrónico, mecánico u otro, están estrictamente prohibidos salvo autorización previa por
        escrito de MiDoc. Cualquier infracción de estos derechos puede dar lugar a los
        procedimientos extrajudiciales o judiciales civiles o penales que correspondan.
      </Paragraph>
      <Paragraph className="paragraph">
        Queda prohibido al Usuario la reproducción, distribución, transmisión, adaptación o
        modificación, por cualquier medio y en cualquier forma, de los contenidos (textos, diseños,
        gráficos, informaciones, bases de datos, archivos de sonido y/o imagen, logos y demás
        elementos de la Plataforma o de las Plataformas relacionados o vinculados con la misma),
        salvo autorización previa y por escrito de sus legítimos titulares cuando así resulte
        permitido por la ley.
      </Paragraph>
      <Paragraph className="paragraph">
        Se prohíbe, al Usuario cualquier uso comercial o publicitario, de los contenidos de la
        Plataforma, y en general el uso de la información contenida en la misma, en un modo o con
        una finalidad distinta de la expresamente permitida en la Plataforma, ya sea que dicho uso
        comercial o publicitario pueda o no causar la vulneración, de cualquier derecho de MiDoc o
        de terceros.
      </Paragraph>
      <Paragraph className="paragraph">
        El Usuario podrá usar los contenidos de la Plataforma, sólo para fines informativos, o en su
        caso para contratar o activar los servicios contratados con MiDoc, y/o, para registrarse a
        los programas de beneficios, descuentos o promociones publicados en la Plataforma o en
        páginas relacionadas a este.
      </Paragraph>
      <Paragraph className="paragraph">
        Ningún contenido de la Plataforma se interpretará en el sentido de que conceda, de manera
        directa o indirectamente, o por cualquier otra causa, licencia o derecho al Usuario o a
        terceros con respecto a los derechos de autor, patentes, marcas comerciales u otros
        intereses y derechos reservados de cualquier tipo. La puesta a disposición de información no
        transfiere al Usuario ningún derecho de uso promocional y no constituirá la concesión o
        renuncia de los derechos de los propietarios de copyright.
      </Paragraph>
      <Paragraph className="paragraph">
        La Plataforma y sus contenidos, en especial, sin que la enumeración tenga efectos
        limitativos; las imágenes gráficas, sonidos, vídeos, códigos HTML y elementos distintivos
        del Plataforma, no podrán ser objeto de copia, reproducción, reedición, envío, comunicación,
        transmisión o distribución en modo alguno, sin contar con el consentimiento previo y por
        escrito de su titular.
      </Paragraph>
      <Paragraph className="paragraph">
        La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los
        contenidos aportados por terceros es de la exclusiva responsabilidad de dichos terceros.
      </Paragraph>
      <Paragraph className="paragraph">
        Será responsabilidad del Usuario no modificar, sustituir, eliminar o dañar el contenido del
        Plataforma.
      </Paragraph>
      <Paragraph className="paragraph">
        El Usuario está autorizado únicamente para ver y guardar copia de las páginas para su uso
        personal, no comercial. Además, el Usuario acepta que: i) No se involucrará ni usará ningún
        dispositivo automático ni minería de datos, ningún robot, araña, raspador ni ningún otro
        método similar de recolección o extracción de datos para tener acceso o usar la Plataforma;
        ii) No modificará, copiará, enmarcará, alquilará, prestará, venderá, distribuirá o creará
        trabajos derivados de la Plataforma ni total ni parcialmente, exceptuando la información que
        el Usuario legalmente suba a la Plataforma; y iii) No violará los derechos de autor, marcas,
        ni ningún otro derecho de propiedad intelectual, ni derechos de publicidad o privacidad de
        MiDoc, ni de ningún tercero.
      </Paragraph>
      <Title className="title" level={2}>
        Vigencia, Terminación y modificación de los términos y condiciones
      </Title>
      <Paragraph className="paragraph">
        MiDoc se reserva el derecho a cambiar los presentes Términos y Condiciones sin necesidad de
        previo aviso al Usuario. Por lo anterior MiDoc recomienda al Usuario vuelva a leer con
        regularidad dicho documento para así mantenerse siempre informado sobre eventuales
        alteraciones. En caso de que el Usuario no acepte los Términos y Condiciones modificados
        deberá dejar de utilizar la Página Web.
      </Paragraph>
      <Title className="title" level={2}>
        Fecha de la última actualización
      </Title>
      <Paragraph className="paragraph">
        Se informa al Usuario que la última actualización de los Términos y Condiciones fue el día
        25 de marzo del 2020.
      </Paragraph>
      <hr />
      <Title className="title" level={1} id="paciente">
        TÉRMINOS Y CONDICIONES <i>Del paciente</i>
      </Title>
      <Paragraph className="paragraph">
        Bienvenido a MiDoc, una plataforma de comunicación electrónica para proveedores de servicios
        y atención médica (los “Proveedores”) y sus pacientes (los “Pacientes”), operada por Mi Doc
        Technologies S.A.P.I. de C.V., una sociedad debidamente constituida de conformidad con las
        leyes de los Estados Unidos Mexicanos (&quot;MiDoc&quot;). MiDoc proporciona el sitio web:{' '}
        <a href="https://www.midocapp.com">www.midocapp.com</a> (el &quot;Sitio&quot;), una
        aplicación móvil (la &quot;Aplicación Móvil&quot;) y los Servicios (según dicho término se
        define más adelante).
      </Paragraph>
      <Paragraph className="paragraph">
        Estos Términos y Condiciones de Uso (el &quot;Acuerdo&quot;) establecen los términos
        legalmente vinculantes para el uso del Sitio, la Aplicación Móvil y los Servicios. Al
        indicar su acuerdo y al acceder o utilizar el Sitio, la Aplicación Móvil y los Servicios,
        está aceptando este Acuerdo y declara y garantiza que tiene el derecho, la autoridad y la
        capacidad para celebrar este Acuerdo, y acepta y reconoce las responsabilidades derivadas
        del mismo. Si no está de acuerdo con todas las disposiciones de este Acuerdo, no acceda o
        use el Sitio, la Aplicación Móvil o los Servicios.
      </Paragraph>
      <Paragraph className="paragraph">
        Al aceptar estos términos y condiciones reconoce que el presente Acuerdo regirá la relación
        contractual entre las partes, las responsabilidades, y cualquier otra consecuencia jurídica
        que pudiera derivar del presente Acuerdo, entre los Pacientes y MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes para utilizar los Servicios, el Sitio y la Aplicación Móvil deberán leer y
        aceptar estos términos y condiciones, en caso contrario deberán abstenerse de utilizar el
        Sitio, los Servicios o la Aplicación Móvil.
      </Paragraph>
      <Title className="title" level={2}>
        Capacidad y legitimación
      </Title>
      <Paragraph className="paragraph">
        Para usar el Sitio, los Servicios y la Aplicación Móvil, los Pacientes deberán contar con
        capacidad legal para contratar. No podrán utilizar el Sitio los Servicios y la Aplicación
        Móvil los Pacientes que no tengan capacidad legal para obligarse, los menores de edad y
        aquellos Pacientes que hubieran sido suspendidos o inhabilitados por MiDoc. Los Pacientes
        reconocen y aceptan que de conformidad con las leyes del país en que realizan y utilizan el
        Sitio, la Aplicación Móvil y los Servicios tienen la capacidad legal suficiente para
        obligarse en los términos del presente Acuerdo.
      </Paragraph>
      <Title className="title" level={2} />
      <Paragraph className="paragraph">
        1. <b>Servicios de Mensajería y de Almacenamiento de Datos:</b> Los Pacientes al registrarse
        en el Sitio o en la Aplicación Móvil contratan con MiDoc una plataforma de comunicación en
        línea para que los Proveedores y estos se conecten a través del Sitio o la Aplicación Móvil
        mediante el uso de llamada, chat, fotografía, documentos y otras tecnologías de
        telecomunicaciones (los “Servicios de Mensajería”). Los Pacientes, igualmente contratan con
        MiDoc una plataforma para que pueden incorporar distintos datos relacionados con su estado
        de salud y darlos a conocer al Proveedor (los “Servicios de Almacenamiento de Datos”). El
        Sitio y la Aplicación Móvil solamente facilitan la comunicación entre los Pacientes y los
        Proveedores.
      </Paragraph>
      <Paragraph className="paragraph">
        Para efectos de lo anterior, MiDoc sólo fungirá como plataforma de intermediación entre los
        Proveedores y los Pacientes y no será responsable de la veracidad de la información que se
        registre por los Proveedores o los Pacientes en la Aplicación Móvil o el Sitio o de los
        servicios o asesoría médica prestada por los Proveedores.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Servicios de Mensajería, los Servicios de Almacenamiento de Datos, el Sitio y la
        Aplicación Móvil únicamente son una herramienta para que los Proveedores puedan proporcionar
        asesoría médica a los Pacientes de conformidad con la información provista por estos al
        Proveedor. Los Servicios de Mensajería y los Servicios de Almacenamiento de Datos en ningún
        momento reemplazan la consulta presencial o los diagnósticos que pueden dar los Proveedores
        de forma presencial. Los Servicios de Mensajería y los Servicios de Almacenamiento de Datos
        no están destinados a ser utilizados en emergencias médicas o situaciones que requieran una
        respuesta o tratamiento práctico o inmediato.
      </Paragraph>
      <Paragraph className="paragraph">
        Derivado de lo anterior, los Proveedores en ningún momento podrán ser acusados por malas
        prácticas (“mala praxis”) derivadas de los servicios o las asesorías que presten a los
        Pacientes mediante los Servicios de Mensajería y los Servicios de Almacenamiento de Datos
        proporcionados por MiDoc toda vez que como se mencionó anteriormente los servicios prestado
        por los Proveedores no cubren los casos de emergencias o situaciones que requieran una
        consulta presencial del Paciente.
      </Paragraph>
      <Paragraph className="paragraph">
        El uso de los Servicios de Mensajería por parte del Paciente se sujetará a las tarifas
        establecidas en el apartado IX del presente Acuerdo.
      </Paragraph>
      <Paragraph className="paragraph">
        2. <b>Otros Servicios:</b> A través del Sitio o de la Aplicación Móvil, el Paciente, al
        aceptar los presentes términos y condiciones acepta que al momento de contratar un Servicio
        de Mensajería deberá realizar el pago correspondiente a las tarifas que cobren los
        Proveedores dentro del Sitio o la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        Para efectos de lo anterior, con anterioridad al inicio de la comunicación entre el
        Proveedor y el Paciente, el Proveedor publicará su tarifa dentro del Sitio o la Aplicación
        Móvil. En caso en que el Paciente, a través del Sitio o la Aplicación Móvil, se ponga en
        contacto con el Proveedor y use los Servicios de Mensajería, se entenderá que el Paciente ha
        aceptado la tarifa propuesta por el Proveedor. En este sentido, el Paciente al aceptar los
        presentes términos y condiciones, acepta y reconoce que se realice un cargo a la tarjeta de
        débito o crédito que ingresó al realizar su registro (“Servicios de Pago” y conjuntamente
        con los Servicios de Mensajería y con los Servicios de Almacenamiento de Datos los
        “Servicios”).
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc contrata proveedores externos para realizar los cargos automáticos, a través del Sitio
        o la Aplicación Móvil y por tanto, no será responsable del rechazo del pago o cualquier otra
        situación relacionada con los pagos. En caso en que hubiera un rechazo o cualquier otra
        situación relacionada con los pagos, el Paciente deberá contactar a los terceros proveedores
        de servicios para aclarar dicha situación.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc no será responsable por cargos incorrectos o la falta de pago o por cualquier otra
        situación relacionada con los pagos por parte de los Pacientes a los Proveedores. En este
        sentido, los Pacientes, al aceptar el presente Acuerdo, se comprometen a sacar en paz y a
        salvo a MiDoc de cualquier reclamación que se pudiera dar referente a los pagos que se
        realicen a través del Sitio o la Aplicación Móvil.
      </Paragraph>
      <Title className="title" level={2}>
        MiDoc no proporciona servicios ni consejos de atención médica
      </Title>
      <Paragraph className="paragraph">
        El Sitio, Aplicación Móvil y los Servicios de MiDoc son sólo una tecnología de atención
        virtual de los servicios que prestan los Proveedores a los Pacientes. Los Proveedores deben
        tener un acuerdo por separado y una relación proveedor-paciente con el Paciente, y MiDoc no
        es parte de dicho acuerdo o relación proveedor-paciente. Los Proveedores son los únicos
        responsables de mantener confidencial toda la información proporcionada por los Pacientes.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc no tiene control o inherencia en los servicios ofrecidos por parte de los Proveedores,
        por ello no será responsable respecto de la existencia, calidad o legitimidad de los
        servicios ofrecidos por los Proveedores, así como de la capacidad para contratar de los
        Pacientes o de la veracidad de la información ingresada por los Proveedores o los Pacientes.
        Los Proveedores y los Pacientes conocen y aceptan ser los únicos responsables por los
        servicios de asistencia médica ofrecidos dentro del Sitio y la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        En este sentido, los Proveedores que brindan servicios a los Pacientes a través de MiDoc
        debieran ser profesionales independientes que son los únicos responsables de los servicios
        que cada uno brinda. Los Proveedores no son empleados de MiDoc y MiDoc no practica la
        medicina o alguna otra profesión que requiera licencia.
      </Paragraph>
      <Paragraph className="paragraph">
        Asimismo, MiDoc no interfiere con la práctica de la medicina o cualquier otra profesión que
        requiera licencia. Cada uno de los Proveedores es responsable de sus servicios y del
        cumplimiento de los requisitos aplicables a su profesión y licencia.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc cuenta con mínimos estándares para verificar que los Proveedores tienen licencia y
        cumplen con los requisitos aplicables para ejercer su profesión, ya que esto depende de las
        declaraciones y la información que el Proveedor ingresa en la Aplicación Móvil o el Sitio, y
        por tanto, MiDoc no garantiza que la licencia se encuentre vigente o que los Proveedores
        cumplen con los requisitos para ejercer su profesión.
      </Paragraph>
      <Paragraph className="paragraph">
        El Sitio y Aplicación Móvil está diseñada únicamente para compartir información entre los
        Pacientes y los Proveedores. MiDoc no presta servicios para el diagnóstico, manejo o
        tratamiento de enfermedades, condiciones médicas o cualquier otro padecimiento. Cualquier
        información generada por el uso del Sitio o la Aplicación Móvil no debe ser tratada como un
        sustituto del consejo médico directo de su médico. El Sitio y Aplicación Móvil no están
        diseñados para ser un sistema de alerta de emergencia.
      </Paragraph>
      <Paragraph className="paragraph">
        La información sobre los Proveedores puede incluir perfiles, revisiones, comentarios, u otro
        tipo de información, que están disponibles a través de MiDoc o de los propios Proveedores.
        MiDoc no recomienda ni respalda a algún Proveedor particular y no es responsable de los
        servicios, consejos, actos u omisiones de los Proveedores. Los Pacientes son los únicos
        responsables de tomar decisiones respecto de que Proveedor consultan o cualquier decisión
        que tomen respecto de su salud.
      </Paragraph>
      <Paragraph className="paragraph">
        Es responsabilidad exclusiva de los Proveedores garantizar que cuentan con las licencias
        necesarias para prestar los servicios que ofrecen a través del Sitio o la Aplicación Móvil y
        que cuentan con los elementos técnicos y los recursos necesarios para prestar dichos
        servicios.
      </Paragraph>
      <Paragraph className="paragraph">
        Cualquier disputa entre los Proveedores y los Pacientes será tratada directamente por los
        Proveedores y los Pacientes. MiDoc no será responsable de resolver y no se involucrará en
        tales disputas. El Paciente acepta liberar y sacar en paz y a salvo a MiDoc, sus directores
        ejecutivos, empleados, afiliados, agentes, principales y dependientes de todas y
        cualesquiera reclamaciones o acciones legales que surjan entre los Pacientes y los
        Proveedores y / o cualquier otro tercero.
      </Paragraph>
      <Title className="title" level={2}>
        Política de privacidad
      </Title>
      <Paragraph className="paragraph">
        MiDoc respeta la privacidad de los usuarios de la Aplicación Móvil, el Sitio y los
        Servicios. Consulte el Aviso de Privacidad de MiDoc (que se encuentra en:{' '}
        <a href="https://www.midocapp.com">www.midocapp.com</a> o en su aplicación movil) para mayor
        información respecto a cómo recopilamos, usamos y divulgamos información relacionada con la
        privacidad del Paciente. Cuando el Paciente accede o utiliza la Aplicación Móvil, el Sitio y
        los Servicios, reconoce expresamente que ha leído y comprendido y está de acuerdo con
        nuestro Aviso de Privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        Cualquier información publicada en MiDoc por los Pacientes, incluida la información de
        identificación personal, se realiza de forma voluntaria y está sujeta a las Políticas de
        privacidad de MiDoc. Los Pacientes son responsables de la información que publican y
        aceptan, que deben, indemnizar, mantener y sacar en paz y a salvo a MiDoc de cualesquiera
        daños, pérdidas, costos o gastos en los que pueda incurrir MiDoc como resultado de la
        información que publiquen.
      </Paragraph>
      <Paragraph className="paragraph">
        Para efectos de lo anterior, MiDoc almacena la información en servidores que mantienen los
        estándares de seguridad y protección tecnológica de conformidad con sus capacidades y las
        políticas y estándares internos.
      </Paragraph>
      <Paragraph className="paragraph">
        La información proporcionada por los Pacientes será tratada como confidencial y sólo se dará
        a conocer a terceras personas para cumplir los fines de los Servicios. MiDoc tomará todas
        las medidas razonables para salvaguardar toda la información obtenida.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes al aceptar estos términos y condiciones reconocen que MiDoc no será
        responsable en el caso en que, por medio de una infiltración, virus o cualquier conducta
        maliciosa algún tercero sustraiga información confidencial del Sitio o de la Aplicación
        Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes al utilizar los Servicios de Mensajería reconocen que MiDoc puede utilizar
        dicha información para realizar estudios, análisis y resúmenes. La información que los
        Pacientes comparten en la Aplicación Móvil es sometida a un proceso de disociación de forma
        tal que MiDoc o algún tercero no puede asociar al Paciente con la información compartida
        dentro de la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes al ingresar su información en el Sitio o en la Aplicación Móvil, reconocen que
        MiDoc puede alterar dicha información cuando se tengan indicios claros que la información
        proporcionada por los Pacientes es incompleta, incorrecta o no sea verdadera. Lo anterior
        sin perjuicio de que MiDoc pueda dar de baja a los Pacientes por ingresar información de
        esta naturaleza.
      </Paragraph>
      <Paragraph className="paragraph">
        Dentro de la plataforma y como parte de los Servicios, es posible que ciertos usuarios
        terceros autorizados por los Pacientes puedan acceder y recibir información relacionada con
        la atención del Paciente u otra información que se recopila al usar el Sitio o la Aplicación
        Móvil. Al efecto, el Paciente podrá cancelar el acceso de estos usuarios terceros en
        cualquier momento y su acceso terminará automáticamente al finalizar este Acuerdo. Cada
        usuario es responsable de garantizar que los usuarios terceros comprendan y cumplan con este
        Acuerdo y hayan leído el Aviso de Privacidad de MiDoc. Los Pacientes son responsables de
        garantizar que los usuarios terceros mantengan la confidencialidad de su información y los
        Pacientes serán responsables de todas las actividades que se realicen como resultado del
        acceso de dichas personas.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes al aceptar el presente Acuerdo, deberán notificar a MiDoc de inmediato (a{' '}
        <a href="mailto:contacto@midocapp.com">contacto@midocapp.com</a>) cualquier uso, acceso o
        divulgación no autorizada relacionada con la información en su Cuenta. Los Pacientes aceptan
        que MiDoc no será responsable por cualquier pérdida o daño que surja de su incumplimiento de
        estos requisitos. MiDoc se reserva el derecho de suspender el acceso al Sitio o la
        Aplicación Móvil de cualquier tercero a su entera discreción.
      </Paragraph>
      <Title className="title" level={2}>
        Cuentas y acceso a los Servicios
      </Title>
      <Paragraph className="paragraph">
        1.- <b>Creación de cuentas.</b> Para utilizar ciertas funciones de los Servicios y usar el
        Sitio o la Aplicación Móvil, los Pacientes deben registrarse en una cuenta con MiDoc (la
        &quote;Cuenta&quote;) y proporcionar cierta información según lo solicite el formulario de
        registro.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes declaran y garantizan que: (a) toda la información de registro requerida que
        envíen es verdadera y precisa; y (b) mantendrán la exactitud de dicha información.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes podrán eliminar su Cuenta en cualquier momento, por cualquier motivo,
        siguiendo las instrucciones proporcionadas en el Sitio o la Aplicación Móvil. MiDoc podrá
        cancelar la Cuenta, en cualquier momento.
      </Paragraph>
      <Paragraph className="paragraph">
        2.- <b>Administración de los Servicios para menores.</b> Para poder realizar un registro y
        utilizar los Servicios, los Pacientes deberán contar con capacidad legal de conformidad con
        lo establecido en los presentes términos y condiciones.
      </Paragraph>
      <Paragraph className="paragraph">
        No obstante, los Servicios están disponibles para las personas menores de edad e
        incapacitados.
      </Paragraph>
      <Paragraph className="paragraph">
        Al respecto, para que un menor de edad incapacitado pueda disponer los servicios, el usuario
        de la Cuenta debe ser el padre o tutor legal de dicha persona menor de edad o incapacitado.
        Si el Paciente se registra como padre o tutor legal en nombre de un menor o un incapacitado,
        el Paciente será completamente responsable de cumplir con el presente Acuerdo y sacará en
        paz y salvo a MiDoc por los Servicios antes mencionados.
      </Paragraph>
      <Paragraph className="paragraph">
        3.- <b>Responsabilidades de la Cuenta.</b> El Paciente es responsable de mantener la
        confidencialidad de la información de inicio de sesión de la Cuenta y es totalmente
        responsable de todas las actividades que se realicen bajo la Cuenta. El Paciente acepta
        notificar a MiDoc de inmediato sobre cualquier uso no autorizado, o uso sospechoso no
        autorizado, de la Cuenta o cualquier otra violación de seguridad. No podemos y no seremos
        responsables por cualquier pérdida o daño que surja de su incumplimiento de los requisitos
        anteriores.
      </Paragraph>
      <Title className="title" level={2}>
        Comunicaciones a través del teléfono móvil, el correo electrónico o la Aplicación Móvil
      </Title>
      <Paragraph className="paragraph">
        MiDoc, notificará cualquier modificación de los presentes términos y condiciones, de su
        Aviso de Privacidad o de cualquier cambio de sus productos o servicios por medio de mensajes
        de texto al teléfono móvil proporcionado por el Paciente al momento de la creación de su
        Cuenta, por medio de correo electrónico cuando el Paciente hubiera elegido dicha opción o a
        través de la Aplicación Móvil. El Paciente acepta recibir las comunicaciones que MiDoc deba
        o pueda darle a través de los medios antes mencionados.
      </Paragraph>
      <Title className="title" level={2}>
        Aplicación Móvil
      </Title>
      <Paragraph className="paragraph">
        Pondremos a disposición de los Pacientes una Aplicación Móvil para acceder a los Servicios a
        través de un dispositivo móvil. Para utilizar la Aplicación Móvil, el Paciente debe tener un
        dispositivo móvil que sea compatible con la Aplicación Móvil. MiDoc no garantiza que la
        Aplicación Móvil sea compatible con su dispositivo móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        Asimismo, el Paciente no podrá: (a) modificar, desmontar, descompilar o aplicar ingeniería
        inversa a la Aplicación Móvil; (b) alquilar, arrendar, prestar, revender, sublicenciar,
        distribuir o transferir la Aplicación Móvil a terceros; (c) hacer cualquier copia de la
        Aplicación Móvil; (d) eliminar, evitar, deshabilitar, dañar o interferir con las
        características de seguridad de la Aplicación Móvil, las funciones que impiden o restringen
        el uso o la copia de cualquier contenido accesible a través de la Aplicación Móvil, o las
        funciones que imponen limitaciones en el uso de la Aplicación Móvil; o (e) eliminar los
        avisos de derechos de autor y otros derechos de propiedad en la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente reconoce que es posible que de vez en cuando publiquemos versiones actualizadas
        de la Aplicación Móvil, y que podemos actualizar automáticamente en su dispositivo móvil la
        versión de la Aplicación Móvil que está utilizando. El Paciente al aceptar estos términos y
        condiciones, acepta las actualizaciones automáticas y acepta que este Acuerdo se aplicará a
        todas estas actualizaciones.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc puede elegir brindarle al Paciente soporte o modificaciones para la Aplicación Móvil,
        a su exclusivo criterio, y puede modificar, reducir o cancelar dicho soporte en cualquier
        momento sin previo aviso. Nos reservamos el derecho de cobrar tarifas por soporte. Cualquier
        uso del software de terceros proporcionado en relación con los Servicios se regirá por las
        licencias de dichos terceros y no por este Acuerdo.
      </Paragraph>
      <Title className="title" level={2}>
        Tiendas de Aplicaciones
      </Title>
      <Paragraph className="paragraph">
        El Paciente reconoce y acepta que la disponibilidad de la Aplicación Móvil depende de los
        sitios web de terceros desde los que se descargue la Aplicación Móvil. El Paciente reconoce
        que este Acuerdo es entre este y MiDoc y no con una tienda de aplicaciones o proveedor de la
        tienda de aplicaciones. Cada tienda de aplicaciones puede tener sus propios términos y
        condiciones que el Paciente debe aceptar antes de descargar la Aplicación Móvil. El Paciente
        acepta cumplir, y la licencia para usar las Aplicaciones Móviles está condicionada al
        cumplimiento, con dichos términos y condiciones de la tienda de aplicaciones.
      </Paragraph>
      <Title className="title" level={2}>
        Condiciones de pago
      </Title>
      <Paragraph className="paragraph">
        De conformidad con lo mencionado anteriormente, el Paciente deberá pagar todas las tarifas o
        cargos que su Proveedor desee realizar de acuerdo con las tarifas que publique el Proveedor
        en la Aplicación Móvil o el Sitio. Al proporcionar al proveedor de pagos su información o
        método de pago, usted acepta que MiDoc está autorizado a coordinar el cargo inmediatamente a
        su cuenta todos los honorarios y cargos debidos y pagaderos a su Proveedor y que no se
        requiere ningún aviso o consentimiento adicional.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc, en nombre de los Proveedores, se reserva el derecho de modificar o implementar una
        nueva estructura de precios en cualquier momento antes de facturarle por su pago inicial o
        por pagos futuros que se deban en virtud de este Acuerdo.
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente es responsable de todos los cargos, incluidos los impuestos, asociados con el
        uso de la Aplicación Móvil, el Sitio y los Servicios. El Paciente es responsable de
        proporcionar un medio de pago válido y se obliga a mantener la información de pago
        actualizada en la Aplicación Móvil de MiDoc y su procesador de pagos con los fondos
        suficientes para pagar los servicios prestados. El Paciente, al aceptar estos términos, le
        está otorgando a MiDoc permiso para cobrar sus métodos de pago aprobados por las tarifas que
        el Paciente autoriza por medio de MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        Los servicios prestados por los Proveedores a los Pacientes tendrán el costo que los
        Proveedores acuerden con los Pacientes.
      </Paragraph>
      <Paragraph className="paragraph">
        El uso del Sitio o Aplicación Móvil, de conformidad con lo mencionado en el apartado II.1.
        del presente Acuerdo, los Servicios de Mensajería tendrán un costo equivalente desde $45.00
        (Cincuenta pesos Moneda Nacional 00/100), hasta un máximo de $150.00(Ciento cincuenta pesos
        Moneda Nacional 00/100) (la “Cuota de Plataforma”).
      </Paragraph>
      <Paragraph className="paragraph">
        La Cuota de Plataforma será pagadera por el Paciente a través de un cargo automático en su
        tarjeta de crédito o débito al momento en que el Paciente finalice la interacción con el
        Proveedor. La Cuota de Plataforma, sólo procederá en caso en que sea exitoso el contacto con
        el Proveedor y éste decida realizar el cobro por la prestación de sus servicios a través de
        la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente, al aceptar este Acuerdo reconoce y acepta que se realice el cargo de la Cuota
        de Plataforma antes mencionada. Asimismo, dicha Cuota de Plataforma deberá ser adicionada
        con cualquier impuesto de conformidad con las leyes aplicables, incluyendo el Impuesto al
        Valor Agregado correspondiente.
      </Paragraph>
      <Paragraph className="paragraph">
        Los Pacientes que requieran de comprobante fiscal en virtud de la Cuota de Plataforma,
        deberán de contar con el Registro Federal de Contribuyentes (“RFC”) correspondiente y demás
        datos fiscales aplicables, y solicitarlo una vez que se realice el pago. La solicitud de
        comprobante fiscal deberá realizarse ingresando una solicitud a través de un correo
        electrónico a<a href="mailto:contacto@midocapp.com"> contacto@midocapp.com</a> a más tardar
        el día 3 (tres) del mes inmediato siguiente a aquél en que se realizó el cargo
        correspondiente. Los Pacientes reconocen y aceptan que será su responsabilidad la solicitud
        de emisión del comprobante fiscal respectivo.
      </Paragraph>
      <Title className="title" level={2}>
        Propiedad intelectual
      </Title>
      <Paragraph className="paragraph">
        El uso no autorizado por parte de los Pacientes del Sitio o la Aplicación Móvil, del
        software o de cualquier parte de los Servicios está estrictamente prohibido e infringe
        derechos de propiedad intelectual de MiDoc y/o sus licenciantes, quedando sujetos a las
        penas civiles y/o penales, así como a las posibles indemnizaciones pecuniarias por los daños
        causados en virtud de la infracción a los derechos de autor y propiedad intelectual de
        MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        Los contenidos de las pantallas relativas a los Servicios que MiDoc ofrece, así como también
        los programas, bases de datos, redes, archivos, algoritmos que permiten a los Pacientes
        acceder al Sitio y a la Aplicación Móvil son propiedad exclusiva de MiDoc y están protegidas
        por las leyes y los tratados internacionales de derechos de autor y propiedad intelectual,
        marcas, patentes, modelos y diseños industriales. El uso indebido de dicha información, así
        como la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo
        autorización expresa y por escrito de MiDoc.
      </Paragraph>
      <Title className="title" level={2}>
        Acceso al sitio, seguridad y restricciones; contraseñas
      </Title>
      <Paragraph className="paragraph">
        Se le prohíbe violar o intentar violar la seguridad del Sitio y Aplicación Móvil, lo que
        incluye, sin limitar: (a) el acceso a datos que no están destinados al usuario
        correspondientes o el inicio de sesión en un servidor o una Cuenta a la que el usuario no
        está autorizado a acceder; (b) intentar probar, o escanear la vulnerabilidad de un sistema o
        red o violar las medidas de seguridad o autenticación sin la debida autorización; o (c)
        acceder o usar el Sitio o la Aplicación Móvil o cualquier parte de los mismos sin
        autorización por parte de MiDoc, en violación de este Acuerdo o en violación de la ley
        aplicable.
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente no puede utilizar ningún raspador (“scraper”), rastreador (“crawler”), araña
        (“spider”), robot o algún otro medio automatizado de cualquier tipo para acceder o copiar
        datos en el Sitio o Aplicación Móvil, enlaces profundos de ninguna característica o
        contenido del Sitio o Aplicación Móvil, omitir nuestros encabezados de exclusión de robots u
        otras medidas que podamos utilizar para prevenir o restringir el acceso al Sitio o la
        Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        Las violaciones de seguridad del sistema o de la red pueden dar lugar a responsabilidad
        civil o penal. MiDoc investigará los sucesos que puedan involucrar dichas violaciones y
        puede involucrar, y cooperar con, las autoridades policiales para procesar a los usuarios
        que están involucrados en dichas violaciones. El Paciente acepta no utilizar ningún
        dispositivo, software o rutina para interferir o intentar interferir con el funcionamiento
        adecuado de este Sitio o la Aplicación Móvil o cualquier actividad que se realice en este
        Sitio o la Aplicación Móvil.
      </Paragraph>
      <Paragraph className="paragraph">
        En el caso de que el acceso al Sitio, a la Aplicación Móvil o a una parte del estos sean
        limitados y requieran una identificación (“ID”) de usuario y una contraseña (“Áreas
        Protegidas“), el Paciente al aceptar el presente Acuerdo, reconoce y acepta acceder a las
        Áreas Protegidas usando solo su ID de usuario y contraseña tal como lo proporciona MiDoc. El
        Paciente acepta proteger la confidencialidad de su identificación de usuario y contraseña, y
        no compartir o divulgar su identificación de usuario o contraseña a terceros. El Paciente
        igualmente, acepta que es totalmente responsable de todas las actividades que se realicen
        bajo su ID de usuario. El Paciente acepta defender, indemnizar y mantener a MiDoc en paz y a
        salvo y en contra de todos los reclamos, daños y gastos de terceros (incluidos los
        honorarios razonables de abogados) contra o incurridos por MiDoc como consecuencia de su
        incumplimiento del uso o acceso del Sitio o a la Aplicación Móvil, o del acceso de cualquier
        persona que acceda utilizando el ID de usuario y contraseña del Paciente.
      </Paragraph>
      <Title className="title" level={2}>
        Modificación y suspensión de los Servicios
      </Title>
      <Paragraph className="paragraph">
        MiDoc se reserva el derecho, en cualquier momento, de modificar, suspender o interrumpir la
        Aplicación Móvil, el Sitio y los Servicios con o sin previo aviso. El Paciente acepta que
        MiDoc no será responsable ante el Paciente o ante terceros por cualquier modificación,
        suspensión o interrupción de la Aplicación Móvil, el Sitio y los Servicios.
      </Paragraph>
      <Title className="title" level={2}>
        Propiedad de los Servicios
      </Title>
      <Paragraph className="paragraph">
        El Paciente reconoce y acepta que todos los derechos de propiedad intelectual, incluidos los
        derechos de autor, patentes, marcas y secretos comerciales, en los Servicios, incluido el
        Sitio y la Aplicación Móvil, son propiedad de MiDoc o sus licenciantes. No obstante, el
        Paciente será propietario de su Contenido del Usuario (definido a continuación). La
        prestación de los Servicios no le transfiere al Paciente o a algún tercero derecho, título o
        interés en los derechos de propiedad intelectual.
      </Paragraph>
      <Paragraph className="paragraph">
        1. <b>Contenido del Usuario.</b> “Contenido del Usuario” se refiere a toda la información y
        el contenido que un usuario envía o publica en: (a) los Servicios, (b) el Sitio, (c) la
        Aplicación Móvil, o (d) en los sitios de redes sociales donde tenemos una página o
        presencia. El Paciente será el propietario de su Contenido de Usuario; no obstante, acepta
        que cualquier Contenido de Usuario proporcionado por el Paciente en relación con los
        Servicios, el Sitio, la Aplicación Móvil o los sitios de redes sociales se proporciona de
        forma no patentada, excepto para su atención médica personal e información médica. MiDoc
        acepta el utilizar cualquier información de identificación personal contenida en cualquiera
        de sus contenidos de usuario de acuerdo con su Aviso de Privacidad.
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente es el único responsable del Contenido del Usuario que publica, carga, vincula o
        pone a disposición a través de los Servicios. MiDoc se reserva el derecho de eliminar o
        resumir cualquier Contenido de Usuario del Servicio, Sitio, Aplicación Móvil o sitios de
        redes sociales a su entera discreción.
      </Paragraph>
      <Paragraph className="paragraph">
        Las siguientes reglas se refieren al Contenido del Usuario. Al transmitir y enviar cualquier
        contenido del usuario mientras se utiliza el Servicio, el Sitio o la Aplicación Móvil, el
        Paciente acepta lo siguiente:
        <ul>
          <li>
            El Paciente es el único responsable de su Cuenta y de la actividad que se produce al
            iniciar sesión o al usar su Cuenta;
          </li>
          <li>
            No enviará contenido con derechos de autor o sujeto a derechos de propiedad de terceros,
            incluidos privacidad, publicidad, secretos comerciales, entre otros, a menos que el
            Paciente sea el propietario de dichos derechos o tenga el permiso adecuado de su
            propietario legítimo para enviar específicamente dicho contenido;
          </li>
          <li>Cumplirá con la Política de uso aceptable de MiDoc; </li>
          <li>
            Reconoce y afirma que MiDoc tiene derecho de: (a) determinar si alguna de las
            presentaciones de contenido de usuario es apropiada y cumple con la Política de uso
            aceptable de MiDoc, (b) eliminar cualquiera y / o todas de las presentaciones, y (c)
            eliminar o terminar la Cuenta sin previo aviso.
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente entiende y acepta que cualquier responsabilidad, pérdida o daño que ocurra como
        resultado del uso de cualquier Contenido de Usuario que el Paciente haga disponible o acceda
        a través de su uso de los Servicios, el Sitio o la Aplicación Móvil es únicamente la
        responsabilidad del Paciente. MiDoc no será responsable de ninguna exhibición pública o mal
        uso del contenido de usuario del Paciente. MiDoc se encuentra imposibilitado para revisar o
        monitorear todo el Contenido del Usuario. Sin embargo, MiDoc, a su entera discreción, y con
        apoyo de la tecnología que emplea, podrá monitorear y / o registrar las interacciones del
        Paciente con el Servicio dentro del alcance de nuestra Aviso de Privacidad. Esas
        prohibiciones no requieren que MiDoc monitoree, vigile o elimine ningún Contenido de usuario
        u otra información enviada por el Paciente o por cualquier otro usuario.
      </Paragraph>
      <Paragraph className="paragraph">
        2. Política de uso aceptable. El permiso para utilizar los Servicios, la Aplicación Móvil y
        el Sitio está condicionado a las siguientes restricciones de uso y restricciones de
        conducta:
      </Paragraph>
      <Paragraph className="paragraph">
        El Paciente acepta que bajo ninguna circunstancia:
        <ul>
          <li>
            Publicará información abusiva, amenazadora, obscena, difamatoria, calumniosa o racial
            por lo demás objetable y ofensivo;
          </li>
          <li>
            Usará el Servicio para cualquier propósito ilegal o para la promoción de actividades
            ilegales;
          </li>
          <li>Intentará, o acosará, abusará o dañará a otra persona o grupo;</li>
          <li>Usará la Cuenta de otro usuario sin la autorización de dicho usuario;</li>
          <li>Proporcionará información falsa o inexacta al registrar una Cuenta;</li>
          <li>Interferirá o intentará interferir con el correcto funcionamiento del Servicio;</li>
          <li>
            Hará un uso automatizado del sistema, o tomará cualquier acción para imponer una carga
            irrazonable o desproporcional en nuestros servidores o infraestructura de red;
          </li>
          <li>
            Omitirá cualquier encabezado de exclusión de robots u otras medidas que tomemos para
            restringir el acceso a los Servicios, a la Aplicación Móvil o al Sitio o utilizará
            cualquier software, tecnología o dispositivo para raspar, arañar o rastrear los
            Servicios, a la Aplicación Móvil o el Sitio o recopilar o manipular datos;
          </li>
          <li>
            Publicará o vinculará contenido malicioso destinado a dañar o interrumpir el navegador o
            la computadora de otro usuario.
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="paragraph">
        El listado de prohibiciones antes mencionado es inclusivo y no limitativo. MiDoc se reserva
        el derecho de (a) cancelar el acceso a la Cuenta de los Pacientes, su capacidad para
        publicar en este Sitio, la Aplicación Móvil o utilizar los Servicios, y (b) rechazar o
        eliminar cualquier contenido del Paciente; con o sin causa y sin necesidad de dar aviso al
        Paciente MiDoc puede reportar a las autoridades competentes cualquier acción que pueda ser
        ilegal y cualquier informe que reciba de tal conducta. Cuando sea legalmente requerido o a
        discreción de MiDoc, MiDoc cooperará con las autoridades competentes en cualquier
        investigación de presunta actividad ilegal en este Sitio, Aplicación Móvil o en Internet.
      </Paragraph>
      <Title className="title" level={2}>
        Indemnización
      </Title>
      <Paragraph className="paragraph">
        Al utilizar el Sitio, la Aplicación Móvil y los Servicios, los Pacientes aceptan, en la
        medida permitida por la ley, a indemnizar y a sacar en paz y a salvo a MiDoc, sus
        directores, ejecutivos, empleados, afiliados, agentes, principales y dependientes, respecto
        a cualquier reclamación derivada del incumplimiento del presente acuerdo de voluntades.
      </Paragraph>
      <Paragraph className="paragraph">
        Lo anterior significa que los Pacientes no podrán demandar, pedir compensación por ningún
        daño ocasionado por parte de MiDoc, sus directores, ejecutivos, empleados, afiliados,
        agentes, principales y dependientes, derivado de la decisión de MiDoc de eliminar o negar el
        procesamiento de información, así como por la suspensión o interrupción de acceso al Sitio o
        a la Aplicación Móvil. Esta disposición de indemnización aplica a todas las violaciones
        contempladas en el presente Acuerdo.
      </Paragraph>
      <Title className="title" level={2}>
        Enlaces a otros sitios, aplicaciones y / o materiales
      </Title>
      <Paragraph className="paragraph">
        Como parte de los Servicios, MiDoc puede proporcionarle al Paciente enlaces a sitios web de
        terceros, así como a contenido o a elementos que pertenecen o son originarios de terceros.
        Estos enlaces se proporcionan como cortesía a los Pacientes usuarios de la Aplicación Móvil,
        el Sitio o los Servicios. MiDoc no tiene control sobre los sitios de terceros y las
        aplicaciones, el software o el contenido de terceros. Si decide abandonar el Sitio o la
        Aplicación Móvil y acceder a los sitios de terceros o utilizar o instalar aplicaciones,
        software o contenido de terceros, lo hace bajo su propio riesgo y debe tener en cuenta que
        dichos sitios web de terceros y el contenido o elementos que son propiedad de terceros
        tienen sus propios términos y condiciones y el presente acuerdo ya no sería aplicable. El
        Paciente deberá revisar los términos y políticas aplicables, incluidas el Aviso de
        Privacidad y recopilación de datos, de cualquier sitio al que navega desde el Sitio o en
        relación con las Aplicación Móvil.
      </Paragraph>
      <Title className="title" level={2}>
        Fallas en el sistema
      </Title>
      <Paragraph className="paragraph">
        MiDoc no será responsable de las fallas en el Sitio, en la Aplicación Móvil, en internet o
        en el sistema y no garantiza a los Pacientes el acceso o el uso ininterrumpido del Sitio o
        la Aplicación Móvil. Por lo anterior, los Pacientes al someterse a estos términos y
        condiciones aceptan que MiDoc no es responsable por cualquier daño o perjuicio que se les
        pueda causar a los Pacientes por fallas en el sistema de cualquier naturaleza.
      </Paragraph>
      <Title className="title" level={2}>
        Término y terminación
      </Title>
      <Paragraph className="paragraph">
        Si los Pacientes no cumplen, o MiDoc sospecha que no han cumplido alguna de las
        disposiciones del presente Acuerdo, MiDoc podrá, a su criterio y sin necesidad de previo
        aviso: (a) terminar el presente Acuerdo y/o cancelar su cuenta de acceso, siendo
        responsables por toda suma de dinero exigible a favor de MiDoc hasta la fecha de dicha
        terminación; y/o (b) impedir el acceso al Sitio o a la Aplicación para usar los servicios de
        MiDoc sin importar el registro que hubiera realizado.
      </Paragraph>
      <Paragraph className="paragraph">
        MiDoc se reserva el derecho a modificar, suspender o descontinuar los Servicios en cualquier
        momento con o sin enviar notificación, y MiDoc no será responsable frente a los Pacientes o
        algún tercero si llegaré a ejercer dichos derechos.
      </Paragraph>
      <Title className="title" level={2}>
        Modificaciones
      </Title>
      <Paragraph className="paragraph">
        MiDoc podrá modificar en todo momento los términos y condiciones haciéndosele saber a los
        Pacientes a través del Sitio, la Aplicación Móvil o un mensaje de texto al teléfono Móvil
        del Paciente las modificaciones que hubiera realizado. Las modificaciones a los términos y
        condiciones entrarán en vigor 5 (cinco) días hábiles después de su notificación a los
        Pacientes a través de los medios señalados. En el caso de que los Pacientes no estén de
        acuerdo con las modificaciones, deberán de dejar de utilizar el Sitio, la Aplicación Móvil y
        los Servicios y si es el caso, deberán proceder con la baja de su Cuenta. MiDoc entenderá
        que los Pacientes aceptan los términos y condiciones si continúan utilizando sus cuentas y
        contraseñas para ingresar al Sitio o a la Aplicación Móvil y utilizan los Servicios.
      </Paragraph>
      <Title className="title" level={2}>
        Jurisdicción aplicable
      </Title>
      <Paragraph className="paragraph">
        Para la interpretación, cumplimiento y ejecución del presente Acuerdo, las partes
        expresamente se someten a la jurisdicción de los tribunales competentes de la Ciudad de
        México, renunciando en consecuencia a cualquier fuero que en razón de su domicilio presente
        o futuro pudiera corresponderles.
      </Paragraph>
      <Title className="title" level={2}>
        Solución de Controversias
      </Title>
      <Paragraph className="paragraph">
        Al aceptar estos términos y condiciones los Pacientes acuerdan libre y voluntariamente que
        todas las desavenencias, controversias, divergencias o cuestiones litigiosas que deriven del
        presente acuerdo, antes de recurrir al arbitraje, procedimiento judicial o a alguno de los
        medios alternativos de solución de controversias que se exponen a continuación, deberán
        interponer una queja ante MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        Una vez expuesta la queja a la que se hace referencia en el párrafo precedente y no se
        hubiera satisfecho la pretensión de los Pacientes en un término de 45 (cuarenta y cinco)
        días hábiles a partir de la presentación de dicha queja, los Pacientes se comprometen a
        someter dichas controversias, divergencias o cuestiones litigiosas derivadas del presente
        acuerdo o relacionadas, en primer lugar, al procedimiento con arreglo al Reglamento de
        Mediación de la Cámara de Comercio Internacional.
      </Paragraph>
      <Paragraph className="paragraph">
        A falta de resolución de las controversias según dicho Reglamento dentro de los 45 (cuarenta
        y cinco) días hábiles siguientes a la presentación de la solicitud de Mediación, tales
        controversias deberán ser resueltas definitivamente de acuerdo con el Reglamento de
        Arbitraje de la Cámara de Comercio Internacional, por uno o más árbitros nombrados conforme
        a este Reglamento de Arbitraje.
      </Paragraph>
      <Paragraph className="paragraph">
        Por su parte MiDoc podrá en cualquier momento iniciar el procedimiento de solución de
        controversias que mejor convenga a sus intereses, a efecto de exigir el cumplimiento de
        cualquier obligación a cargo de los Pacientes, así como para demandar el pago de daños y/o
        perjuicios ocasionados, así como cualquier indemnización que en su caso se llegue a generar
        a favor de MiDoc.
      </Paragraph>
      <Paragraph className="paragraph">
        El uso del Sitio, la Aplicación Móvil y los Servicios, significa que el Paciente acepta
        estos términos y condiciones.
      </Paragraph>
      <Title className="title" level={2}>
        Fecha de la última actualización
      </Title>
      <Paragraph className="paragraph">
        Se informa al Paciente que la última actualización de los Términos y Condiciones fue el día
        25 de marzo del 2020.
      </Paragraph>
    </section>
  );
};

export default TermsComponent;
