import Paty from '../../../../assets/img/paty.jpeg';
import Mario from '../../../../assets/img/mario.jpg';
import Carlos from '../../../../assets/img/carlos-p.jpeg';
import Jose from '../../../../assets/img/jose-manuel.jpg';
import Daniil from '../../../../assets/img/daniil.jpeg';

export const teamInfo = {
  Mario: {
    img: Mario,
    name: 'Mario Cortés',
    career: 'Ingeniero',
  },
  Carlos: {
    img: Carlos,
    name: 'Carlos Peña',
    career: 'Ingeniero de Software',
  },
  Jose: {
    img: Jose,
    name: 'José Marrón',
    career: 'Ingeniero de Software',
  },
  Paty: {
    img: Paty,
    name: 'Patricia Cortés',
    career: 'Médico Pediatra',
  },
  Daniil: {
    img: Daniil,
    name: 'Daniil Shushpanov',
    career: 'Ingeniero de Software',
  },
};
