import React, { useContext } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { DoctorDataContext } from '@contexts/DoctorData';
import { Button, notification } from 'antd';
import QRCode from 'qrcode.react';

import { copyQRCode, dowloadQrCode, messageQrCode } from './constants';
import './styles.scss';
import { IQRCodeProps } from './types';

const QrCode: React.FC<IQRCodeProps> = ({ doctorId }) => {
  const { doctorData } = useContext(DoctorDataContext);
  const downloadQR = () => {
    const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      if (pngUrl) {
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };
  const formatLink = `https://midoc.page.link/?link=https://midocapp.com/${doctorId}&apn=com.midoc.midoc&amv=3&ibi=com.midoc.midoc&isi=1494637379&efr=1`;
  const copyToClickBoard = () => {
    notification.success({
      message: 'Tu link fue copiado',
    });
    const dummyElement = document.createElement('input');
    document.body.appendChild(dummyElement);
    dummyElement.setAttribute('value', doctorData.dynamicLink);
    dummyElement.select();
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
  };
  return (
    <div className="qrcode-section">
      <i>{messageQrCode}</i>
      {doctorData.dynamicLink && (
        <QRCode id="qrcode" value={doctorData.dynamicLink} includeMargin={true} size={200} />
      )}
      <div className="qrcode-section-links">
        <Button
          type="link"
          size="small"
          onClick={() => {
            copyToClickBoard();
          }}
        >
          {copyQRCode}
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            downloadQR();
          }}
          icon={<DownloadOutlined />}
          size={'small'}
        >
          {dowloadQrCode}
        </Button>
      </div>
    </div>
  );
};

export default QrCode;
